import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api'
import { setCommentSuccess, setFormLoading, setRatingSuccess, setScore } from './reducer';


type RatingSetArgs = {
  post_id: number | null, 
  data: { 
    score?: number, 
    comment?: string, 
    comment_type?: "comment" | "question" 
  }
}

export const ratingSet = createAsyncThunk(
  'ratings/set',
  async function({ post_id, data }: RatingSetArgs, { dispatch }) {

    if ('score' in data && (!data.score || !(data.score > 0))) {
      delete data.score;
    }
  
    if ('comment' in data && data.comment && data.comment.trim().length) {
      data.comment = data.comment.trim();
    } else {
      delete data.comment;
    }

    try {
      dispatch(setFormLoading(true))

      const res = await api.post(`/rating/${post_id ? post_id : ''}`, data);
      if (res && res.success) {
        if (data.score) {
          dispatch(setScore(data.score || 0))
          dispatch(setRatingSuccess(true))
        }
        if (data.comment) {
          dispatch(setCommentSuccess(true))
        }
      }
      
    } catch (e) {
      // this should be a failed http response, probably a 401
      if (data.score) {
        dispatch(setRatingSuccess(false))
      }
      if (data.comment) {
        dispatch(setCommentSuccess(false))
      }
    } finally {
      dispatch(setFormLoading(false))
    }

  }
)


