import { useEffect, useState } from "react"
import { QType } from './EntityList'


export interface EntityListHeaderProps {
  category?: string
  tag?: string
  qType: QType
}


export function EntityListHeader({ category, tag, qType }: EntityListHeaderProps) {

  const [label, setLabel] = useState<string>("");

  useEffect(() => {

    switch (qType) {
      case "portal":
        setLabel("Portals")
      break;

      case "podcast":
        setLabel("Podcasts")
      break;

      case "showfull":
        setLabel("Full Episodes")
      break;

      case "showclip":
        setLabel("Ruddle Show Clips")
      break;

      default:
        if (category || tag) {
          setLabel("Everything")
        } else {
          setLabel("Everything")
        }
    }

  }, [ qType ])


  return (
  <>
    <h1>
      {label && (
      <small>

        {label} {" "}

        {category && (
        <>
          in {" "}
          <strong>
            {category}
          </strong>
        </>
        )}

        {" "}

        {tag && (
        <>
          tagged {" "}
          <strong>
            {tag}
          </strong>
        </>
        )}

      </small>
      )}
    </h1>

    {(false && (category || tag)) && (
      <div>
      <a href="/all">view all</a>
      </div>
    )}
    
  </>
  )
}
