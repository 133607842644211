import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { AccountState } from "./types";


const slice = (state: RootState) => state.account

export const selectLoading = createSelector(slice, (state: AccountState) => state.loading);

export const selectFormMessageType = createSelector(slice, (state: AccountState) => state.form_message_type);

export const selectPage = createSelector(slice, (state: AccountState) => state.page);

export const selectError = createSelector(slice, (state: AccountState) => state.error);
export const selectErrors = createSelector(slice, (state: AccountState) => state.errors);
export const selectSubscription = createSelector(slice, (state: AccountState) => state.subscription);
export const selectPaymentUpdateSuccess = createSelector(slice, (state: AccountState) => state.paymentUpdateSuccess);
export const selectPaymentUpdateErrors = createSelector(slice, (state: AccountState) => state.paymentUpdateErrors);
