import classNames from "classnames";


export interface SubscribeIntervalProps {
  interval: string | null
  intervalCount: number | null
  stacked: boolean
}


export function SubscribeInterval({ interval, intervalCount, stacked }: SubscribeIntervalProps) {

  if (!(interval && intervalCount)) {
    return (
      <></>
    )
  }

  const label = interval.substring(0, 1).toUpperCase() + interval.substring(1).toLowerCase();

  const cls = stacked ? "d-flex flex-column" : "";
  const style: React.CSSProperties = stacked ? { lineHeight: 1 } : {};

  if (intervalCount > 1) {
    return (
      <span className={classNames(cls, "font-weight-bold")} style={style}>
        <span>every</span>{" "}
        <span>{intervalCount} {label}s</span>
      </span>
    )  
  }

  return (
    <span className={classNames(cls, "font-weight-bold")} style={style}>
      <span>per</span>{" "}
      <span>{label}</span>
    </span>
  )
}
