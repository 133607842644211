import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import SigninFlow from './signInFlow';
import RatingForm from './ratingForm';
import { UserState } from "../store/user/types";
import { useAppSlice } from "store/app/reducer";
import { selectUser } from "store/user/select";


export interface RatingFlowProps extends UserState {
  postId: number | null;
  postTitle?: string;
  //withComment: boolean;
  type: "comment" | "rating" | "combined";
  withCloser: boolean;
  commentType?: "comment" | "question"
  subjectChoice?: boolean | string
  episodeThumbnail?: string
}


export function RatingFlow({ postId, type, commentType, postTitle, subjectChoice, episodeThumbnail, withCloser }: RatingFlowProps) {

  const { register } = useAppSlice();

  const post_id = postId ? parseInt(postId as any) : null;
  const user = useSelector(selectUser);


  useEffect(() => {
    if (post_id) {
      register({
        type: 'rating',
        post_id,
        count: 1,
      })
    }
  }, [ register, post_id ]);


  let typeText = "";
  if (type === "comment") {
    if (commentType === 'question') {
      typeText = "Question";
    } else if (commentType === 'comment') { 
      typeText = "Comment";
    } else {
      typeText = "Comment or Question";
    }
  } else if (type === "rating") {
    typeText = "Rating";
  } else {
    if (commentType === 'question') {
      typeText = "Rating and Question";
    } else if (commentType === 'comment') { 
      typeText = "Rating and Comment";
    } else {
      typeText = "Rating and Comment / Question";
    }
  }


  return (
    <div className="TRS-widget TRS-ratingFlow" style={{position: 'relative'}}>

      {user && user.id ? (
        <div>
          <RatingForm 
            postId={post_id} 
            postTitle={postTitle} 
            type={type} 
            commentType={commentType} 
            subjectChoice={subjectChoice}
            episodeThumbnail={episodeThumbnail}
            withCloser={!!withCloser} />
        </div>
      ) : (
        <div>
          <h1>Add your {typeText}</h1>
          <SigninFlow />
        </div>
      )}

    </div>
  );
}


export default RatingFlow;