import classNames from 'classnames';


export interface RatingCommentFormStarProps {
  idx: number // idx of this start, starting with 1
  rating: number // current rating of the post
  selectMode: boolean // is this starr in "select mode" (waiting for user selection)
  selectingIdx?: number // idx of the star that we are currently selecting (hovering)
  onSelect: (e: React.MouseEvent, rating: number) => void // callback for clicking our star in select mode
  onSelectHover: () => void // callback for hovering our star in select mode
} 

export function RatingCommentFormStar({ idx, rating, selectMode, selectingIdx, onSelect, onSelectHover }: RatingCommentFormStarProps) {

  const noop = () => undefined;
  selectingIdx = selectingIdx || 0;

  return (
  <button className={classNames('star', { 
      select: selectMode,
      selectedto: selectingIdx >= idx,
      filled: rating >= idx
      })}
    onMouseEnter={selectMode ? onSelectHover : noop}
    onClick={selectMode ? (e) => onSelect(e, idx) : noop}
    >
    <svg viewBox="0 0 18 18">
      <path d="M9 11.3l3.71 2.7-1.42-4.36L15 7h-4.55L9 2.5 7.55 7H3l3.71 2.64L5.29 14z"/>
    </svg>
    <span className="num">{idx}</span>
  </button>
  )
}



