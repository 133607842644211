import React from "react";
import { md5 } from "../../md5";
import { imageResizeURL } from "../../resizeURL";
import { lpad, seFormat } from "../../util";
import classNames from "classnames";
import { PacificDate } from "../PacificDate"

export interface PodcastListPodcastProps {
  staticURL: string
  fadein: boolean
  index: number
  podcast: any
}


export function PodcastListPodcast({ staticURL, fadein, index, podcast }: PodcastListPodcastProps) {

  const previewTimeStamp: number = podcast.data.preview_timestamp || (Math.floor(parseInt(podcast.data.duration) / 2)) || 25;
  const posterImage = podcast.data.video_thumbnail 
    ? staticURL + imageResizeURL(`/video-posters/${podcast.id}.thumbnail.jpg`, 'jpg', 'cover', '640x360', 80, md5(`${podcast.id}${podcast.updated}`).slice(0, 10))
    : podcast.data.video_poster
      ? staticURL + imageResizeURL(`/video-posters/${podcast.id}.poster.jpg`, 'jpg', 'cover', '640x360', 80, md5(`${podcast.id}${podcast.updated}`).slice(0, 10))
      : `https://videodelivery.net/${podcast.data.video_cdn_id}/thumbnails/thumbnail.jpg?time=${previewTimeStamp}s&amp;width=608&amp;height=342&amp;fit=crop`;
  const animatedImage = `https://videodelivery.net/${podcast.data.video_cdn_id}/thumbnails/thumbnail.gif?time=${previewTimeStamp}s&amp;duration=6s&amp;width=600&amp;height=400&amp;fit=crop`;

  const duration = formatDuration(podcast.data.duration);

  const seFormatted = seFormat(podcast.data.season_number, podcast.data.episode_number);

  const delay = 0.125 * (index % 3);

  return (
    <>
    <div className="container-fluid podcast mb-5">
      <div className="row align-items-center">
        <div className="colimg">

          <div className="cimg">
            <a href={`/podcast/${podcast.id}/${podcast.urlpath}`} className="thumbpreview">
              <span className="preview" style={{ backgroundImage: `url('${animatedImage}')` }}></span>
              <span className="still" style={{ backgroundImage: `url('${posterImage}')` }}></span>
            </a>
          </div>

        </div>
        <div className="coltext">

          <div className="title">
            <p style={{ display: (podcast.data.title_pre ? "" : "none") }}>{podcast.data.title_pre}</p>
            <h4>{podcast.data.title}</h4>
            <p style={{ display: (podcast.data.title_post ? "" : "none") }}>{podcast.data.title_post}</p>
          </div>

          <div>
            <p className="my-2 description">{podcast.data.description}</p>

            <div className="duration mb-2">
              <img src={`${staticURL}/images/clock.png`} width="25" alt="Duration" />{" "}
              <b>Duration:</b>{" "}
              {duration}
            </div>
            <div className="released mb-2">
              <img src={`${staticURL}/images/calendar-check.png`} width="25" alt="Release Date" />{" "}
              <b>Released:</b>{" "}
              <PacificDate timestamp={podcast.published} output="date" />
            </div>
            <div className="episode mb-3">
              <img src={`${staticURL}/images/video.png`} width="25" alt="Episode" />{" "}
              <b>Episode:</b>{" "}
              Podcast #{podcast.data.episode_number}
            </div>

            <div className="action action-self">
              <a href={`/podcast/${podcast.id}/${podcast.urlpath}`} 
                className={classNames(`btn btn-primary btn-sm`, podcast.data.access === "premium" ? "btn-premium" : "")}
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M7.75194 5.43872L18.2596 11.5682C18.4981 11.7073 18.5787 12.0135 18.4396 12.252C18.3961 12.3265 18.3341 12.3885 18.2596 12.432L7.75194 18.5615C7.51341 18.7006 7.20725 18.62 7.06811 18.3815C7.0235 18.305 7 18.2181 7 18.1296V5.87061C7 5.59446 7.22386 5.37061 7.5 5.37061C7.58853 5.37061 7.67547 5.39411 7.75194 5.43872Z"></path>
                </svg>
                Play 
              </a>
            </div>

            {podcast.data.access === "premium" && (
            <div className="ruddleplus-block ruddlediv">
              <span className="ruddleplus-text">Ruddle<img src={`${staticURL}/images/plusicon.png`} className="zoomin" /></span>
              <p className="mb-0">Premium Access</p>
            </div>
            )} 

          </div>
        </div>
      </div>
    </div>
    </>
  );
}


function formatDuration(input: string) {
  let n = parseInt(input);

  let h,m;

  h = Math.floor(n / 3600);
  n = n - (h * 3600);
  
  m = Math.floor(n / 60);
  n = n - (m * 60);

  if (h) {
    return `${h}:${lpad(m+"", 2, '0')}:${lpad(n+"", 2, '0')}`
  } else {
    return `${lpad(m+"", 2, '0')}:${lpad(n+"", 2, '0')}`
  }

}

