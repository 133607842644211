import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from 'react-redux';
import { Loader } from "./loader";
import classNames from 'classnames';
import { PollSubtitle, chartColors } from "./poll";
import { selectPoll, selectPollFormLoading, selectVoteSuccess } from "store/polls/select";
import { usePollsSlice } from "store/polls/reducer";


export interface PollFormProps {
  withCloser: boolean;
}


export function PollForm({ withCloser }: PollFormProps) {

  const mainRef = useRef<HTMLDivElement>(null);
  const [ answer, setAnswer ] = useState(undefined as number | undefined);
  const [ submitted, setSubmitted ] = useState(false);

  //const pollState = props.pollsState;
  const poll = useSelector(selectPoll);
  const vote_success = useSelector(selectVoteSuccess);
  const form_loading = useSelector(selectPollFormLoading);

  const { setVoteSuccess, pollVote  } = usePollsSlice();

  const cleanForm = useCallback(() => {
    setVoteSuccess(false)
  }, [ setVoteSuccess ]);


  const addVote = useCallback((post_id: number, data: { choice: number }) => {
    pollVote({ post_id, data })
  }, [ pollVote ]);


  useEffect(() => {
    cleanForm();
    setSubmitted(false);
    setAnswer(poll?.answer_choice_id || undefined);
  }, [ poll, cleanForm ]);

  if (!poll) return (
    <div></div>
  )

  function submitForm(e: React.FormEvent) {
    e.preventDefault();

    if (!answer || !poll?.id) {
      return;
    }

    setSubmitted(true);
    addVote(poll?.id, { choice: answer })
  }

  return (
    <div className="TRS-widget TRS-ratingForm" style={{position: 'relative'}} 
      ref={mainRef}>

      <form onSubmit={submitForm}>

        <div className="title">
          <span>{poll?.data.title}</span>
          <PollSubtitle poll={poll}></PollSubtitle>
        </div>

        {poll.data.choices?.map((c, i) => 
          <div className="choice" key={c.id}>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id={`pollRadio_${poll.id}_${c.id}`} name="choice" 
                className="custom-control-input"
                disabled={submitted && vote_success}
                checked={c.id === answer} onChange={e => setAnswer(c.id)} />
              <label className="custom-control-label" htmlFor={`pollRadio_${poll.id}_${c.id}`}>
                <span className={'dot dot-'+i} style={{backgroundColor: chartColors[i] }}></span>
                <span className="label">{c.label}</span>
              </label>
            </div>
          </div>
        )}
        
        <div className={classNames('mt-4', {
            'd-none': !( (vote_success && (submitted)) )
            })}>

          <div className="alert alert-info d-flex justify-content-center">
            <div className="text-center">
              <h5>Thank you for your Vote</h5>

              {withCloser ? 
              <p>
                <button className="btn btn-secondary btn-lg" data-dismiss="modal">
                  Close
                </button>
              </p>
              : ''}
            </div> 
          </div>

        </div>


        <div className={classNames('btnRow mt-4', {
            'd-none': (vote_success && submitted)
            })} >
          <button type="submit" className="btn btn-primary btn-lg" disabled={!answer}>
            Add Vote
          </button>      
        </div>

        <Loader loading={form_loading} exclass="dark" />

      </form>
    </div>
  );
}


export default PollForm;
