import { RenderedPage, SpecialPageState } from './types'
import { specialPageLoad } from './actions';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';


const initialState: SpecialPageState = {
  loading: false,
};


export const specialPageSlice = createSlice({
  name: "specialpage",
  initialState: initialState,
  reducers: {

    setLoading: (state: SpecialPageState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    pageLoaded: (state: SpecialPageState, { payload }: PayloadAction<RenderedPage | undefined>) => {
      state.loading = false;
      state.renderedPage = payload;
    },

    setRenderedPage: (state: SpecialPageState, { payload }: PayloadAction<RenderedPage>) => {
      state.renderedPage = payload
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setLoading, pageLoaded, setRenderedPage } = specialPageSlice.actions;

export const useSpecialPageSlice = () => createSliceWrapperHook(specialPageSlice, "specialpage", {
  specialPageLoad
});


export default specialPageSlice.reducer;