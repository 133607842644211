import React, { useCallback, useEffect, useRef, useState } from "react";
import { StripePaymentMethod, StripePaymentMethodCard, StripePaymentMethodCashApp, StripePaymentMethodUSBankAccount, StripeSubscription } from "../../store/subscriptions/types";
import { SubscribePrice } from "../subscribe/SubscribePrice";
import { SubscribeInterval } from "../subscribe/SubscribeInterval";
import { SubscriptionDate } from "../subscribe/SubscriptionDate"
import { useAccountSlice } from "store/account/reducer";



export interface SubscriptionBlockProps {
  subscription: StripeSubscription
}

export function SubscriptionBlock({ subscription }: SubscriptionBlockProps) {

  const [ menuOpen, setMenuOpen ] = useState<boolean>(false);
  const { setSubscription, setPage } = useAccountSlice();

  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const clickAnywhere = (e: MouseEvent) => ref.current && !ref.current.contains(e.target as any) && menuOpen ? setMenuOpen(false) : undefined;
    window.addEventListener('click', clickAnywhere)

    return () => window.removeEventListener('click', clickAnywhere)
  }, [ ref ])

  const handleUpdateClick = useCallback(() => {
    setSubscription(subscription); 
    setMenuOpen(false);
    const url = new URL(window.location.href);
    url.searchParams.append('subscription', subscription.id);
    window.history.replaceState({}, "", url);
    window.location.hash = 'subscriptionMethod'
  }, []);

  return (
  <div className="subscription p-3" style={{ 
      lineHeight: "1.2", 
      border: "1px solid #ddd", 
      borderRadius: "1em", 
      boxShadow: "2px 2px 2em rgba(0,0,0,0.125)"
    }}>
    <div className="row">

      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Subscription:</small></div>
        <div>
          <b>{subscription.plan.product.name}</b>
        </div>
      </div>

      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Plan:</small></div>
        <div><b>{subscription.plan.nickname || "Custom"}</b></div>
      </div>


      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Price:</small></div>
        <div className="d-flex align-items-center">
          <b className="mr-1">
            <SubscribePrice unitAmount={subscription.plan.amount} />
          </b>
          <b>
            <SubscribeInterval stacked={false} interval={subscription.plan.interval} intervalCount={subscription.plan.interval_count} />
          </b>
        </div>
      </div>

      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Current Period Start:</small></div>
        <div>
          <b><SubscriptionDate output="date" timestamp={subscription.current_period_start} /></b>
        </div>
      </div>

      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Current Period End:</small></div>
        <div>
        <b><SubscriptionDate output="date" timestamp={subscription.current_period_end} /></b>
        </div>
      </div>


      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Status:</small></div>
        <div>
            
          {subscription.cancel_at_period_end ? (
            <b>
              Cancelled at period end
            </b>
          ) : (
            <b className="text-capitalize">
              {subscription.status}
            </b>
          )}

          {subscription.pause_collection && (
            <div>
              <small>(Payments are paused)</small>
            </div>
          )}


        </div>
      </div>


      <div className="col-sm-6 col-xl-4 mb-2">
        <div><small>Payment Method:</small></div>
        <div>
          {subscription.default_payment_method 
          ? <b><SubscriptionPaymentMethod paymentMethod={subscription.default_payment_method} /></b>
          : <b><span>No Payment Method Set</span></b>
          }
        </div>
      </div>


      {(!subscription.cancel_at_period_end && !["cancelled", "expired", "incomplete_expired"].includes(subscription.status)) && (
      <div className="col-sm-6 col-xl-4 mb-2 align-self-end ml-auto" ref={ref}>
        <div><small>&nbsp;</small></div>
        <div className="position-relative">
          <button 
            className="btn btn-secondary btn-sm"
            onClick={() => setMenuOpen(!menuOpen)}
            >Modify</button>

          <div style={{ 
            display: menuOpen ? "block" : "none",
            position: "absolute",
            overflow: "hidden",
            zIndex: "50",
            width: "auto",
            maxWidth: "320px", 
            background: "#fff",
            border: "1px solid #aaa",
            borderRadius: "0.5em",
            boxShadow: "1px 1px 1.5em rgba(0,0,0,0.18)"
            }}
            >

            <div>
              <button className="d-block w-100 px-4 py-2 border-0 text-left"
                style={{ background: "#fff" }}
                onClick={handleUpdateClick}
                >Update Billing Method</button>
            </div>

            {["active", "incomplete", "past_due", "unpaid"].includes(subscription.status) && (
            <div>
              <button className="d-block w-100 px-4 py-2 border-0 text-left"
                style={{ background: "#fff" }}
                onClick={() => { setSubscription(subscription); window.location.hash = 'subscriptionCancel'; setMenuOpen(false); }}
                >Cancel</button>
            </div>
            )}

          </div>
        </div>
      </div>
      )}


    </div>
  </div>
  )
}



export interface SubscriptionPaymentMethodProps {
  paymentMethod: StripePaymentMethod
}

export function SubscriptionPaymentMethod({ paymentMethod }: SubscriptionPaymentMethodProps) {

  switch(paymentMethod.type) {
    case "card": {
      return <SubscriptionPaymentMethodCard paymentMethod={paymentMethod} />
    }
    case "cashapp": {
      return <SubscriptionPaymentMethodCashApp paymentMethod={paymentMethod} />
    }
    case "us_bank_account": {
      return <SubscriptionPaymentMethodUSBankAccount paymentMethod={paymentMethod} />
    }
    default: {
      return <SubscriptionPaymentMethodOther paymentMethod={paymentMethod} />
    }
  }
}

export function SubscriptionPaymentMethodCard({ paymentMethod }: SubscriptionPaymentMethodProps) {

  const card: StripePaymentMethodCard = paymentMethod.card || {} as StripePaymentMethodCard;

  return (
    <span>
      <span className="text-capitalize">{card.brand}</span> {" "} ****{card.last4}<br />
      Expire: {" "} {card.exp_month}/{card.exp_year}
    </span>
  )
}

export function SubscriptionPaymentMethodCashApp({ paymentMethod }: SubscriptionPaymentMethodProps) {

  const cashapp: StripePaymentMethodCashApp = paymentMethod.cashapp || {} as StripePaymentMethodCashApp;

  return (
    <span>
      CashApp {cashapp.cashtag}
    </span>
  )
}


export function SubscriptionPaymentMethodUSBankAccount({ paymentMethod }: SubscriptionPaymentMethodProps) {

  const ba: StripePaymentMethodUSBankAccount = paymentMethod.us_bank_account || {} as StripePaymentMethodUSBankAccount;

  return (
    <span>
      <span className="text-transform: capitalize;">{ba.account_type}</span> Account {" "} ****{ba.last4}
    </span>
  )
}


export function SubscriptionPaymentMethodOther({ paymentMethod }: SubscriptionPaymentMethodProps) {

  return (
    <span>
      Non-card Payment
    </span>
  )
}