import { RatingState } from './types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { ratingSet } from './actions';


const initialState: RatingState = {
  form_loading: false,
  rating_success: false,
  comment_success: false,
  score: 0,
};


export const ratingsSlice = createSlice({
  name: "ratings",
  initialState: initialState,
  reducers: {

    setFormLoading: (state: RatingState, action: PayloadAction<boolean>) => {
      state.form_loading = action.payload
    },

    setRatingSuccess: (state: RatingState, action: PayloadAction<boolean>) => {
      state.rating_success = action.payload
    },

    setCommentSuccess: (state: RatingState, action: PayloadAction<boolean>) => {
      state.comment_success = action.payload
    },

    setScore: (state: RatingState, action: PayloadAction<number>) => {
      state.score = action.payload
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setFormLoading, setCommentSuccess, setRatingSuccess, setScore } = ratingsSlice.actions;

export const useRatingsSlice = () => createSliceWrapperHook(ratingsSlice, "ratings", {
  ratingSet,
});


export default ratingsSlice.reducer;
