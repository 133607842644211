import React, { useEffect, useMemo, useRef, useState } from "react";
import { AnyAction } from "redux";
import { wuid } from '../util';
import { PollPostID, PollRegData, PollRegDataPoll } from "../store/app/types";
import { PollChoice } from "../store/polls/types";
import throttle from 'lodash.throttle';
import { usePollsSlice } from "store/polls/reducer";
import { useAppSlice } from "store/app/reducer";
import { useSelector } from "react-redux";
import { selectAppRegistrationData, selectAppRegistrations } from "store/app/select";
import { selectChartLibLoaded } from "store/polls/select";


export interface PollProps {
  postId: PollPostID;
}


export const chartColors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];


const chartOptions: any = {
  //title: 'Title',
  is3D: false,
  pieHole: 0.4,
  colors: chartColors,
  backgroundColor: 'transparent',
  chartArea: {
    left:'5%',
    top:'5%',
    width:'90%',
    height:'90%',
    backgroundColor: 'transparent',
  },
  fontSize: 16,
  fontName: 'Quicksand, sans-serif',
  tooltip: {
    text: 'percentage',
    textStyle: {
      color: '#444444'
    }, 
    showColorCode: true
  },
  legend: {
    position: 'none', 
    textStyle: {
      color: '#444444',
      fontSize: 16
    }
  },
  animation: {
    startup: true,
    duration: 1000,
    easing: 'out',
  },			
};


export function Poll({ postId }: PollProps) {

  const chartEl:React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [ mywuid, setWUID ]  = useState('');
  const [ chart, setChart ]  = useState(null);

  const { register } = useAppSlice();
  const { pollChartLoadLib, pollVote } = usePollsSlice();

  const app_registrations = useSelector(selectAppRegistrations);
  const app_registration_data = useSelector(selectAppRegistrationData);
  const chart_lib_loaded = useSelector(selectChartLibLoaded);

  const post_id = useMemo(() => parseInt(postId as any) > 0 ? parseInt(postId as any) : postId, [ postId ])

  const registration = useMemo(() => {
    return app_registrations.find(r => r.type === 'poll' && r.post_id == post_id)
  }, [ app_registrations, post_id ])

  const registration_data = useMemo(() => {
    return app_registration_data.find(r => r.type === 'poll' && r.post_id == post_id);
  }, [ app_registration_data, post_id ])

  const poll = useMemo(() => {
    let poll: PollRegDataPoll | undefined;
    if (post_id && registration_data && registration && registration_data.type === 'poll' && registration.type === 'poll') {
      if (registration_data.post_id === 'featured' || registration_data.post_id === 'latest') {
        let idx = registration.wuids?.indexOf(mywuid);
        idx = idx || 0;
        poll = registration_data.data.polls[idx];
        if (!poll) {
          // if we don't have enough active polls to cover this one, then put up the last one
          poll = registration_data.data.polls[registration_data.data.polls.length - 1];
        }
      } else {
        poll = (registration_data.data as PollRegData).poll;
      }
    }
    return poll
  }, [ post_id, registration, registration_data             , app_registrations])

  // signal the store to init the chart library globally
  useEffect(() => {
    pollChartLoadLib()
  }, [ pollChartLoadLib ]);

  // assign a wuid to this chart instance
  // register this instance for data gathering
  useEffect(() => {
    if (!postId) {
      return;
    }
    const w = wuid();
    setWUID(w);
    register({
      type: "poll",
      post_id: postId,
      wuid: w,
      count: 1
    });
  }, [ postId, register ]);

  // redraw the chart on window resize
  useEffect(() => {
    const resizeListener = throttle(() => {
      if (chart_lib_loaded === true && poll && chartEl.current) {
        const c = drawChart(chart, chartEl.current, poll);
        setChart(c);
      }
    }, 100, { trailing: true });

    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [chart_lib_loaded, poll, chart]);
  
  // load the chart when the chart library inits
  useEffect(() => {
    if (chart_lib_loaded === true && poll && chartEl.current) {
      const c = drawChart(chart, chartEl.current, poll);
      setChart(c);
    }
  }, [ chart_lib_loaded, poll, setChart, chart ]);

  return (
    <div className="TRS-widget TRS-poll" style={{position: 'relative'}}>

      <div className="poll">

        <div className="title">
          <span>{poll?.data.title}</span>
          <PollSubtitle poll={poll}></PollSubtitle>
        </div>

        <div className="pr">
          <div className="chartContainer" style={{ position: 'relative', width: '100%' }}>
            <div className="chartContainerInner" style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '100%' }}>
              <div className="chart" ref={chartEl} style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}></div>
            </div>
          </div>


					<div className="c2">

						<div className="title-sbs">
		          <span>{poll?.data.title}</span>
    		      <PollSubtitle poll={poll}></PollSubtitle>
						</div>

	          <PollAnswers poll={poll}></PollAnswers>

						<div className="myanswer-sbs">
        			<PollMyAnswer postId={postId} currentPoll={poll}></PollMyAnswer>
						</div>

					</div>

        </div>

				<div className="myanswer">
        	<PollMyAnswer postId={postId} currentPoll={poll}></PollMyAnswer>
				</div>

      </div>

    </div>
  );
}


function drawChart(chart: any, chartEl: HTMLDivElement, poll: PollRegDataPoll) {
  let totalAnswers = poll.data?.answers?.reduce((a, w) => a + w.count, 0);

  const opts = !totalAnswers ? {...chartOptions, colors: ['#EEEEEE']} : chartOptions;

  if (chart) {
    const data = initData(poll);
    chart.draw(data, opts);
  } else {
    const chart = new (window as any).google.visualization.PieChart(chartEl); 
    const data = initData(poll);
    chart.draw(data, opts);
  }

  return chart
}

function initData(poll: PollRegDataPoll) {
  const google = (window as any).google;
  const data = new google.visualization.DataTable();
  data.addColumn('string', 'Answer');
  data.addColumn('number', 'Count');

  // check if all answers are zero
  let totalAnswers = poll.data?.answers?.reduce((a, w) => a + w.count, 0);
  if (!totalAnswers) {
    data.addRow(['No Answers', 1]);
  } else {
    poll.data.choices.forEach(c => {
      const a = poll.data?.answers?.find(a => a.choice_id === c.id);
      data.addRow([c.label, a ? a.count : 0]);
    })
  }

  return data;
}


export function PollSubtitle(props: {poll: PollRegDataPoll | undefined}) {
  const poll = props.poll;
  return poll?.data.subtitle ? 
    <small>{poll.data.subtitle}</small> 
  : null;
}


function PollAnswers(props: {poll: PollRegDataPoll | undefined}) {
  const poll = props.poll;
  if (!poll?.data.choices) {
    return null;
  }

  let cs: [PollChoice, number][] = [];
  let total = 0;
  for (let c of poll.data.choices) {
    let a = poll.data.answers?.find(a => a.choice_id === c.id);
    total += a?.count || 0;
    cs.push([c, a?.count || 0]);
  }

  return (
    <div className="answers">
      {cs.map((c, i) => 
      <div className="answer" key={c[0].id}>
        <span className={"dot dot-"+i} style={{ backgroundColor: chartColors[i] }}></span>
        <span className="label">{c[0].label}</span>
        <span className="pct">{Math.round(c[1] / total * 100) || 0}</span>
      </div>
      )}
    </div>
  )
}


function PollMyAnswer(props: PollProps & { currentPoll: PollRegDataPoll | undefined}) {

  const { setPoll } = usePollsSlice();

  const poll = props.currentPoll;
  if (!(poll && poll.data.choices)) {
    return null;
  }

  const aChoice = poll.data.choices.find(c => c.id === poll.answer_choice_id);

  let templ: JSX.Element;
  if (aChoice) {
    templ = (
      <div>
        <div className="yourans-label">
          Your Answer
        </div>
        <div className="yourans">
          {aChoice.label}
        </div>
        <button type="button" className="btn btn-primary" onClick={e => openModal(e, poll, setPoll)}>
          Change Answer
        </button>
      </div>
    );
  } else {
    templ = (
    <div>
      <button type="button" className="btn btn-primary" onClick={e => openModal(e, poll, setPoll)}>
        Add Your Answer
      </button>
    </div>
    );
  }

  return (
    <div>
      {templ}
    </div>
  )
}


function openModal(e: React.MouseEvent, poll: PollRegDataPoll, setPoll: Function) {
  const $ = (window as any).jQuery;
  setPoll(poll);
  $('#pollModal').modal('show');
}


export default Poll;
