import { AccountFormMessageType, AccountPage, AccountState, FormError } from './types'
import { accountUpdate, subscriptionCancel, subscribeUpdateInit, subscribeUpdateSetup } from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { StripeSubscription } from 'store/subscriptions/types';
import { User } from 'store/user/types';


const initialState: AccountState = {
  page: "edit",
  loading: false,
  user: undefined,
  error: false,
  errors: [],
  form_message_type: undefined,
  paymentUpdateSuccess: false
};


export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {

    setPage: (state: AccountState, { payload }: PayloadAction<AccountPage>) => {
      state.page = payload;
    },

    setUser: (state: AccountState, { payload }: PayloadAction<User | undefined>) => {
      state.user = payload
    },

    setLoading: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setFormMessageType: (state: AccountState, { payload }: PayloadAction<AccountFormMessageType>) => {
      state.form_message_type = payload;
      state.error = false;
      state.errors = [];
    },

    setError: (state: AccountState, { payload }: PayloadAction<FormError[]>) => {
      state.error = (payload && payload.length > 0);
      state.errors = payload && payload.length ? payload : [];
    },

    setSubscription: (state: AccountState, { payload }: PayloadAction<StripeSubscription | undefined>) => {
      state.subscription = payload
    },

    setPaymentUpdateSuccess: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.paymentUpdateSuccess = payload
    },

    setPaymentUpdateErrors: (state: AccountState, { payload }: PayloadAction<string[] | undefined>) => {
      state.paymentUpdateErrors = payload
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setPage, setError, setFormMessageType, setLoading, setUser, setSubscription, setPaymentUpdateSuccess, setPaymentUpdateErrors } = accountSlice.actions;

export const useAccountSlice = () => createSliceWrapperHook(accountSlice, "account", {
  accountUpdate,
  subscriptionCancel,
  subscribeUpdateInit,
  subscribeUpdateSetup,
});

export default accountSlice.reducer;