import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { lpad, secondsToHMS } from "../../util";
//import { selectMetaData } from "store/specialpage/select";
import { WatchSummaryEntry } from "store/specialpage/types";


export interface PortalWatchedIndicatorProps {
  showid: number
}


export function PortalWatchedIndicator({ showid }: PortalWatchedIndicatorProps) {
  showid = parseInt(showid as any);
 
  const watch_summary: WatchSummaryEntry[] | undefined = (window as any).TRS.watch_summary;

  const wsEntry = watch_summary ? watch_summary.find(w => w.show_id == showid) : null;
  const hms = wsEntry ? secondsToHMS(wsEntry.total) : null;

  return (
    <div className={"portal-watched-indicator "+(wsEntry && hms ? 'watched' : 'notwatched')}>
      {wsEntry && hms ? 
        <HMS {...hms} /> 
        : 
        <NotWatched />
      }
    </div>
  );
}


interface HMSProps extends ReturnType<typeof secondsToHMS> {}
function HMS({ h, m, s }: HMSProps) {

  let hfrag: JSX.Element | string = h > 0 ?
    <Fragment>{lpad(h+"", 2, '0')}:</Fragment>
    : '';

  return (
    <div className="hms">
      <span className="lbl">
        You watched for
      </span> {' '}
      <span className="time">
        {hfrag}{lpad(m+"", 2, '0')}:{lpad(s+"", 2, '0')}
      </span>
    </div>
  )
}

function NotWatched() {
  return (
    <div className="notwatched">
      Not watched yet
    </div>
  )
}


export default PortalWatchedIndicator;