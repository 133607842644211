import SigninFlow from "components/signInFlow";
import { useCallback, useEffect, useRef, useState } from "react"
import RatingCommentForm from "./RatingCommentForm";
import classNames from "classnames";

export interface RatingCommentLoginPopupProps {}

export type RatingCommentPopupMode = "rate" | "comment" | "question" | "signin";


export function RatingCommentLoginPopup(props: RatingCommentLoginPopupProps) {

  const mainRef = useRef<HTMLDivElement>(null);
  const [ popupShown, setPopupShown ] = useState<boolean>(false)


  useEffect(() => {
    // register bootstrap popup
    const $ = (window as any).jQuery;
    $(mainRef.current).modal({
      show: false
    })
  }, [ mainRef.current ]);


  useEffect(() => {

    function open(ev: Event) {
      setPopupShown(true)
    }

    function close() {
      setPopupShown(false)
    }

    // listen for custom events to open/close the modal
    window.addEventListener('TRS_RatingCommentLoginPopup_open', open)
    window.addEventListener('TRS_RatingCommentPopup_close', close)
    window.addEventListener('TRS.user.login', close) // close on successful login

    return () => {
      window.removeEventListener('TRS_RatingCommentLoginPopup_open', open)
      window.removeEventListener('TRS_RatingCommentPopup_close', close)
      window.removeEventListener('TRS.user.login', close)
    }
  }, [ mainRef.current, setPopupShown ])


  useEffect(() => {
    const $ = (window as any).jQuery;
    if (popupShown) {
      $(mainRef.current).modal('show');
    } else {
      $(mainRef.current).modal('hide');
    }
  }, [ mainRef.current, popupShown ]);



  const closeModal = useCallback(() => {
    setPopupShown(false)
  }, [ mainRef.current ])


  return (
  <div ref={mainRef} className="modal fade" id="{{id}}" tabIndex={-1} 
    role="dialog" aria-labelledby="RatingFormLoginPopup" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-body">

          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>

          <div className="TRS-widget mt-5">
            <SigninFlow />
          </div>

        </div>
      </div>
    </div>
  </div>  
  )
}
