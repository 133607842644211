import * as api from '../../api'
import { RootState } from '../';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setChartLibLoaded, setFormLoading, setVoteSuccess } from './reducer';
import { setRegisterPoll } from 'store/app/reducer';


export const pollChartLoadLib = createAsyncThunk<void, void, { state: RootState }>(
  'polls/loadChartLib',
  async function(_, { dispatch, getState } ) {

    const { chart_lib_loaded } = getState().polls;

    if (chart_lib_loaded === null) {
      try {
        dispatch(setChartLibLoaded(false));
        (window as any).google.charts.load('current', { 'packages': ['corechart'] });
        (window as any).google.charts.setOnLoadCallback(() => {
          dispatch(setChartLibLoaded(true));
        });
      } catch(e) {
        dispatch(setChartLibLoaded(false))
        throw new Error(e as any);
      }
    }
  }
)


type PollVoteArgs = {
  post_id: number
  data: { 
    choice: number
  }
}

export const pollVote = createAsyncThunk(
  'polls/loadChartLib',
  async function({ post_id, data}: PollVoteArgs, { dispatch }) {

    if (!data || !(data.choice > 0)) {
      return;
    }

    try {
      dispatch(setFormLoading(true))

      const res = await api.post('/poll/'+post_id, data);
      if (res && res.poll && res.poll.id) {
        dispatch( setRegisterPoll(res.poll) )
        dispatch( setVoteSuccess(true) )
      } else {

      }
      
    } catch (e) {
      // this should be a failed http response, probably a 401
      if (data.choice) {
        dispatch( setVoteSuccess(false) )
      }
    } finally {
      dispatch(setFormLoading(false))
    }
  }
)
