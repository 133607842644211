import { AppState, AppRegistration, AppRegistrationData, PollRegDataPoll } from './types'
import { mainFetch } from './actions';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';


const initialState: AppState = {
  loading: true,
  registrations: [],
  registration_data: [],
  isInitialized: false
};


export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {

    setLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    register: (state: AppState, { payload }: PayloadAction<AppRegistration>) => {
      state.registrations = addNoDupes( [ ...state.registrations ], payload);
    },

    setRegisterData: (state: AppState, { payload }: PayloadAction<AppRegistrationData[]>) => {
      state.registration_data = payload;
    },
    
    setRegisterPoll: (state: AppState, { payload }: PayloadAction<PollRegDataPoll>) => {
      const poll = payload as PollRegDataPoll;
      if (!(poll && poll.id) || !(state.registration_data)) {
        return state;
      }

      let regdata = ([ ...state.registration_data ] as AppRegistrationData[]);

      for (let i in regdata) {
        const d = { ...regdata[i] };
        if (d.type === 'poll') {
          if (d.post_id == poll.id) {
            regdata[i] = d;
            d.data = { poll: { ...poll } };
          } else if (d.post_id === 'featured' || d.post_id === 'latest') {
            const dpolls = d.data.polls.map(p => {
              if (p.id == poll.id) {
                return { ...poll };
              } else {
                return p;
              }
            })

            regdata[i] = d;
            d.data = {
              polls: dpolls,
            };
          }
        }
      }

      state.registration_data = regdata
    },

    setIsInitialized: (state: AppState, { payload }: PayloadAction<boolean>) => {
      state.isInitialized = payload;
    },

  },
  extraReducers: (builder) => {

  }
});


function addNoDupes(regs: AppRegistration[], reg: AppRegistration) {

  let has = regs.findIndex((r) => {
    if (r.type === reg.type) {
      if (r.type === 'rating') {
        if (r.post_id === reg.post_id) {
          return true;
        }
      } else if (r.type === 'poll') {
        if (r.post_id === reg.post_id) {
          return true;
        }
      }
    }
    return false;
  })

  if (has === -1) {
    reg.count = 1;

    if (reg.type === 'poll' && reg.wuid) {
      reg.wuids = [reg.wuid];
    }

    regs.push(reg);
  } else {
    let oldreg = { ...regs[has] };

    if (oldreg.type === 'poll' && reg.type === 'poll' && reg.wuid) {
      const newmap = oldreg.wuids ? [...oldreg.wuids] : [];
      newmap.push(reg.wuid);
      oldreg.wuids = newmap;
    }

    regs.splice(has, 1, { ...oldreg, count: regs[has].count + 1 })
  }
  
  return regs;
}


export const { setLoading, register, setRegisterData, setRegisterPoll, setIsInitialized } = appSlice.actions;

export const useAppSlice = () => createSliceWrapperHook(appSlice, "app", {
  mainFetch,
});


export default appSlice.reducer;
