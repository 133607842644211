import React from 'react';
import { selectSubscription, selectLoading } from 'store/account/select';
import { useSelector } from 'react-redux';
import { useAccountSlice } from '../../store/account/reducer';
import { SubscriptionDate } from '../subscribe/SubscriptionDate';
import { StripeSubscription } from '../../store/subscriptions/types';
import { Loader } from '../loader';


export interface SubscriptionCancelProps {
}

export function SubscriptionCancel({ }: SubscriptionCancelProps) {

  const { setSubscription, setPage } = useAccountSlice()

  const subscription = useSelector(selectSubscription);
  const loading = useSelector(selectLoading);

  if (!subscription) {
    return <></>
  }

  return (
    <div className="container-fluid-md">

      <Loader loading={loading} exclass="dark middle" />

      {subscription.cancel_at_period_end || subscription.cancelled_at ? (
        <SubscriptionCancelled subscription={subscription} />
      ) : (
        <SubscriptionCancelConfirm subscription={subscription} />
      )}

    </div>
  )
}


interface SubscriptionCancelSubProps {
  subscription: StripeSubscription
}

function SubscriptionCancelConfirm({ subscription }: SubscriptionCancelSubProps) {
  
  const { setPage, subscriptionCancel } = useAccountSlice()

  return (
    <div>
      <h1 className="mb-4">Cancel Subscription</h1>

      <p className="lead">
        Are you sure you want to cancel your subscription?
      </p>

      <h3>
        {subscription.plan.product.name} {" "} ({subscription.plan.nickname})
      </h3>

      <p>
        Your current subscription is paid until:<br />
        <SubscriptionDate timestamp={subscription.current_period_end} output="datetime" />
      </p>

      <p>
        If you choose to cancel, you will not be re-billed and your membership will end at the above date and time.
      </p>

      <div className="mt-4 row justify-content-center">
        <div className="col-sm-6 mb-2">
          <button 
            className="btn btn-outline-secondary"
            onClick={() => window.location.hash = "subscriptions"}
            >
            Go back
          </button>
        </div>

        <div className="col-sm-6 mb-2">
          <button 
            className="btn btn-danger"
            onClick={() => { subscriptionCancel(subscription.id) }}
            >
            Yes, Cancel this Subscription
          </button>
        </div>
      </div>

    </div>
  )
}
function SubscriptionCancelled({ subscription }: SubscriptionCancelSubProps) {
  
  const {  setPage } = useAccountSlice()

  return (
    <div>
      <h1 className="mb-4">Subscription Cancelled</h1>

      <p className="lead">
        Your subscription to<br />
        {" "}{subscription.plan.product.name} {" "} ({subscription.plan.nickname})<br />
        has been cancelled.
      </p>

      <p>
        Your membership will end on:<br />
        <SubscriptionDate timestamp={subscription.current_period_end} output="datetime" />
      </p>

      <div className="mt-4 row justify-content-center">
        <div className="col-sm-6 mb-2">
          <button 
            className="btn btn-outline-secondary"
            onClick={() => window.location.hash = "subscriptions"}
            >
            Go back
          </button>
        </div>
      </div>

    </div>
  )
}