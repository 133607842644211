import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { get as apiGet } from '../../api'
import InnerHTML from '../DangerouslySetHtmlContent';
import { Loader } from "../loader";

export interface PortalMainContentFlowProps {}


export function PortalMainContentFlow({}: PortalMainContentFlowProps) {

  const [ pageData, setPageData ] = useState<any>();

  const getPage = () => {
    // get the show id from the url
    const parts = window.location.pathname.slice(1).split(/\//g);
    const pagename = parts[1];

    // check for a draft key
    const draft_key = new URLSearchParams(window.location.search).get('draft_key');

    // fetch the show from the website api
    const fetch = async () => {
      try {
        const res = await apiGet(`/portal/${pagename}` + (draft_key ? `?draft_key=${draft_key}` : '')) 
        if (res && res.metadata) {
          setPageData(res);
        } else {
          setPageData(undefined);
        }
      } catch (e) { 
        console.error(e) 
      }
    }

    fetch();
  }

  useEffect(() => {
    getPage();
  }, []);


  // re-fetch the the page content if the user logs in or out
  useEffect(() => {

    const handleLogin = () => {
      getPage();
    }
    const handleLogout = () => {
      getPage();
    }

    window.addEventListener('TRS.user.login', handleLogin)
    window.addEventListener('TRS.user.logout', handleLogout)

    return () => {
      window.removeEventListener('TRS.user.login', handleLogin)
      window.removeEventListener('TRS.user.logout', handleLogout)
    }
  }, [])


  useEffect(() => {
    // set the watch_summary on window.TRS so we can find it
    if (pageData?.metadata?.watch_summary) {
      (window as any).TRS.watch_summary = pageData?.metadata?.watch_summary;
    }

    // re-init TRS components to pick up any components that may be in the content
    if (typeof (window as any).TRSRender === 'function') {
      (window as any).TRSRender();
    }
  }, [ pageData ]);


  return (
    <>
      {pageData && pageData.__html ? (
        <InnerHTML html={pageData.__html}></InnerHTML>
      ) : (
        <div className="my-7" style={{ minHeight: "60vh" }}>
          <Loader loading={true} exclass="dark middle">
            Loading Potal Content...
          </Loader>
        </div>
      )}
    </>
  )


}


export default PortalMainContentFlow;
