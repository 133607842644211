import config from './config';


export type FetchMethod = 'GET' | 'POST';


const domain = config.host;
const path_pfx = '/api/v1';
const pfx = domain+path_pfx;


export async function get(path: string) {
  return doFetch('GET', path);
}


export async function post(path: string, data: any) {
  return doFetch('POST', path, data);
}


async function doFetch(method: FetchMethod, path: string, data?: any) {

  const init: RequestInit = {
    method: method,
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
    }
  }

  if (data && method === 'POST') {
    init.body = JSON.stringify(data);
    init.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'      
    }
  }


  try {
    const res = await fetch(pfx+path, init);

    if (res.status >= 100 && res.status < 300) {
      const b = await res.json();
      return b;
    } else {
      throw res;
    }
  } catch (e) {
    if (e && (e as any).status) {
      throw e;
    }
    console.error(e);
    return false;
  }
}
