import React, { useMemo } from "react"

export interface SubscriptionDateProps {
  timestamp: number
  output: "date" | "time" | "datetime"
}

export function SubscriptionDate({ timestamp, output }: SubscriptionDateProps) {

  const formatted: string = useMemo(() => {
    const d = new Date(timestamp * 1000);
    switch(output) {
      case "date": {
        return d.toLocaleDateString();
        break;
      }
      case "time": {
        return d.toLocaleTimeString();
        break;
      }
      default: {
        return d.toLocaleString();
      }
    }
  }, [ timestamp ])

  return (
    <>{formatted}</>
  )
}