import React, { useMemo } from "react";
import config from "../config";
import { SearchResult, SearchResultShow } from "../store/search/types";
import { seFormat } from "../util";
import { RuddlePlusTag } from '../components/RuddlePlusTag'


export interface SearchResultItemProps {
  result: SearchResult;
}

export function SearchResultItem({ result }: SearchResultItemProps) {

  let desc = result.highlighted.length > 1 
    ? result.highlighted 
    : result.description;

  desc = desc.replace(/\\n+/g, "\n");

  let href = result.href || '/';
  if (result.static) {
    href = config.static_host + href;
  }

  let target = result.target || "";

  const hasParent = !!result.parent_id;
  const typeLabel = useMemo(() => getPostLabel(result), [ result ]);
  const hasBigTitle = useMemo(() => ['show', 'podcast', 'portal'].includes(result.post_type), [ result ]);

  return (
    <div className={'result result-'+result.post_type.toLowerCase()+(hasParent ? ' result-subtype-clip' : '')}>
      <a href={href} target={target}>
        <span className={'type type-'+result.post_type.toLowerCase()+(hasParent ? ' subtype-clip' : '')}>
          {typeLabel}
        </span>
        <span className="info">

          <span className="titleRow">
            {hasBigTitle 
              ? <BigTitle result={result} /> 
              : (
                <span className="title">
                  {result.title}
                </span>
              )}

            {'access' in result.data && result.data?.access === "premium" && (
              <span className="premium">
                <RuddlePlusTag />
              </span>
            )}
          </span>

          <span className="snip" 
            dangerouslySetInnerHTML={{ __html: desc }}></span>
          
        </span>
      </a>
    </div>
  );

}


function BigTitle({ result }: { result: SearchResult }) {

  result = result as SearchResultShow;

  return (
    <span className="bigTitle">
      {result.data.title_pre && (
      <span className="title_pre">
        {result.data.title_pre}
      </span>
      )} 
      <span className="title">
        {result.data.title}
      </span>
      {result.data.title_post && (
      <span className="title_post">
        {result.data.title_post}
      </span>
      )} 

    </span>
  )
}



function getPostLabel(result: SearchResult) {

  const hasParent = !!result.parent_id;

  let ret = <></>;

  switch(result.post_type) {
    case "show": {
      if (hasParent) {
        if (result.parent_data?.season_number && result.parent_data?.episode_number) {
          ret = <><span className="t">Clip</span> from <span className="se">{seFormat(result.parent_data?.season_number, result.parent_data?.episode_number)}</span></>
        } else {
          ret = <><span className="t">Clip</span></>
        }
      } else {
        if (result.data.season_number && result.data.episode_number) {
          ret = <><span className="se">{seFormat(result.data.season_number, result.data.episode_number)}</span></>
        } else {
          ret = <><span className="se">Show</span></>
        }
      }
      break;
    }

    case "podcast": {
      if (hasParent) {
        const ep = result.parent_data?.episode_number ? '#'+result.parent_data?.episode_number : "";
        ret = <><span className="t">Clip</span>{ep && <>from <span className="se">Podcast {ep}</span></>}</>
      } else {
        const ep = result.data.episode_number ? '#'+result.data.episode_number : "";
        ret = <><span className="t">Podcast</span> <span className="se">{ep}</span></>
      }
      break;
    }

    case "portal": {
      ret = <><span className="t">Portal</span></>
      break;
    }

    case "blog": {
      ret = <><span className="t">Blog</span></>
      break;
    }

    case "pdf": {
      ret = <><span className="t">PDF</span></>
      break;
    }

    case "poll": {
      ret = <><span className="t">Poll</span></>
      break;
    }

    // default is Page
    default: {
      ret = <><span className="t">Page</span></>
      break;
    }

  }

  return ret;
}



export default SearchResultItem;
