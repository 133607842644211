import React, { useMemo } from "react";
import { md5 } from "../../md5";
import { imageResizeURL } from "../../resizeURL";
import { lpad, seFormat } from "../../util";
import classNames from "classnames";
import { PacificDate } from "../PacificDate"


export interface PortalListPortalProps {
  staticURL: string
  fadein: boolean
  index: number
  portal: any
}


export function PortalListPortal({ staticURL, fadein, index, portal }: PortalListPortalProps) {

  const titleParts = useMemo<string[]>(
    () => ([ portal.data.title_pre || "", portal.data.title || "", portal.data.title_post || ""].filter(s => s.length)),
    [ portal ]
  );

  const delay = 0.125 * (index % 3);

  return (
    <div className="portal-listing">
      <a href={`/portal/${portal.urlpath}`} className="img d-block">
        <img src={`${staticURL}/portal-uploads/${md5(portal.id + "" + portal.updated)}/${portal.id}.thumbnail.jpg`} 
          className="img-fluid d-block mx-auto" 
          alt={titleParts.join(' - ')}
          />
      </a>
      <div className="title mt-2">
        <h3>
          {portal.data.title_pre && <span className="d-block">{portal.data.title_pre}</span>}
          <span className="d-block">{portal.data.title}</span>
          {portal.data.title_post && <small className="d-block">{portal.data.title_post}</small>}
        </h3>
      </div>
      <div className="desc mt-3">
        {portal.data.description}
      </div>
      <div className="cta mt-3 text-center">
        <a href={`/portal/${portal.urlpath}`} className="btn btn-primary btn-premium">View Portal</a>
      </div>
      
      {portal.data.access === "premium" &&
        <div className="ruddleplus-block">
          <span className="ruddleplus-text highlight">RUDDLE<img src={`${staticURL}/images/plusicon.png`} width="20" height="20" /></span>
            <p className="mb-0">Premium Access</p>
        </div>
      }
      
    </div>
  );
}


function formatDuration(input: string) {
  let n = parseInt(input);

  let h,m;

  h = Math.floor(n / 3600);
  n = n - (h * 3600);
  
  m = Math.floor(n / 60);
  n = n - (m * 60);

  if (h) {
    return `${h}:${lpad(m+"", 2, '0')}:${lpad(n+"", 2, '0')}`
  } else {
    return `${lpad(m+"", 2, '0')}:${lpad(n+"", 2, '0')}`
  }

}

