import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { UserState } from "../store/user/types";
import { AppState } from "../store/app/types";
import { selectLoaded, selectUser } from "store/user/select";
import { useAppSlice } from "store/app/reducer";
import { selectAppRegistrations } from "store/app/select";


export interface MainProps {}

export function Main() {

  const { mainFetch } = useAppSlice();

  const user = useSelector(selectUser);
  const userLoaded = useSelector(selectLoaded);

  const app_registrations = useSelector(selectAppRegistrations)

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (userLoaded && !init) {
      window.dispatchEvent(new Event('TRS.user-init'));
      window.dispatchEvent(new Event('TRS.data-init'));
      setInit(true);
    }
  }, [ userLoaded, init ])


  useEffect(() => {    
    // fetch user info and page widget data
    mainFetch( app_registrations );

    if (app_registrations.findIndex(r => r.type === 'poll') > -1) {
      (window as any)._CHART_LIB_LOADED = false;
      const google = (window as any).google;
      google.charts.load("current", { packages: ["corechart"] }).then(() => {  
        (window as any)._CHART_LIB_LOADED = true;
        window.dispatchEvent(new Event('chart-lib-loaded'));
      });
    }

  // eslint-disable-next-line
  }, [])


  useEffect(() => {
    // update the document body with the appropriate parent classes
    // to indicate loading state and user logged in or not
    const cl = document.body.classList;
    
    if (userLoaded) {
      cl.remove('TRS-loading');
      cl.add('TRS-loaded');
    } else {
      cl.remove('TRS-loaded');
      cl.add('TRS-loading');
    }

    if (user) {
      cl.remove('TRS-logged-out');
      cl.add('TRS-logged-in');
    } else {
      cl.remove('TRS-logged-in');
      cl.add('TRS-logged-out');
    }
  }, [ user, userLoaded ]);

  return (
    <div></div>
  );
}


export default Main;