import { UserState, SigninFlowStep, User, LoginError, ForgotpassResult } from './types'
import { userSendLink, userSignup, userLogout, userLogin, userFetch } from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { useCallback } from 'react';


const initialState: UserState = {
  loaded: false,
  user: undefined,
  form_loading: false,
  login_error: false,
  login_errors: [],
  signup_step: 1,
  flow_step: 'login'
};


export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {

    setFlowStep: (state: UserState, { payload }: PayloadAction<SigninFlowStep>) => {
      let step = payload;
      if (state.user && state.user.id) {
        if (step !== 'logout') {
          step = 'logout';
        }
      }
      state.flow_step = step;
    },

    setUser: (state: UserState, { payload }: PayloadAction<{
      user: User | undefined;
      loaded?: boolean | undefined;
      login_error?: boolean | undefined;
      login_errors?: LoginError[];
    }>) => {
      state.user = payload.user;
      state.loaded = payload.loaded !== undefined ? payload.loaded : state.loaded;
      state.login_error = payload.login_error !== undefined ? payload.login_error : state.login_error;
      state.login_errors = payload.login_errors !== undefined ? payload.login_errors : state.login_errors;
    },

    setFormLoading: (state: UserState, { payload }: PayloadAction<boolean>) => {
      state.form_loading = payload
    },

    setForgotPassResult: (state: UserState, { payload }: PayloadAction<{ result: ForgotpassResult }>) => {
      state.forgotpass_result = payload.result
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setUser, setFlowStep, setForgotPassResult, setFormLoading } = userSlice.actions;

export const useUserSlice = () => createSliceWrapperHook(userSlice, "user", {
  userSendLink,
  userSignup,
  userLogout,
  userLogin,
  userFetch,
});


export default userSlice.reducer;