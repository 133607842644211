import React from "react"
import classnames from 'classnames'
import config from '../config'

export interface RuddlePlusTagProps {
  exClass?: string
}

export function RuddlePlusTag({ exClass }: RuddlePlusTagProps) {
  return (
    <div className={classnames("ruddleplus-block", exClass)}>
      <span className="ruddleplus-text">Ruddle<img src={`${config.static_host}/images/plusicon.png`} className="zoomin" /></span>
      <p className="mb-0">Premium Access</p>
    </div>    
  )
}