import React from "react";
//import classNames from 'classnames';
import { SearchQueryResult } from "../store/search/types";
import { Loader } from "./loader";
import SearchResultItem from "./SearchResultItem";


export interface SearchResultListProps {
  loading: boolean;
  query: string;
  result: SearchQueryResult | undefined;
}


export function SearchResultList(props: SearchResultListProps) {

  return (
    <div className="TRS-widget TRS-searchResultList" style={{position: 'relative'}} >

      {props.result?.results?.map(r => (
        <SearchResultItem result={r} key={r.id} />
      ))}

      {!(props.result?.results && props.result?.results.length) && (
        <div className="no-results">
          <p>Sorry, we couldn't find any results for the term "{props.query}".</p>
          <p>Please try a different search term.</p>
        </div>
      )}

      <Loader loading={props.loading} exclass="dark" />

    </div>
  );
}



export default SearchResultList;
