import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { AccountState, AccountPage } from "../../store/account/types";
import classNames from 'classnames';
import { useAccountSlice } from "store/account/reducer";
import { selectUser } from "store/user/select";
import { selectError, selectErrors, selectFormMessageType } from "store/account/select";



interface AccountEditFormProps {
  changePage: (step: AccountPage) => void;
}


export function AccountEditForm({ changePage }: AccountEditFormProps) {

  const { accountUpdate } = useAccountSlice();

  const user = useSelector(selectUser);
  const form_message_type = useSelector(selectFormMessageType);
  const hasError = useSelector(selectError);
  const errors = useSelector(selectErrors);
  

  const [myuid] = useState( (window as any).TRS.wuid() );
  const [showPass, setShowPass] = useState( false );

  const [submitted, setSubmitted ] = useState(false);
  const [formError, setFormError ] = useState('');
  const [email, setEmail] = useState(user?.email || '');
  const [first_name, setFName] = useState(user?.first_name || '');
  const [last_name, setLName] = useState(user?.last_name || '');
  const [password, setPassword] = useState('');
  const [password_confirm, setPasswordConfirm] = useState('');
  const [subscribe1, setSubscribe1] = useState(user?.email_opt !== -1);

  useEffect(() => {
    setEmail(user?.email || '');
    setFName(user?.first_name || '');
    setLName(user?.last_name || '');
    setPassword('');
    setPasswordConfirm('');
    setSubscribe1(user?.email_opt !== -1);
  }, [ user ])

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const f = (e.target as HTMLFormElement);
    
    setSubmitted(true);

    if (!f.checkValidity()) {
      setFormError('Please check the form for errors');
      return;
    }

    // check min length of password
    if (showPass) {
      if (password.length < 8) {
        setFormError("Password must be at least 8 characters long (longer is recommended)");
      }

      // check password matching
      if (password !== password_confirm) {
        setFormError("Password and confirmation don't match");
        return;
      }
    }

    setFormError('');

    const update: any = {
      email,
      first_name,
      last_name,
      subscribe1,
    }

    if (showPass) {
      update.password = password;
    } else {
      update.password = "";
    }

    // submit the form
    accountUpdate({
      email,
      first_name,
      last_name,
      password,
      subscribe1,
    })

    setShowPass(false);
  }

  if (!user || !user.id) {
    return (
      <div></div>
    )
  }

  return (
    <div className="AccountEditForm container-fluid-md">
      
      <div className="h1">Edit Account Information</div>

      <div className={classNames('alert alert-info', {'d-none': form_message_type !== "success"})}>
        <h4>Account Updated</h4>
      </div>

      <div className={classNames('alert alert-danger', {'d-none': !formError})}>
        <h4>Error</h4>
        <p>{formError}</p>
      </div>
      <div className={classNames('alert alert-danger', {'d-none': !hasError})}>
        <h4>Error</h4>
        {errors.map(e => {

          if (e.type === 'email_exists') {
            return (
            <div>
              <p>
                The email address <b>"{email}"</b> already exists in our system.
                <a href="/">Login instead?</a>
              </p>
              <p><a href="/">Forgot password?</a></p>
            </div>
            )
          }

          return (
            <p>{e.msg}</p>
          )
        })}
      </div>

      <form className="account-edit-form" action="" method="POST" onSubmit={submitForm} noValidate>
        
        <div className={classNames('form-group', {'was-validated': email.length >= 4 || submitted})}>
          <label>Email Address</label>
          <input type="email" className="form-control" name="email" placeholder="Email" required autoComplete="username" 
            minLength={4}
            maxLength={255}
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
          <div className="invalid-feedback">
            Please enter a valid email address
          </div>                  
        </div>

        <div className={classNames('form-group', {'was-validated': first_name.length >= 1 || submitted})}>
          <label>First Name</label>
          <input type="text" className="form-control" name="first_name" placeholder="First name" required 
            autoComplete="given-name"
            minLength={1}
            maxLength={255}
            value={first_name}
            onChange={(e) => setFName(e.target.value)} />
          <div className="invalid-feedback">
            First Name is required
          </div>      
        </div>

        <div className={classNames('form-group', {'was-validated': last_name.length >= 1 || submitted})}>
          <label>Last Name</label>
          <input type="text" className="form-control" name="last_name" placeholder="Last name" required 
            autoComplete="family-name" 
            minLength={1}
            maxLength={255}
            value={last_name}
            onChange={(e) => setLName(e.target.value)} />
          <div className="invalid-feedback">
            Last Name is required
          </div>      
        </div>

        
        <div className="form-group">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={e => setShowPass(!showPass)}>
            {showPass ? 'hide change password' : 'Change Password' }
          </button>
        </div>

        <div className={classNames("resetPassFields", { 'd-none': !showPass })}>
          <div className={classNames('form-group', {'was-validated': password.length >= 8 || submitted})}>
            <label>Password</label>
            <input type="password" className="form-control" name="Password" placeholder="password" 
              required={showPass}
              autoComplete="new-password"  
              minLength={8}
              maxLength={255}
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
            <div className="invalid-feedback">
              {password.length < 8 ? 'Password must be at least 8 characters long (longer is recommended)' : 
                'Password is required'}
            </div>      
          </div>
          
          <div className={classNames('form-group', {'was-validated': password.length >= 1 || submitted})}>
            <label>Confirm Password</label>
            <input type="password" className={classNames('form-control', { 'is-invalid': submitted && password !== password_confirm })} 
              name="password_confirm" placeholder="Confirm password"
              required={showPass}
              autoComplete="confirm-password"  
              minLength={8}
              maxLength={255}
              value={password_confirm}
              onChange={(e) => setPasswordConfirm(e.target.value)} />
            <div className="invalid-feedback">
              {password.length >= 8 && password !== password_confirm ? "Password and confirmation don't match" : ''}
            </div>      
          </div>
        </div>

        <div className="form-group">
          <div className={classNames('custom-control custom-checkbox')}>
            <input type="checkbox" className="custom-control-input" id={"SignupEmailOpt" + myuid}
              checked={subscribe1}
              value={1} 
              onChange={(e) => setSubscribe1(e.target.checked) } />
            <label className="custom-control-label" htmlFor={"SignupEmailOpt" + myuid}>Subscribe to mailing list for Announcements and New Show notices</label>
          </div>
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>

    </div>
  );
}



export default AccountEditForm;
