import React, { useEffect, useCallback, useRef, useState } from "react";
import { Loader } from "./loader";
import { SigninFlowStep, User } from "../store/user/types";
import LoginForm from "./loginForm"
import LogoutForm from "./logoutForm"
import SignupForm from "./signupForm"
import ForgotPassForm from "./forgotPassForm"
import classNames from 'classnames';
import { setUser, useUserSlice } from "store/user/reducer";
import { useSelector } from "react-redux";
import { selectFlowStep, selectFormLoading, selectLoadedUser, selectUser } from "store/user/select";
import { selectAppIsInitialized } from "store/app/select";


export interface SigninFlowProps {}

export function SigninFlow({}: SigninFlowProps) {

  const [ fbMessage, setFBMessage ] = useState(false);

  const { setFlowStep } = useUserSlice();
  const initialUserRef = useRef<User | undefined | false>(false);

  const appIsInitialized = useSelector(selectAppIsInitialized);
  const user = useSelector(selectLoadedUser);
  const flow_step = useSelector(selectFlowStep);
  const form_loading = useSelector(selectFormLoading);

  const changeStep = useCallback((step: SigninFlowStep) => {
    setFlowStep(step)
  }, [ setFlowStep ]);

  useEffect(() => {
    window.addEventListener('TRS.set.signupStep', (e: Event) => {
      e.stopPropagation();
      let step = (e as CustomEvent).detail;
      if (step) {
        changeStep(step);
      }
    });  
  }, [ changeStep ]);

  
  useEffect(() => {
    if (user === false) return;

    if (user?.id && flow_step !== 'logout') {
      changeStep('logout');
    } else if (!user?.id && flow_step === 'logout') {
      changeStep('login');
    }
  }, [ user, changeStep ]);


  // watch for when the user logs in and logs out
  // dispatch an event on window when that happens
  useEffect(() => {
    if (user !== false) {
      if (initialUserRef.current === false) {
        initialUserRef.current = user;
      } else {
        if (user) {
          window.dispatchEvent(new Event('TRS.user.login'))
        } else {
          window.dispatchEvent(new Event('TRS.user.logout'))
        }
        initialUserRef.current = user;
      }
    }
  }, [ user, initialUserRef ])


  return (
    <div className="TRS-widget SigninFlow" style={{position: 'relative'}}>

      <div className={classNames('flow', { 'active': flow_step === 'login' })}>
        <LoginForm changeStep={changeStep} setFBMessage={setFBMessage} />
      </div>

      <div className={classNames('flow', { 'active': flow_step === 'signup' })}>
        <SignupForm changeStep={changeStep} />
      </div>

      <div className={classNames('flow', { 'active': flow_step === 'logout' })}>
        <LogoutForm changeStep={changeStep} />
      </div>

      <div className={classNames('flow', { 'active': flow_step === 'forgotpass' })}>
        <ForgotPassForm changeStep={changeStep} fbMessage={fbMessage} />
      </div>

      <Loader loading={form_loading} exclass="dark" />

    </div>
  );
}


export default SigninFlow;