import { User, UserForm } from './types';
import * as api from '../../api'
import { AppRegistration, AppRegistrationData } from '../app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setForgotPassResult, setFormLoading, setUser } from 'store/user/reducer';
import { setRegisterData } from 'store/app/reducer';


export const userFetch = createAsyncThunk(
  'user/fetch',
  async function(_, { dispatch }) {

    let user: User | undefined;
    try {
      const res = await api.get('/auth/check');
      user = res && res.user && res.user.id ? (res.user as User) : undefined;
    } catch (e) {
      // this should be a failed http response, probably a 401
    } finally {
      dispatch(setUser({ 
        user: user || undefined, 
        loaded: true
      }))
    }
  }
)



interface userLoginArgs {
  username: string
  password: string
  registers: AppRegistration[]
}
export const userLogin = createAsyncThunk(
  'user/login',
  async function({ username, password, registers }: userLoginArgs, { dispatch }) {
    try {
      dispatch(setFormLoading(true))

      const res = await api.post('/auth/login', {
        username,
        password,
        registers,
      });

      const user = res && res.user && res.user.id ? (res.user as User) : undefined;
      dispatch( setUser({ user, login_error: false }) )

      const regs = (res && res.registers && Array.isArray(res.registers) ? res.registers : []) as AppRegistrationData[];
      dispatch(setRegisterData(regs || []))

      window.dispatchEvent(new Event('TRS.logged-in'));

    } catch (e) {
      // this should be a failed http response, probably a 401
      dispatch(setUser({ user: undefined, login_error: true }))
    } finally {
      dispatch(setFormLoading(false))
    }
  }
)


export const userLogout = createAsyncThunk(
  'user/logout',
  async function(registers: AppRegistration[], { dispatch }) {
    try {
      dispatch(setFormLoading(true))

      const res = await api.post('/auth/logout', { registers });
      
      dispatch( setUser({ user: undefined, login_error: false }) )

      const regs = (res && res.registers && Array.isArray(res.registers) ? res.registers : []) as AppRegistrationData[];
      dispatch(setRegisterData(regs || []))

      window.dispatchEvent(new Event('TRS.logged-out'));

    } catch (e) {
      // this should be a failed http response, probably a 401
      dispatch(setUser({ user: undefined, login_error: true }))
    } finally {

      dispatch(setFormLoading(false))
    }
  }
)



export const userSignup = createAsyncThunk(
  'user/signup',
  async function(userForm: UserForm, { dispatch }) {
    try {
      dispatch(setFormLoading(true))

      const res = await api.post('/auth/', {
        ...userForm,
      });

      const errors = res && res.error ? res.errors : null;

      if (errors) {
        dispatch(setUser({ user: undefined, login_error: true, login_errors: errors }))
      } else {
        const user = res && res.user && res.user.id ? (res.user as User) : undefined;
        dispatch(setUser({ user, login_error: false, login_errors: [] }))

        window.dispatchEvent(new Event('TRS.signed-up'));
        window.dispatchEvent(new Event('TRS.logged-in'));
      }

    } catch (e) {
      // this should be a failed http response, probably a 401
      dispatch(setUser({ user: undefined, login_error: true }))
    } finally {
      dispatch(setFormLoading(false))
    }
  }
)


interface userSendLinkArgs {
  username: string
  url: string
}
export const userSendLink = createAsyncThunk(
  'user/sendLink',
  async function({ username, url }: userSendLinkArgs, { dispatch }) {
    try {
      dispatch(setFormLoading(true))

      const res = await api.post('/auth/magic_token', {
        email: username,
        url,
      });

      if (res && res.success) {
        dispatch(setForgotPassResult({ result: "success" }))
      }

    } catch (e) {
      // this should be a failed http response, probably a 401
      dispatch(setForgotPassResult({ result: "error" }))
    } finally {
      dispatch(setFormLoading(false))
    }
  }
)

