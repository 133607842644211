import React from "react";
import { Loader } from "./loader";
import { State } from "../store/types";
import { useSelector } from "react-redux";
import { selectFormLoading, selectUser } from "store/user/select";


const mapStateToProps = (state: State) => ({
  ...state.user,
})

const mapDispatchToProps = {}


export function UserInfo() {

  const user = useSelector(selectUser);
  const form_loading = useSelector(selectFormLoading);

  
  return (
    <div className="TRS-widget userInfo" style={{position: 'relative'}}>

      <h4>Signed-In as</h4>
      <div className="name">{user?.first_name} {' '} {user?.last_name}</div>
      <div className="email">{user?.email}</div>

      <Loader loading={form_loading} exclass="dark" />

    </div>
  );
}


export default UserInfo;
