import { fetchSubscriptions } from './actions';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StripeSubscription, SubscriptionsState } from './types';


const initialState: SubscriptionsState = {
  loadingSubscriptions: false,
  stripeSubscriptions: []
};


export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialState,
  reducers: {

    setLoadingSubscriptions: (state: SubscriptionsState, { payload }: PayloadAction<boolean>) => {
      state.loadingSubscriptions = payload
    },

    setStripeSubscriptions: (state: SubscriptionsState, { payload }: PayloadAction<StripeSubscription[]>) => {
      state.stripeSubscriptions = payload
    },
  },
  extraReducers: (builder) => {

  }
});


export const { setLoadingSubscriptions, setStripeSubscriptions } = subscriptionsSlice.actions;

export const useSubscriptionsSlice = () => createSliceWrapperHook(subscriptionsSlice, "subscriptions", {
  fetchSubscriptions,
});


export default subscriptionsSlice.reducer;