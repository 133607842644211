import { SearchQueryResult, SearchState } from './types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { searchFetch } from './actions';
import { useCallback } from 'react';


const initialState: SearchState = {
  loading: false,
  query: '',
  result: undefined,
};


export const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {

    setLoading: (state: SearchState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    setQuery: (state: SearchState, action: PayloadAction<string>) => {
      state.query = action.payload
    },

    setResult: (state: SearchState, action: PayloadAction<SearchQueryResult>) => {
      state.result = action.payload
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setLoading, setQuery, setResult } = searchSlice.actions;

export const useSearchSlice = () => createSliceWrapperHook(searchSlice, "search", {
  searchFetch,
});


export default searchSlice.reducer;