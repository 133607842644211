import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api';
import { setFormLoading, setListLoaded, entitiesClear, entitiesFetched } from './reducer';


type StartSeatchArgs = {
  category?: string
  tag?: string
  sort: string
  qType: string
  asc: boolean
  offset: number
  limit?: number
}

export const startSearch = createAsyncThunk(
  'entitylist/startsearch',
  async function({ category, tag, sort, qType, asc, offset, limit }: StartSeatchArgs, { dispatch }) {
    try {

      dispatch(setFormLoading(true));
      dispatch(setListLoaded(false));

      if (offset === 0) {
        dispatch(entitiesClear());
      }

      const sp = new URLSearchParams();
      if (category) sp.set('category', category);
      if (tag) sp.set('tag', tag);
      if (sort) sp.set('sort', sort);
      if (qType) sp.set('qtype', qType);
      if (asc) sp.set('asc', '1');
      if (offset > 0) sp.set('offset', offset.toString());
      if (limit && limit > 0) sp.set('limit', limit.toString());

      const res = await api.get('/entitylist/?'+sp.toString());

      dispatch(entitiesFetched(res));
      
    } catch(e) {
      dispatch(setFormLoading(false));
      throw new Error(e as any);
    }
  }
)



export const startSearchOnType = createAsyncThunk(
  'entitylist/startsearchontype',
  async function({ category, tag, sort, qType, asc, offset, limit }: StartSeatchArgs, { dispatch }) {
    try {

      dispatch(setFormLoading(true));
      dispatch(setListLoaded(false));

      if (offset === 0) {
        dispatch(entitiesClear());
      }

      const sp = new URLSearchParams();
      if (category) sp.set('category', category);
      if (tag) sp.set('tag', tag);
      if (sort) sp.set('sort', sort);
      if (qType) sp.set('qtype', qType);
      if (asc) sp.set('asc', '1');
      if (offset > 0) sp.set('offset', offset.toString());
      if (limit && limit > 0) sp.set('limit', limit.toString());

      const res = await api.get('/entitylist/?'+sp.toString());

      dispatch(entitiesFetched(res));
      
    } catch(e) {
      dispatch(setFormLoading(false));
      throw new Error(e as any);
    }
  }
)