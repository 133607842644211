import * as api from '../../api'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { incrementAccessPollCount, setClientSecret, setGranted, setInitError, setLoading, setPrice, setPublicKey, setSubscriptionId, setUserData } from './reducer'

interface Order {
  product: string
}


type InitArgs = {
  price_id: string
}

export const subscribeInit = createAsyncThunk(
  'subscribe/init',
  async function({ price_id }: InitArgs, { dispatch }) {
    try {

      dispatch(setLoading(true))

      const s = new URLSearchParams({ price_id });
      const res = await api.get('/subscribe/init?'+s);

      if (res.error) {
        const msg1 = res.errors?.[0]?.msg || "";
        if (/^No such price: /i.test(msg1)) {
          dispatch(setInitError("price_not_found"))
        } else {
          dispatch(setInitError("unknown"))
        }
        
        return;
      }

      dispatch(setPublicKey(res.public_key))
      dispatch(setSubscriptionId(res.clientSecret))
      dispatch(setClientSecret(res.clientSecret))
      dispatch(setPrice(res.price))
      dispatch(setUserData(res.user))
      dispatch(setLoading(false))
      
    } catch(e: any) {
      
      dispatch(setLoading(false))
      dispatch(setInitError("unknown"))

      throw new Error(e as any);
    }
  }
)



export const subscribeStripeInit = createAsyncThunk(
  'subscribe/stripe_init',
  async function(_args, { dispatch }) {
    try {

      dispatch(setLoading(true))

      const res = await api.get('/subscribe/stripe');

      if (res.error) {
        dispatch(setInitError("price_not_found"))
        return;
      }

      dispatch(setPublicKey(res.public_key))
      dispatch(setLoading(false))
      
    } catch(e: any) {
      
      dispatch(setLoading(false))
      dispatch(setInitError("unknown"))

      throw new Error(e as any);
    }
  }
)


export const pollForSubscribedAccess = createAsyncThunk(
  'subscribe/pollForAccess',
  async function({ grant }: { grant: string }, { dispatch }) {

    for (let i = 0; i < 1000; i++) {
      try {
        const res = await pollAccess(grant);
        if (res) {
          dispatch(setGranted(true))
          return true;
        }
      } catch (e) {
        console.log(e)
      }
      await new Promise((r) => setTimeout(r, 1800));
    }

    return false;
  }
)

async function pollAccess(grant: string) {
  const res = await api.post('/subscribe/await-provisioning', {
    grant,
  });

  if (res.error) {
    return false;
  }

  return !!res.provisioned;
}
