import React, { useEffect, useRef, useCallback } from "react";
import { useSelector } from 'react-redux';
import { Loader } from "./loader";
import InnerHTML from './DangerouslySetHtmlContent';
import { selectLoading, selectRenderedPage } from "store/specialpage/select";
import { useSpecialPageSlice } from "store/specialpage/reducer";


interface OrigMeta {
  title?: string
  description?: string
}

export interface SpecialPageProps {
  page: string
  prefix?: string
  updateCallback?: (el: HTMLDivElement) => void
  setMetadata?: boolean
}


export function SpecialPage({ updateCallback: parentUpdateCallback, prefix, page, setMetadata }: SpecialPageProps) {

  const setPageMetadata = setMetadata !== false;

  const { specialPageLoad } = useSpecialPageSlice();

  const loading = useSelector(selectLoading)
  const renderedPage = useSelector(selectRenderedPage)

  const html = renderedPage?.__html || "";
  const metadata = renderedPage?.metadata;

  const elRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    // load page (should load the 404 page if it doesn't exist)
    if (page && page.length > 0) {
      specialPageLoad({ pageName: page, prefix })
    }
  }, [ page, prefix, specialPageLoad ]);

  useEffect(() => {
    // re-init TRS components to pick up any components that may be in the content
    if (typeof (window as any).TRSRender === 'function') {
      (window as any).TRSRender();
    }
  }, [ html ]);

  useEffect(() => {
    if (!setPageMetadata) return;
    
    // set the page metadata to the special page metadata
    if (metadata?.title) {
      document.title = metadata.title;
    }
    let metaDesc = document.querySelector<HTMLMetaElement>('meta[name="description"]')
    if (metadata?.description && metaDesc) {
      metaDesc.content = metadata.description || "";
    }

  }, [ setPageMetadata, metadata ]);

  const updateCallback = useCallback((node: HTMLDivElement) => {
    if (parentUpdateCallback && elRef.current) {
      parentUpdateCallback(elRef.current)
    }
  }, [ parentUpdateCallback, elRef ]);

  return (
    <div className="TRS-widget TRS-SpecialPage" style={{position: 'relative'}} ref={elRef}>
      <Loader loading={loading} exclass="dark" />
      <InnerHTML html={html} updateCallback={updateCallback}></InnerHTML>
    </div>
  );
}


export default SpecialPage;
