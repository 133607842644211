
export interface SubscribePriceProps {
  unitAmount: number | null
}


export function SubscribePrice({ unitAmount }: SubscribePriceProps) {
  return (
    <>
      {unitAmount ? "$"+(unitAmount / 100).toFixed(2) : ""}
    </>
  )
}
