import React, { useCallback, useEffect, useState } from "react";
import { ShowListResults } from "./ShowListResults"
import { ShowListForm } from "./ShowListForm";
import { usePopState } from "../../hooks/usePopState";
import { useShowListSlice } from "../../store/showList/reducer";
import { selectShowListDone, selectShowListLoading, selectShowListShows } from "../../store/showList/select";
import { useSelector } from "react-redux";


export interface ShowListProps {
  category?: string
  tag?: string
  staticurl: string
}


export function ShowList({ staticurl, category, tag }: ShowListProps) {

  const { startSearch } = useShowListSlice();

  const shows = useSelector(selectShowListShows);
  const done = useSelector(selectShowListDone);
  const loading = useSelector(selectShowListLoading);

  const [ initialized, setInitialized ] = useState<boolean>(false);
  const [ eptype, setEptype ] = useState<string>('all');
  const [ sort, setSort ] = useState<string>('aired');
  const [ asc, setAsc ] = useState<boolean>(false);

  const setStateFromURL = () => {
    // gather state from URL
    const sp = new URLSearchParams(window.location.search);
    setEptype(sp.get('eptype') || "all")
    setSort(sp.get('sort') || "aired")
    setAsc(!!sp.get('asc'))
  }


  const refreshSearch = useCallback(() => {
    // start a new search
    startSearch({
      category, 
      tag, 
      sort, 
      eptype, 
      asc, 
      offset: 0
    });
  }, [ startSearch, category, tag, sort, eptype, asc ]);
  
  const loadNextPage = () => {
    // start a new search
    if (!shows.length || done) return;
    startSearch({
      category, 
      tag, 
      sort, 
      eptype, 
      asc, 
      offset: shows.length
    });
  }

  useEffect(() => {
    // on load, setup our state from the initial URL
    setStateFromURL();
    setInitialized(true);
  }, []) 

  useEffect(() => {
    if (!initialized) return;

    // re-start our search when our search params change
    updateURLQuery(sort, eptype, asc);
    refreshSearch();
  }, [ initialized, sort, eptype, asc, refreshSearch ]) 


  usePopState((loc: Location) => {
    // if we pop state to an older url, set our state from the current url
    setStateFromURL();
  });


  // handle form field changes
  const changeSort = (val: string) => setSort(val)
  const changeEptype = (val: string) => setEptype(val)
  const changeAsc = (val: boolean) => setAsc(val)


  return (
    <div className="TRS-widget TRS-showList" style={{position: 'relative'}}>

      <ShowListForm 
        eptype={eptype} sort={sort} asc={asc}
        onChangeEptype={changeEptype}
        onChangeSort={changeSort}
        onChangeAsc={changeAsc}
        ></ShowListForm>

      <ShowListResults 
        staticURL={staticurl}
        category={category} tag={tag} 
        eptype={eptype} sort={sort} asc={asc}
        shows={shows}
        loading={loading}
        onTriggerNext={loadNextPage}
        ></ShowListResults>

    </div>
  );
}


function updateURLQuery(sort: string, eptype: string, asc: boolean) {
  const sp = new URLSearchParams(window.location.search);
  sp.set('sort', sort)
  sp.set('eptype', eptype)
  if (asc) {
    sp.set('asc', '1')
  } else if (sp.get('asc')?.length) {
    sp.delete('asc')
  }

  let newurl = new URL(window.location.href)
  newurl.search = sp.toString();
  window.history.pushState({}, "", newurl.toString());
}


export default ShowList