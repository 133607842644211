import React, { useEffect, useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { Loader } from "../loader";
import { PortalListPortal } from "./PortalListPortal";


export interface PortalListResultsProps {
  staticURL: string
  category?: string
  tag?: string
  //eptype: string
  sort: string
  asc: boolean
  loading: boolean
  portals: any[]
  onTriggerNext: () => void
}


export function PortalListResults({ staticURL, loading, portals, onTriggerNext }: PortalListResultsProps) {

  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible && !loading) {
      onTriggerNext();
    }
  }, [ isVisible, loading, onTriggerNext ]);


  return (
    <div className="TRS-widget TRS-portalListResults" style={{position: 'relative'}}>
            
      <div className="row">
        {portals.map((portal, i) => 
        <div className={`col-md-6 mb-5 fadeInUp animated`} style={{ animationDelay: i % 5 > 0 ? `${(i % 5) * 200}ms` : '0'}}>
          <PortalListPortal staticURL={staticURL} portal={portal} fadein={true} index={i} />
        </div>        
        )}
      </div>

      <div ref={ref} style={{ position: 'relative', minHeight: '5rem' }}>
        <Loader loading={loading} exclass="dark" />
      </div>

    </div>
  );
}

