import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { get as apiGet } from '../../api'
import InnerHTML from '../DangerouslySetHtmlContent';
import { Loader } from "components/loader";

export interface PortalFlowProps {}


export function PortalFlow({}: PortalFlowProps) {

  const elRef = useRef<HTMLDivElement>(null);
  const [ pageName, setPageName ] = useState<string>("");
  const [ pageData, setPageData ] = useState<any>();


  // function to fetch page data from the website api
  const getPage = useCallback(async (upath: string) => {
    // fetch the portal page from the website api

    // check for a draft key
    const draft_key = new URLSearchParams(window.location.search).get('draft_key');

    try {
      const res = await apiGet(`/portal/${upath}` + (draft_key ? `?draft_key=${draft_key}` : '')) 
      if (res && res.metadata) {
        setPageData(res);
      } else {
        setPageData(undefined);
      }
    } catch (e) { 
      console.error(e) 
    }
  }, []);


  // set initial page from url
  useEffect(() => {
    // get the portal page name from the current URL
    const ppath = window.location.pathname.slice(1).split(/\//g);
    const pageName = ppath.slice(1).join('/');
    setPageName(pageName)
  }, []);


  // fetch new HTML whenever the pageName changes
  useEffect(() => {
    if (pageName) {
      getPage(pageName);
    } else {
      setPageData(undefined);
    }
  }, [ pageName ])


  // re-fetch the the page content if the user logs in or out
  useEffect(() => {

    const handleLogin = () => {
      getPage(pageName);
    }
    const handleLogout = () => {
      getPage(pageName);
    }

    window.addEventListener('TRS.user.login', handleLogin)
    window.addEventListener('TRS.user.logout', handleLogout)

    return () => {
      window.removeEventListener('TRS.user.login', handleLogin)
      window.removeEventListener('TRS.user.logout', handleLogout)
    }
  }, [ getPage, pageName ])


  // re-initialize TRS components whenever the HTML is updated
  useEffect(() => {
    // re-init TRS components to pick up any components that may be in the content
    if (typeof (window as any).TRSRender === 'function') {
      (window as any).TRSRender();
    }
  }, [ pageData ]);


  // navigate to other portal pages without reloading the entire page
  useEffect(() => {
    const onLocationChange = () => {
      let ppath = window.location.pathname.slice(1).split(/\//g);
      const pagename = ppath.slice(1).join('/')
      if (ppath[0] === 'portal') {
        setPageName(pagename);
      }
    }

    window.addEventListener('popstate', onLocationChange)

    return () => {
      window.removeEventListener('popstate', onLocationChange)
    }
  }, [ setPageName ])



  // whenever the HTML changes, run this to keep non-rnavigation
  useEffect(() => {
    const node = elRef.current;
    if (!node) return;

    node.querySelectorAll<HTMLAnchorElement>(`a[href]`).forEach((a: HTMLAnchorElement) => {
      a.addEventListener('click', (e: MouseEvent) => {

        const url = new URL(a.href);

        if (url.pathname && /^\/portal\//.test(url.pathname)) {

          // only "watch" pages get loaded without relaoding the page
          // other pages just link normally
          if (! /^\/portal\/watch\//.test(url.pathname)) {
            return true;
          }

          // a link that goes to a portal page
          e.preventDefault();
          let draft_key = new URLSearchParams(window.location.search).get('draft_key');
          let u = new URL(a.href, window.location.origin);
          if (draft_key) {
            u.searchParams.append('draft_key', draft_key);
          }
          const pp = u.pathname.split('/');
          setPageName(pp.slice(2).join('/'));
          window.history.pushState({}, "", a.href);
          window.scrollTo(0,0);
          return false;

        } else if (url.pathname && !a.target) {
          // a link that goes to a non-portal page
          e.preventDefault();

          window.dispatchEvent(new CustomEvent('PortalNavigateAwayWarning', {
            bubbles: true, 
            detail: { 
              url,
            } 
          }))

          return false;
        }
      })
    })
  }, [ pageData, setPageName ]);


  return (
    <>
      {pageData && pageData.__html ? (
        <div ref={elRef}>
          <InnerHTML html={pageData.__html}></InnerHTML>
        </div>
      ) : (
        <div className="my-7" style={{ minHeight: "60vh" }}>
          <Loader loading={true} exclass="dark middle">
            Loading Portal...
          </Loader>
        </div>
      )}
    </>
  )

}


export default PortalFlow;
