import * as api from '../../api'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageLoaded, setLoading, setRenderedPage } from './reducer'
import { PageMetaData } from './types';


type SpecialPageLoadArgs = {
  pageName: string
  prefix?: string
}

export const specialPageLoad = createAsyncThunk(
  'specialpage/load',
  async function({ pageName, prefix }: SpecialPageLoadArgs, { dispatch }) {
    try {

      prefix = prefix || '/specialpage';

      dispatch(setLoading(true))
      dispatch(setRenderedPage({ metadata: {} as PageMetaData, __html: '' }))

      const res = await api.get(prefix+'/'+pageName);

      dispatch(pageLoaded(res))
      
    } catch(e) {
      dispatch(setLoading(false))
      throw new Error(e as any);
    }
  }
)
