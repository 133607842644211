import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { RatingState } from "./types";

const slice = (state: RootState) => state.ratings;

export const selectCommentSuccess = createSelector(slice, (state: RatingState) => state.comment_success);
export const selectRatingFormLoading = createSelector(slice, (state: RatingState) => state.form_loading);
export const selectRatingSuccess = createSelector(slice, (state: RatingState) => state.rating_success);
export const selectRatingScore = createSelector(slice, (state: RatingState) => state.score);
