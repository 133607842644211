import { md5 } from "./md5";


export function imageResizeURL(filePath: string, source: string, fit: string, size: string, q: number, key: string) {

  const image_transformer_secret = 'U7S2Bylc6EdjsCPipgHkHNmToSz5qOyjF9D';

  // get the output format from the path extension
  //let fmt = (filePath.match(/\.([^\.\/]+)$/)?.[1] || "").toLowerCase();
  //fmt = fmt === 'jpeg' ? 'jpg' : fmt;

  let whs = size.split('x').map(i => parseInt(i));
  let wh = [];
  if (whs[0] && whs[0] > 0) {
    wh.push(`w=${whs[0]}`)
  }
  if (whs[1] && whs[1] > 0) {
    wh.push(`h=${whs[1]}`)
  }

  let qs = '';
  if (q && q > 0) {
    qs = `,q=${q}`
  }

  let keystr = '';
  if (key && key.length) {
    keystr = `,key=${key}`
  }

  let spec = `src=${source}${keystr}${qs};op=resize,fit=${fit},${wh.join(',')}`;

  // see if this is a video poster url
  let pfx = '';
  if (/^\/video-posters\//.test(filePath)) {
    pfx = 'video-posters/'
    filePath = filePath.replace(/^\/video-posters/, '')
  }


  let hash = computeImageTransformerHash(`${filePath}|${spec}|${image_transformer_secret}`);

  return `/transform/${pfx}${spec}/${hash}${filePath}`;
}


function computeImageTransformerHash(input: string) {
  return md5(input)
    .slice(0, 10) // we only use the first 10 chars for validation
}
