import { pollForSubscribedAccess, subscribeInit, subscribeStripeInit } from './actions';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StripePriceWithProduct, SubscribeState, UserData } from './types';


const initialState: SubscribeState = {
  subscribeFlowLoading: false,
  accessPollCount: 0,
};


export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState: initialState,
  reducers: {

    setLoading: (state: SubscribeState, { payload }: PayloadAction<boolean>) => {
      state.subscribeFlowLoading = payload
    },

    setPublicKey: (state: SubscribeState, { payload }: PayloadAction<string>) => {
      state.public_key = payload
    },

    setSubscriptionId: (state: SubscribeState, { payload }: PayloadAction<string>) => {
      state.subscriptionId = payload
    },

    setClientSecret: (state: SubscribeState, { payload }: PayloadAction<string>) => {
      state.clientSecret = payload
    },

    setPrice: (state: SubscribeState, { payload }: PayloadAction<StripePriceWithProduct>) => {
      state.price = payload
    },

    setUserData: (state: SubscribeState, { payload }: PayloadAction<UserData>) => {
      state.userData = payload
    },

    setInitError: (state: SubscribeState, { payload }: PayloadAction<SubscribeState["initError"]>) => {
      state.initError = payload
    },

    incrementAccessPollCount: (state: SubscribeState, { payload }: PayloadAction<void>) => {
      state.accessPollCount = state.accessPollCount + 1
    },

    setGranted: (state: SubscribeState, { payload }: PayloadAction<boolean>) => {
      state.granted = payload
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setLoading, setPublicKey, setSubscriptionId, setClientSecret, setPrice, setUserData, setInitError, incrementAccessPollCount, setGranted } = subscribeSlice.actions;

export const useSubscribeSlice = () => createSliceWrapperHook(subscribeSlice, "subscribe", {
  subscribeInit,
  subscribeStripeInit,
  pollForSubscribedAccess,
});


export default subscribeSlice.reducer;