import { PollsState } from './types'
import { pollChartLoadLib, pollVote } from './actions';
import { AppRegistrationData, PollRegData, PollRegDataPoll } from '../app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSliceWrapperHook } from 'hooks/createSliceWrapperHook';
import { useCallback } from 'react';


const initialState: PollsState = {
  chart_lib_loaded: null,
  form_loading: false,
  vote_success: false,
  poll: undefined,
};


export const pollsSlice = createSlice({
  name: "polls",
  initialState: initialState,
  reducers: {

    setChartLibLoaded: (state: PollsState, action: PayloadAction<boolean>) => {
      state.chart_lib_loaded = action.payload
    },

    setFormLoading: (state: PollsState, action: PayloadAction<boolean>) => {
      state.form_loading = action.payload
    },

    setVoteSuccess: (state: PollsState, action: PayloadAction<boolean>) => {
      state.vote_success = action.payload
    },

    setPoll: (state: PollsState, action: PayloadAction<PollRegDataPoll | undefined>) => {
      state.poll = action.payload
    },

    setRegisters: (state: PollsState, action: PayloadAction<AppRegistrationData[]>) => {
      // if we reset the registers, our current poll data may be out of date,
      // find the correct poll in the register data and set it here
      const regdata = action.payload;

      let newpoll: PollRegDataPoll | undefined;
      if (state.poll) {
        const oldpoll = state.poll;
        let pollreg = regdata.find(r => r.type === 'poll' && r.post_id === oldpoll.id);
        
        if (pollreg) {
          newpoll = (pollreg.data as PollRegData).poll;
        } else {
          for (let r of regdata) {
            if (r.type === 'poll' && (r.post_id === 'featured' || r.post_id === 'latest')) {
              newpoll = r.data.polls.find(rr => rr.id === oldpoll.id);
              if (newpoll) break;
            }
          }
        }
      }

      state.poll = newpoll || state.poll
    },
    

  },
  extraReducers: (builder) => {

  }
});


export const { setChartLibLoaded, setFormLoading, setPoll, setRegisters, setVoteSuccess } = pollsSlice.actions;

export const usePollsSlice = () => createSliceWrapperHook(pollsSlice, "polls", {
  pollChartLoadLib,
  pollVote,
});


export default pollsSlice.reducer;
