import * as api from '../../api'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoadingSubscriptions, setStripeSubscriptions } from './reducer'


export const fetchSubscriptions = createAsyncThunk(
  'subscribe/init',
  async function(_, { dispatch }) {
    try {

      dispatch(setLoadingSubscriptions(true))

      const res = await api.get('/subscribe/scripts/list');

      if (res.error) {
        return;
      }

      dispatch(setStripeSubscriptions(res.subscriptions))
      dispatch(setLoadingSubscriptions(false))
      
    } catch(e: any) {
     
      dispatch(setLoadingSubscriptions(false))
      throw new Error(e as any);
    }
  }
)

