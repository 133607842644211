import React from "react";
import config from '../config';


export interface LoginButtonFacebookProps {
  handleClick: (e: React.MouseEvent) => void
}

export function LoginButtonFacebook({ handleClick }: LoginButtonFacebookProps) {

  return (
    <button type="button" className="btn btn-outline-primary btn-login btn-login-facebook" onClick={handleClick}>
      <span className="icon">
        <svg className="icon icon-facebook"><use href="#icon-facebook"></use></svg>
      </span>
      Sign In with Facebook
    </button>
  );
}


export default LoginButtonFacebook;
