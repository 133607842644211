import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { startSearch } from "./actions";
import { PortalListState } from "./types";
import { createSliceWrapperHook } from "../../hooks/createSliceWrapperHook";
import { useCallback } from "react";


const initialState: PortalListState = {
  loading: false,
  portals: [],
  done: false
};


export const portalListSlice = createSlice({
  name: "portallist",
  initialState: initialState,
  reducers: {

    setFormLoading: (state: PortalListState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    portalsFetched: (state: PortalListState, { payload }: PayloadAction<any>) => {
      let done = state.done;
      let shows = state.portals;
      if (payload.offset > 0) {
        done = !payload.results.length;
        shows = shows.concat(payload.results)
      } else {
        done = false;
        shows = payload.results;
      }
      state.loading = false;
      state.done = done;
      state.portals = shows;
    },

    portalsClear: (state: PortalListState, action: PayloadAction<void>) => {
      state.done = false;
      state.portals = []
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setFormLoading, portalsFetched, portalsClear } = portalListSlice.actions;

export const usePortalListSlice = () => createSliceWrapperHook(portalListSlice, "portallist", {
  startSearch,
});


export default portalListSlice.reducer;