import React from "react";
import { QType } from "./EntityList";


export interface EntityListFormProps {
  qType: QType
  sort: string
  asc: boolean
  onChangeQType: (val: QType) => void
  onChangeSort: (val: string) => void
  onChangeAsc: (val: boolean) => void
}


export function EntityListForm({ qType, sort, asc, onChangeQType, onChangeSort, onChangeAsc }: EntityListFormProps) {


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //alert(`Submitting Form`);
  } 

  const changeSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeSort(e.target.value);
  }
  const changeQType = (val: QType) => onChangeQType(val);
  const changeAsc = (val: boolean) => onChangeAsc(val);


  return (
    <form className="mt-4" id="SortForm_" onSubmit={handleSubmit}>
      <div className="row justify-content-start align-items-center">
        <div className="col-auto">
          <div className="form-group form-inline">
            <label className="mr-2">Sort</label>
            <select name="sort" className="form-control" 
              value={sort} onChange={changeSort}>
              <option value="aired">Original Air Date</option>
              <option value="published">Publish Date</option>
              <option value="duration">Show Duration</option>
              <option value="rating">Show Rating</option>
            </select>
          </div>
        </div>

        <div className="col-auto">
          <div className="form-group btn-group btn-group-sm radio-btn-group">
            
            <label className={`btn ${!asc ? 'btn-secondary' : 'btn-outline-secondary'}`} htmlFor="SortForm_dir_desc_">
              <input type="radio" name="dir" value="desc" 
                id="SortForm_dir_desc_"
                checked={!asc} onChange={() => changeAsc(false)} 
                />
              <svg className="icon icon-sort-amount-desc" style={{ width: '1.25em', height: '1.25em'}}>
                <use xlinkHref="#icon-sort-amount-desc"></use>
              </svg>
              <span className="sr-only">Descending</span>
            </label>
            
            <label className={`btn ${asc ? 'btn-secondary' : 'btn-outline-secondary'}`} htmlFor="SortForm_dir_asc_">
              <input type="radio" name="dir" value="asc" 
                id="SortForm_dir_asc_" 
                checked={asc} onChange={() => changeAsc(true)} 
                />
              <svg className="icon icon-sort-amount-desc" style={{ width: '1.25em', height: '1.25em'}}>
                <use xlinkHref="#icon-sort-amount-asc"></use>
              </svg>
              <span className="sr-only">Ascending</span>
            </label>

          </div>
        </div>

        <div className="col-auto d-none d-sm-block">
          <div className="form-group btn-group btn-group-sm radio-btn-group">

            <label className={`btn ${(qType === "all" || !qType) ? 'btn-secondary' : 'btn-outline-secondary'}`} 
              htmlFor="SortForm_qtype_all_">
              <input type="radio" name="qtype" value="all" 
                id="SortForm_qtype_all_" 
                checked={(qType === "all" || !qType)} onChange={() => changeQType("all")} 
                />
              <span>Everything</span>
            </label>

            <label className={`btn ${qType === "portal" ? 'btn-secondary' : 'btn-outline-secondary'}`}
              htmlFor="SortForm_qtype_portal_">
              <input type="radio" name="qtype" value="portal" 
                id="SortForm_qtype_portal_" 
                checked={qType === "portal"} onChange={() => changeQType("portal")} 
                />
              <span>Portals</span>
            </label>

            <label className={`btn ${qType === "podcast" ? 'btn-secondary' : 'btn-outline-secondary'}`}
              htmlFor="SortForm_qtype_podcast_">
              <input type="radio" name="qtype" value="podcast" 
                id="SortForm_qtype_podcast_" 
                checked={qType === "podcast"} onChange={() => changeQType("podcast")} 
                />
              <span>Podcasts</span>
            </label>

            <label className={`btn ${qType === "showfull" ? 'btn-secondary' : 'btn-outline-secondary'}`}
              htmlFor="SortForm_qtype_showfull_">
              <input type="radio" name="eptype" value="showfull" 
                id="SortForm_qtype_showfull_" 
                checked={qType === "showfull"} onChange={() => changeQType("showfull")} 
                />
              <span>Full Episodes</span>
            </label>

            <label className={`btn ${qType === "showclip" ? 'btn-secondary' : 'btn-outline-secondary'}`} 
              htmlFor="SortForm_qtype_showclip_">
              <input type="radio" name="eptype" value="showclip" 
                id="SortForm_qtype_showclip_" 
                checked={qType === "showclip"} onChange={() => changeQType("showclip")} 
                />
              <span>Clips</span>
            </label>
            
          </div>
        </div>

      </div>


      <div className="d-sm-none" style={{ maxWidth: "350px" }}>
        <div className="form-group">
          <label className="mr-2">Resource Type:</label>
          <select name="qtype" className="form-control" value={qType}
            onChange={(e) => changeQType(e.target.value as QType)}>
            <option value="">Everything</option>
            <option value="portal">Portals</option>
            <option value="podcast">Podcasts</option>
            <option value="showfull">Full Episodes</option>
            <option value="showclip">Clips</option>
          </select>
        </div>
      </div>


    </form>
  )
}