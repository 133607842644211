import { useSelector } from 'react-redux';
import { UserState } from "../../store/user/types";
import { selectUser } from "store/user/select";
import SigninFlow from '../signInFlow';
import SubscribeForm from './subscribeForm';
import { Loader } from '../loader';
import React, { useEffect, useMemo, useState } from 'react';
import { useSubscribeSlice } from 'store/subscribe/reducer';
import { selectSubscribFlowLoading } from 'store/subscribe/select';
import { selectAppIsInitialized } from 'store/app/select';
import { PaymentResult } from './PaymentResult'

export interface SubscribeFlowProps {
}


export function SubscribeFlow({}: SubscribeFlowProps) {

  const appIsInitialized = useSelector(selectAppIsInitialized);
  const user = useSelector(selectUser);

  const [ paymentClientSecret, setPaymentClientSecret ] = useState<string | null | false>(false);

  // check for a return_url payment param
  // to see if we need to display a payment result here
  useEffect(() => {
    const sp = new URLSearchParams(window.location.search)
    const pcs = sp.get("payment_intent_client_secret");
    setPaymentClientSecret(pcs);
  }, [])

  
  const granted_access: boolean = useMemo(() => {
    if (!user) return false;

    const sp = new URLSearchParams(window.location.search)
    const for_grant = sp.get("grant");
    const idx = user.subscriptions?.findIndex(s => s.grants === for_grant && s.status === 'active');
    return !!(idx && idx > -1);
  }, [ user ])


  return (
    <div className="TRS-widget TRS-subscribeFlow" style={{position: 'relative'}}>

      <Loader loading={!appIsInitialized || paymentClientSecret === false}>
        <div className="text-center">Loading, please wait...</div>
      </Loader>

      {user && user.id ? (

        <div>
          {paymentClientSecret ? (
            <PaymentResult paymentClientSecret={paymentClientSecret} />
          ) : (
            <>
            {granted_access ? (
              <>
                <h2>You already have a subscription for this access</h2>
                <p><a href="/members">Click here for the members page</a></p>
              </>
            ) : (
              <SubscribeForm />
            )}
            </>
          )}
        </div>

      ) : (
        <div className="container-fluid-md">
          <SigninFlow />
        </div>
      )}

    </div>
  );
}


export default SubscribeFlow;