import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useSubscribeSlice } from 'store/subscribe/reducer';
import { selectGranted, selectPublicKey, selectSubscribeInitError } from 'store/subscribe/select';
import { selectUser } from 'store/user/select';
import { SubscribeInitError } from './subscribeForm';
import { PaymentIntent, Stripe, loadStripe } from '@stripe/stripe-js';
import { Loader } from 'components/loader';


export interface PaymentResultProps {
  paymentClientSecret: string
}

export function PaymentResult({ paymentClientSecret }: PaymentResultProps) {

  const { subscribeStripeInit, setInitError } = useSubscribeSlice();

  const user = useSelector(selectUser);
  const stripePublicKey = useSelector(selectPublicKey);
  const subscribeInitError = useSelector(selectSubscribeInitError);

  const [ stripe, setStripe ] = useState<Stripe>();
  const [ initialized, setInitialized ] = useState<boolean>(false);
  const [ resultMessage, setResultMessage ] = useState<string>();
  const [ paymentIntent, setPaymentIntent ] = useState<PaymentIntent>();


  useEffect(() => {
    if (!(user && user.id)) return;

    // on inital loading of the user, fetch the requested price, using the URLSearchParams
    const s = new URLSearchParams(window.location.search);
    const price_id = s.get('p') || "";

    if (!price_id) {
      setInitError("price_not_found")
      return;
    }

    // fetch the stripe public key from our API
    subscribeStripeInit()

  }, [ user ])
 

  // load the stripe client as soon as we have the stripePublicKey
  useEffect(() => {
    if (stripePublicKey && typeof stripe === "undefined") {
      async function initStripe() {
        if (stripePublicKey) {
          const st = await loadStripe(stripePublicKey);
          if (st) {
            setStripe(st);
          }
        }
      }
      initStripe();
    }
  }, [ stripePublicKey, stripe, setStripe ]);


  // wait until we have a paymentClientSecret and stripe client
  // and fetch the data about our payment attempt
  useEffect(() => {
    if (!(paymentClientSecret && stripe)) return;

    stripe.retrievePaymentIntent(paymentClientSecret).then(({ paymentIntent }) => {
      setPaymentIntent(paymentIntent);
    });

  }, [ paymentClientSecret, stripe ]);


  // wait for all required initial data to be set, then set the initialized var
  useEffect(() => {
    if (!initialized && stripePublicKey && paymentClientSecret && stripe && paymentIntent) {
      setInitialized(true)
    }
  }, [ initialized, setInitialized, stripePublicKey, paymentClientSecret, stripe, paymentIntent ]);

  
  // if we have an invalid productId, then show an error for that
  if (subscribeInitError) {
    return (
      <SubscribeInitError subscribeInitError={subscribeInitError} />
    )
  }

 
  return (
    <div className="container-fluid-md TRS-widget TRS-subscribeFlow" style={{position: 'relative'}}>

      <Loader loading={!initialized} exclass="dark middle">
        <div>Loading, please wait...</div>
      </Loader>

      {paymentIntent && (
        <>
          {paymentIntent.status === 'succeeded' ? (
            <>
              <h2>Thank You for signing up!</h2>
              <p>
                Your payment in the amount of ${(paymentIntent.amount / 100).toFixed(2)} was successful. 
              </p>
              <p>
                A payment receipt has been sent to {paymentIntent.receipt_email}.
              </p>

              <div style={{ position: "relative" }}>
                <ProvisionAwaiter />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}

    </div>
  )
}


function ProvisionAwaiter() {

  const { pollForSubscribedAccess } = useSubscribeSlice();
  const provisioned = useSelector(selectGranted);


  useEffect(() => {
    pollForSubscribedAccess({ grant: "ruddle_plus" });
  }, [])


  if (provisioned) {
    return (
      <div>
        <h4 className="mt-4 mb-4">You now have access to the members area</h4>
        <p>
          <a href="/members">Click here to go to the members page</a>
        </p>
      </div>
    )
  } else {
    return (
      <div style={{ minHeight: "200px" }}>
        <Loader loading={true} exclass="dark middle">
          <div className="text-center">Please wait while your access is provisioned</div>
        </Loader>
      </div>
    )
  }
}