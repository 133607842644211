import React from "react";


export interface ShowListFormProps {
  eptype: string
  sort: string
  asc: boolean
  onChangeEptype: (val: string) => void
  onChangeSort: (val: string) => void
  onChangeAsc: (val: boolean) => void
}


export function ShowListForm({ eptype, sort, asc, onChangeEptype, onChangeSort, onChangeAsc }: ShowListFormProps) {


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //alert(`Submitting Form`);
  } 

  const changeSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeSort(e.target.value);
  }
  const changeEptype = (val: string) => onChangeEptype(val);
  const changeAsc = (val: boolean) => onChangeAsc(val);


  return (
    <form className="mt-4" id="SortForm_" onSubmit={handleSubmit}>
      <div className="row justify-content-start align-items-center">
        <div className="col-auto">
          <div className="form-group form-inline">
            <label className="mr-2">Sort</label>
            <select name="sort" className="form-control" 
              value={sort} onChange={changeSort}>
              <option value="aired">Original Air Date</option>
              <option value="published">Publish Date</option>
              <option value="duration">Show Duration</option>
              <option value="rating">Show Rating</option>
            </select>
          </div>
        </div>

        <div className="col-auto">
          <div className="form-group btn-group btn-group-sm radio-btn-group">
            
            <label className={`btn ${!asc ? 'btn-secondary' : 'btn-outline-secondary'}`} htmlFor="SortForm_dir_desc_">
              <input type="radio" name="dir" value="desc" 
                id="SortForm_dir_desc_"
                checked={!asc} onChange={() => changeAsc(false)} 
                />
              <svg className="icon icon-sort-amount-desc" style={{ width: '1.25em', height: '1.25em'}}>
                <use xlinkHref="#icon-sort-amount-desc"></use>
              </svg>
              <span className="sr-only">Descending</span>
            </label>
            
            <label className={`btn ${asc ? 'btn-secondary' : 'btn-outline-secondary'}`} htmlFor="SortForm_dir_asc_">
              <input type="radio" name="dir" value="asc" 
                id="SortForm_dir_asc_" 
                checked={asc} onChange={() => changeAsc(true)} 
                />
              <svg className="icon icon-sort-amount-desc" style={{ width: '1.25em', height: '1.25em'}}>
                <use xlinkHref="#icon-sort-amount-asc"></use>
              </svg>
              <span className="sr-only">Ascending</span>
            </label>

          </div>
        </div>

        <div className="col-auto">
          <div className="form-group btn-group btn-group-sm radio-btn-group">

            <label className={`btn ${eptype === "all" || !eptype ? 'btn-secondary' : 'btn-outline-secondary'}`} 
              htmlFor="SortForm_eptype_all_">
              <input type="radio" name="eptype" value="all" 
                id="SortForm_eptype_all_" 
                checked={eptype === "all" || !eptype} onChange={() => changeEptype("all")} 
                />
              <span>All Shows</span>
            </label>
            
            <label className={`btn ${eptype === "full" ? 'btn-secondary' : 'btn-outline-secondary'}`}
              htmlFor="SortForm_eptype_full_">
              <input type="radio" name="eptype" value="full" 
                id="SortForm_eptype_full_" 
                checked={eptype === "full"} onChange={() => changeEptype("full")} 
                />
              <span>Full Episodes</span>
            </label>

            <label className={`btn ${eptype === "clip" ? 'btn-secondary' : 'btn-outline-secondary'}`} 
              htmlFor="SortForm_eptype_clip_">
              <input type="radio" name="eptype" value="clip" 
                id="SortForm_eptype_clip_" 
                checked={eptype === "clip"} onChange={() => changeEptype("clip")} 
                />
              <span>Clips</span>
            </label>
            
          </div>
        </div>

      </div>
    </form>
  )
}