import React, { useMemo } from "react";
import { useSelector } from 'react-redux';
import SpecialPage from "../SpecialPage";
import { selectLoaded, selectUser } from "../../store/user/select";
import MembersPage from "./MembersPage";


export interface MembersFlowProps {
}


export function MembersFlow({}: MembersFlowProps) {

  const user = useSelector(selectUser);
  const userLoaded = useSelector(selectLoaded);

  const has_ruddle_plus: boolean = useMemo(() => {
    if (!user) return false;
    const idx = user.subscriptions?.findIndex(s => s.grants === 'ruddle_plus' && s.status === 'active');
    return !!(typeof idx !== 'undefined' && idx > -1);
  }, [ user ])


  return (
    <div className="TRS-widget TRS-membersFlow" style={{position: 'relative'}}>
    {userLoaded 
      ? user?.id && has_ruddle_plus
        ?
          <MembersPage />
        :
          <SpecialPage page="_members_noaccess" />
      : ''}
    </div>
  );
}


export default MembersFlow;
