import { Slice as RTKSlice } from "@reduxjs/toolkit"; 
import { RootState, useAppDispatch } from "../store"; 
import { useSelector } from "react-redux"; 
import { useMemo } from "react";


export type StorePropertyNames = keyof RootState; 
 
export type StoreData<StorePropertyName extends StorePropertyNames> = { 
  [K in StorePropertyName]: RootState[K]; 
}; 

export type WrappedSliceMethods<Slice extends RTKSlice> = { 
  [ActionName in keyof Slice["actions"]]: ( 
    ...args: Parameters<Slice["actions"][ActionName]> 
  ) => void; 
};

export type ThunkSlice = {
  [k: string]: any
}

export type WrappedThunkActions<S extends ThunkSlice> = { 
  [ActionName in keyof S]: ( 
    ...args: Parameters<S[ActionName]> 
  ) => void; 
}; 


export const createSliceWrapperHook = < 
    Slice extends RTKSlice, 
    Name extends StorePropertyNames,
    AddedThunkActions extends ThunkSlice
  >( 
    slice: Slice, 
    storePropertyName: Name,
    thunkActions: AddedThunkActions
  ): 
    StoreData<Name> & 
    WrappedSliceMethods<Slice> & 
    WrappedThunkActions<AddedThunkActions> =>
{

  const dispatch = useAppDispatch();
  const dispatchThunk = useAppDispatch();
  
  const { actions } = slice;
 
  const dateSelector = useSelector<RootState, RootState[Name]>( 
    (state) => state[storePropertyName] 
  ); 
  const getDataOutput = () => ({ 
      [storePropertyName]: dateSelector
  } as StoreData<Name>);


  const getMethods = () => Object.keys(actions).reduce((acc, k) => { 
    const key = k as keyof typeof actions; 
    type Method = Slice["actions"][typeof key]; 
  
    if (actions[key]) { 
      return { 
        ...acc, 
        [key]: (...input: Parameters<Method>) => { 
          dispatch(actions[key](...input as [payload: any]));
        } 
      }; 
    } 
    return acc; 
  }, {} as WrappedSliceMethods<Slice>)    


  const getThunks = () => Object.keys(thunkActions).reduce((acc, k) => { 
  
    const key = k as keyof typeof thunkActions; 
    type Method = typeof thunkActions[typeof key];
  
    if (thunkActions[key]) { 
      return { 
        ...acc, 
        [key]: (...input: Parameters<Method>) => { 
          dispatchThunk(thunkActions[key].apply(this, input));
        } 
      }; 
    } 

    return acc; 
  }, {} as WrappedThunkActions<AddedThunkActions>)

  const methods = useMemo(getMethods, [  ]);
  const thunks = useMemo(getThunks, [  ]);
  const dataOutput = useMemo(getDataOutput, [ storePropertyName ]);

  const val = useMemo(() => {  
    return { ...methods, ...thunks, ...dataOutput }
  }, [ methods, thunks, dataOutput ]);
  
  return val;
}

