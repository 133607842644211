import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../user/types';
import * as api from '../../api'
import { AppRegistration, AppRegistrationData } from './types';
import { setIsInitialized, setRegisterData } from './reducer';
import { setUser } from 'store/user/reducer';


export const mainFetch = createAsyncThunk(
  'app/mainfetch',
  async function(registers: AppRegistration[], { dispatch }) {
    let user: User | undefined;
    let regs: AppRegistrationData[] | undefined;
    try {
      const res = await api.post('/app/load', { registers });
      user = res && res.user && res.user.id ? (res.user as User) : undefined;
      regs = (res && res.registers && Array.isArray(res.registers) ? res.registers : []) as AppRegistrationData[];
    } catch (e) {
      // this should be a failed http response, probably a 401
    } finally {
      dispatch(setRegisterData(regs || []))
      dispatch(setUser({ user: user || undefined, loaded: true }))
      dispatch(setIsInitialized(true))
    }
  }
)

