import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Loader } from "./loader";
import { SigninFlowChildProps } from "../store/user/types";
import classNames from 'classnames';
import LoginButtonFacebook from './loginButton-facebook';
//import LoginButtonGoogle from './loginButton-google';
import { selectFormLoading, selectHasLoginError, selectUser } from "store/user/select";
import { selectAppRegistrations } from "store/app/select";
import { useUserSlice } from "store/user/reducer";


interface LoginFormProps extends SigninFlowChildProps {
  setFBMessage: (show: boolean) => void 
}


export function LoginForm({ changeStep, setFBMessage }: LoginFormProps) {

  const { userLogin } = useUserSlice();

  const hasLoginError = useSelector(selectHasLoginError);
  const formLoading = useSelector(selectFormLoading);
  const app_registrations = useSelector(selectAppRegistrations);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validForm, setValidForm] = useState((null as boolean | null));

  function updateUsername(e: React.ChangeEvent<HTMLInputElement>) {
    setUsername(e.target.value || '');
  }

  function updatePassword(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value || '');
  }

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // check for a completed form
    const f = (e.target as HTMLFormElement);

    if (!f.checkValidity()) {
      setValidForm(false);
      return false;
    }

    setValidForm(true);

    userLogin({ 
      username, 
      password, 
      registers: app_registrations
    })
  }

  return (
    <div className="TRS-widget loginForm" style={{position: 'relative'}}>

      <div className="h1">Sign In</div>

      <div className="form-group text-center">
        Don't have an account? <a href="/" onClick={(e) => { e.preventDefault(); return changeStep('signup') }}>Sign up</a>
        <div>-- or --</div>
        <div className="login login-facebook">
          <LoginButtonFacebook handleClick={(e) => { e.preventDefault(); setFBMessage(true); return changeStep('forgotpass') }} />
        </div>
      </div>

      <div className={classNames('alert alert-danger', {'d-none': !hasLoginError})}>
        <h4>Login Failed</h4>
        <p>
          Please double-check your username and password.<br />
          <a href="/" onClick={(e) => { e.preventDefault(); return changeStep('forgotpass') }}>Forgot password?</a>
        </p>
      </div>

      <form className={classNames('login-form', {'was-validated': validForm !== null})} 
        action="" method="POST" noValidate onSubmit={submitForm}>
        
        <div className={classNames('form-group', {'was-validated': username.length >= 4})}>
          <label>Email Address</label>
          <input type="email" className="form-control" name="username" placeholder="email" required autoComplete="username" 
            minLength={5}
            maxLength={255}
            value={username}
            onChange={updateUsername} />
          <div className="invalid-feedback">
            Please enter a valid email address
          </div>                    
        </div>

        <div className={classNames('form-group', {'was-validated': password.length >= 4})}>
          <label>Password</label>
          <input type="password" className="form-control" name="password" placeholder="password" required autoComplete="current-password" 
            minLength={4}
            maxLength={255}
            value={password}
            onChange={updatePassword} />
          <div className="invalid-feedback">
            Please enter a valid password
          </div>        
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Sign In
          </button>
        </div>

      </form>


      <div className="form-group text-center">
        Forgot password? <a href="/" onClick={(e) => { e.preventDefault(); return changeStep('forgotpass') }}>Click here</a>
      </div>

      <Loader loading={formLoading} exclass="dark" />

    </div>
  );
}


export default LoginForm;
