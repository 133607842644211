import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { AppState } from "./types";


const slice = (state: RootState) => state.app;

export const selectAppRegistrations = createSelector(slice, (state: AppState) => state.registrations);

export const selectAppRegistrationData = createSelector(slice, (state: AppState) => state.registration_data);

export const selectAppIsInitialized = createSelector(slice, (state: AppState) => state.isInitialized);
