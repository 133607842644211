import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { UserState } from "./types";


const slice = (state: RootState) => state.user

export const selectUser = createSelector(slice, (state: UserState) => state.user);

export const selectForgotPassResult = createSelector(slice, (state: UserState) => state.forgotpass_result);

export const selectFormLoading = createSelector(slice, (state: UserState) => state.form_loading);

export const selectLoaded = createSelector(slice, (state: UserState) => state.loaded);

export const selectHasLoginError = createSelector(slice, (state: UserState) => state.login_error);

export const selectLoginErrors = createSelector(slice, (state: UserState) => state.login_errors);

export const selectFlowStep = createSelector(slice, (state: UserState) => state.flow_step);

export const selectSignupStep = createSelector(slice, (state: UserState) => state.signup_step);

export const selectLoadedUser = createSelector(slice, (state: UserState) => {
  return state.loaded ? state.user : false;
});
