import React, { useEffect, useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { Loader } from "../loader";
import { PortalListEntity } from "./PortalListEntity";
import { ShowListShow } from "../../components/showList/ShowListShow";
import { EntityPodcast } from "./EntityPodcast";
import { QType } from "./EntityList";


export interface PortalListResultsProps {
  staticURL: string
  category?: string
  tag?: string
  qType?: QType
  sort: string
  asc: boolean
  loading: boolean
  entityType: "show" | "portal" | "podcast"
  entities: any[]
  onTriggerNext?: () => void
}


export function EntityListResults({ staticURL, loading, entities, entityType, onTriggerNext }: PortalListResultsProps) {

  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible && !loading && typeof onTriggerNext === 'function') {
      onTriggerNext();
    }
  }, [ isVisible, loading, onTriggerNext ]);


  return (
    <div className="TRS-widget TRS-EntityListResults" style={{position: 'relative' }}>

      <div className="container-fluid-xl">
        <div className="d-flex justify-content-center mx-auto">
        
          <div className={entityType === 'portal' ? "preview_slider1 max2col" : "preview_slider1"}>
            
            <div className="sliderboard">
        
              {entityType === "show" && 
                entities.map((ent, i) => 
                <div className="video-box-outer">
                  <ShowListShow staticURL={staticURL} show={ent} fadein={true} index={i} />
                </div>
                )
              }

              {entityType === "podcast" && 
                entities.map((ent, i) => 
                <div className="video-box-outer">
                  <EntityPodcast staticURL={staticURL} podcast={ent} fadein={true} index={i} />
                </div>
                )
              }

              {entityType === "portal" && 
                entities.map((ent, i) => 
                <div className="video-box-outer">
                  <PortalListEntity staticURL={staticURL} portal={ent} fadein={true} index={i} />
                </div>
                )
              }

            </div>
          </div>
          
        
        </div>
      </div>

      <div ref={ref} style={{ position: 'relative', minHeight: '5rem', display: loading ? 'block' : 'none' }}>
        <Loader loading={loading} exclass="dark" />
      </div>

    </div>
  );
}

