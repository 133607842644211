import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { Loader } from "../loader";
import classNames from 'classnames';
import { AccountPage } from '../../store/account/types'
import AccountEditForm from "./AccountEditForm";
import { useAccountSlice } from "../../store/account/reducer";
import { selectLoading, selectPage } from "../../store/account/select";
import { SubscriptionManager } from "./SubscriptionManager";
import { SubscriptionCancel } from "./SubscriptionCancel";
import { SubscriptionMethod } from "./SubscriptionMethod";


export interface AccountFlowProps {}

export function AccountFlow() {

  const { setPage } = useAccountSlice()

  const page = useSelector(selectPage);
  const loading = useSelector(selectLoading);

  function changePage(page: AccountPage) {
    const u = new URL(window.location.href);
    if (u.search.length > 0) {
      u.search = "";
      window.history.replaceState({}, "", u);
    }
    window.location.hash = page;
  }

  useEffect(() => {
    const onHashChange = () => {
      const hash: AccountPage = (window.location.hash.substring(1) as AccountPage) || "edit"
      setPage(hash)
    };

    window.addEventListener("hashchange", onHashChange);
    onHashChange();

    return () => window.removeEventListener("hashchange", onHashChange);
  }, [])


  return (
    <div className="TRS-widget AccountFlow" style={{position: 'relative'}}>

      <div className="container-fluid-xl">
        <div className="row justify-content-center align-items-center py-3 mb-3" style={{ borderBottom: "1px solid #ccc" }}>
          <div className="col-auto mb-2">
            <button onClick={() => changePage("edit")}
              className={classNames('btn btn-sm', page === 'edit' ? 'btn-secondary' : 'btn-outline-secondary' )} 
              >
              Edit Account
            </button>
          </div>

          <div className="col-auto mb-2">
            <button onClick={() => changePage("subscriptions")}
              className={classNames('btn btn-sm', page === 'subscriptions' ? 'btn-secondary' : 'btn-outline-secondary' )} 
              >
              Manage Subscriptions
            </button>
          </div>
        </div>
      </div>

      {page === 'edit' && (
        <div className={classNames('page', { 'active': page === 'edit' })}>
          <AccountEditForm changePage={changePage} />
        </div>
      )} 
      
      {page === 'subscriptions' && (
        <div className={classNames('page', { 'active': page === 'subscriptions' })}>
          <SubscriptionManager />
        </div>
      )}

      {page === 'subscriptionCancel' && (
        <div className={classNames('page', { 'active': page === 'subscriptionCancel' })}>
          <SubscriptionCancel />
        </div>
      )}

      {page === 'subscriptionMethod' && (
        <div className={classNames('page', { 'active': page === 'subscriptionMethod' })}>
          <SubscriptionMethod />
        </div>
      )}

      <Loader loading={loading} exclass="dark" />

    </div>
  );
}


export default AccountFlow;
