import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { startSearch } from "./actions";
import { PostListState } from "./types";
import { createSliceWrapperHook } from "hooks/createSliceWrapperHook";
import { useCallback } from "react";


const initialState: PostListState = {
  loading: false,
  shows: [],
  done: false
};


export const postListSlice = createSlice({
  name: "postlist",
  initialState: initialState,
  reducers: {

    setFormLoading: (state: PostListState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    showsFetched: (state: PostListState, { payload }: PayloadAction<any>) => {
      let done = state.done;
      let shows = state.shows;
      if (payload.offset > 0) {
        done = !payload.results.length;
        shows = shows.concat(payload.results)
      } else {
        done = false;
        shows = payload.results;
      }
      state.loading = false;
      state.done = done;
      state.shows = shows;
    },

    showsClear: (state: PostListState, action: PayloadAction<void>) => {
      state.done = false;
      state.shows = []
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setFormLoading, showsFetched, showsClear } = postListSlice.actions;

export const usePostListSlice = () => createSliceWrapperHook(postListSlice, "postlist", {
  startSearch,
});


export default postListSlice.reducer;