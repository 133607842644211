import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { startSearch, startSearchOnType } from "./actions";
import { EntityListState } from "./types";
import { createSliceWrapperHook } from "hooks/createSliceWrapperHook";


const initialState: EntityListState = {
  loading: false,
  listLoaded: false,
  portals: [],
  podcasts: [],
  showsfull: [],
  showsclip: [],
  portals_total: 0,
  podcasts_total: 0,
  showsfull_total: 0,
  showsclip_total: 0,
};


export const entityListSlice = createSlice({
  name: "entitylist",
  initialState: initialState,
  reducers: {

    setFormLoading: (state: EntityListState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    setListLoaded: (state: EntityListState, action: PayloadAction<boolean>) => {
      state.listLoaded = action.payload
    },

    entitiesFetched: (state: EntityListState, { payload }: PayloadAction<any>) => {

      if (!payload.results) {
        return state;
      }

      const qtype = payload.qtype;

      const ret = { ...state };
      ret.loading = false;
      ret.listLoaded = true;

      if (!qtype || (qtype && qtype === "portal")) {
        ret.portals_total = payload.results.portals.total;
        ret.portals = ret.portals.concat(payload.results.portals.rows);
      } 

      if (!qtype || (qtype && qtype === "podcast")) {
        ret.podcasts_total = payload.results.podcasts.total;
        ret.podcasts = ret.podcasts.concat(payload.results.podcasts.rows);
      } 

      if (!qtype || (qtype && qtype === "showfull")) {
        ret.showsfull_total = payload.results.showsfull.total;
        ret.showsfull = ret.showsfull.concat(payload.results.showsfull.rows);
      } 

      if (!qtype || (qtype && qtype === "showclip")) {
        ret.showsclip_total = payload.results.showsclip.total;
        ret.showsclip = ret.showsclip.concat(payload.results.showsclip.rows);
      }

      return ret;
    },

    entitiesClear: (state: EntityListState, action: PayloadAction<void>) => {
      state.listLoaded = false;
      state.portals_total = 0;
      state.podcasts_total = 0;
      state.showsfull_total = 0;
      state.showsclip_total = 0;
      state.portals = []
      state.podcasts = []
      state.showsfull = []
      state.showsclip = []
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setFormLoading, setListLoaded, entitiesFetched, entitiesClear } = entityListSlice.actions;

export const useEntityListSlice = () => createSliceWrapperHook(entityListSlice, "entitylist", {
  startSearch,
  startSearchOnType,
});


export default entityListSlice.reducer;