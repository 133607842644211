import React, { useEffect, useState } from 'react';
import { selectSubscription, selectLoading, selectPaymentUpdateSuccess, selectPaymentUpdateErrors } from '../../store/account/select';
import { useSelector } from 'react-redux';
import { setPaymentUpdateErrors, useAccountSlice } from '../../store/account/reducer';
import { StripeSubscription } from '../../store/subscriptions/types';
import { Loader } from '../loader';
import { SubscribeInitError } from '../subscribe/subscribeForm';
import { PaymentUpdate } from './PaymentUpdate';
import { selectUser } from 'store/user/select';
import { selectPublicKey, selectSubscribeInitError } from 'store/subscribe/select';
import { PaymentIntent, Stripe, loadStripe } from '@stripe/stripe-js';


export interface SubscriptionMethodProps {
}

export function SubscriptionMethod({ }: SubscriptionMethodProps) {

  const subsc = useSelector(selectSubscription);
  const { setPaymentUpdateSuccess } = useAccountSlice();

  const [ setupIntentId, setSetupIntentId ] = useState<string | null | false>(false);
  const [ redirectStatus, setRedirectStatus ] = useState<string | null | false>(false);
  const [ subscription, setSubscription ] = useState<StripeSubscription | undefined | false>(false);
  const [ subscriptionId, setSubscriptionId ] = useState<string | null>();

  useEffect(() => {
    const sp = new URLSearchParams(window.location.search)
    const sid = sp.get("subscription");
    const pcs = sp.get("setup_intent");
    const res = sp.get("redirect_status");
    setSubscriptionId(sid);
    setSetupIntentId(pcs);
    setRedirectStatus(res);

    // reset the success bool, in case this is a re-run
    setPaymentUpdateSuccess(false);
    setPaymentUpdateErrors(undefined);
  }, [])

  useEffect(() => {
    setSubscription(subsc);
  }, [ subsc, setSubscription ])


  if (subscriptionId && setupIntentId && redirectStatus === "succeeded") {
    return (
      <SubscriptionMethodResult subscriptionId={subscriptionId} setupIntentId={setupIntentId} />
    )
  } else if (setupIntentId) {
    return (
      <div style={{ textAlign: "center" }}>Error {subscriptionId}, {setupIntentId}, {redirectStatus}</div>
    )
  } else if (subscription) {
    return (
      <div className="container-fluid-xl">
        <PaymentUpdate subscriptionId={subscription.id} />
      </div>
    )
  }
}



interface SubscriptionMethodResultProps {
  subscriptionId: string
  setupIntentId: string
}

function SubscriptionMethodResult({ subscriptionId, setupIntentId }: SubscriptionMethodResultProps) {

  const { subscribeUpdateSetup, setPaymentUpdateSuccess } = useAccountSlice();

  const user = useSelector(selectUser);
  const paymentUpdateSuccess = useSelector(selectPaymentUpdateSuccess);
  const paymentUpdateErrors = useSelector(selectPaymentUpdateErrors);

  useEffect(() => {
    if (!(user && user.id)) return;
    subscribeUpdateSetup({ subscriptionId, setupIntentId })
  }, [ user ])
  
  return (
    <div className="container-fluid-md TRS-widget TRS-subscribeFlow" style={{position: 'relative'}}>

      <Loader loading={!paymentUpdateSuccess} exclass="dark middle">
        <div>Loading, please wait...</div>
      </Loader>

      {paymentUpdateSuccess && (
        <>
          <h2>Payment Method updated</h2>
          <p>
            Your next invoice will be charged to your new payment method.
          </p>
          <p>
            <a href="/my-account#subscriptions" className="btn btn-primary">Subscription Manager</a>
          </p>
        </>
      )}

      {paymentUpdateErrors && (
        <>
          <h3>Error: Payment Method was not updated</h3>
          
          {paymentUpdateErrors.map(e => (
          <div className="alert alert-danger p-4">
            <p>
              {e}
            </p>
          </div>
          ))}

          <p>
            <a href="/my-account#subscriptions" className="btn btn-primary">Subscription Manager</a>
          </p>
        </>
      )}


    </div>
  )

}


