import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/select";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { selectClientSecret, selectPrice, selectPublicKey, selectSubscribeInitError, selectUserData } from "../../store/subscribe/select";
import { SubscribePrice } from "../subscribe/SubscribePrice";
import { SubscribeInterval } from "../subscribe/SubscribeInterval";
import { PaymentUpdateForm } from "./PaymentUpdateForm";
import { Loader } from "../loader";
import { useSubscribeSlice } from "../../store/subscribe/reducer";
import { SubscribeState } from "../../store/subscribe/types";
import { StripeSubscription } from "../../store/subscriptions/types";
import { useAccountSlice } from "../../store/account/reducer";
import { selectPaymentUpdateSuccess, selectSubscription } from "store/account/select";


export interface PaymentUpdateProps {
  subscriptionId: string
}


export function PaymentUpdate({ subscriptionId }: PaymentUpdateProps) {

  const { subscribeInit, setInitError } = useSubscribeSlice();
  const { subscribeUpdateInit }  = useAccountSlice();

  const user = useSelector(selectUser);
  const stripePublicKey = useSelector(selectPublicKey);
  const stripeClientSecret = useSelector(selectClientSecret);
  const stripePrice = useSelector(selectPrice);
  const subscription = useSelector(selectSubscription);
  const userData = useSelector(selectUserData);
  const subscribeInitError = useSelector(selectSubscribeInitError);

  const [ stripe, setStripe ] = useState<Stripe>();
  const [ initialized, setInitialized ] = useState<boolean>(false);


  useEffect(() => {
    if (!(user && user.id)) return;

    if (subscription && subscriptionId) {
      // fetch the product info from our API
      subscribeUpdateInit({ subscriptionId: subscriptionId })
    }
 
  }, [ user, subscriptionId ])


  // load the stripe client as soon as we have the stripePublicKey
  useEffect(() => {
    if (stripePublicKey && typeof stripe === "undefined") {
      async function initStripe() {
        if (stripePublicKey) {
          const st = await loadStripe(stripePublicKey);
          if (st) {
            setStripe(st);
          }
        }
      }
      initStripe();
    }
  }, [ stripePublicKey, stripe, setStripe ]);


  // wait for all required initial data to be set, then set the initialized var
  useEffect(() => {
    if (!initialized && stripePublicKey && stripeClientSecret && stripe && userData) {
      setInitialized(true)
    }
  }, [ initialized, setInitialized, stripePublicKey, stripeClientSecret, stripe, userData ]);


  // if we have an invalid productId, then show an error for that
  if (subscribeInitError) {
    return (
      <PaymentUpdateInitError subscribeInitError={subscribeInitError} />
    )
  }
  

  // we have to wait for all the data to be initialized before we can 
  // use any of the stripe elements
  if (!initialized || !stripe || !stripeClientSecret) {
    return (
      <div>
        <Loader loading={true} exclass="dark">
          <div className="text-center">Loading, please wait...</div>
        </Loader>
      </div>
    )
  }

  const testMode = stripePublicKey?.substring(0, 8) === 'pk_test_';
  const cssFontSrc = "https://fonts.googleapis.com/css?family=DM+Sans:400,400i,500,500i,700,700i|Quicksand:300,400,500,600,700&display=swap"

  const amount = subscription && stripePrice ? (stripePrice as any).amount : stripePrice?.unit_amount;
  const recurring_interval = subscription && stripePrice ? (stripePrice as any).interval : stripePrice?.recurring?.interval;
  const recurring_interval_count = subscription && stripePrice ? (stripePrice as any).interval_count : stripePrice?.recurring?.interval_count;
  
  return (
    <div className="row">
      
      <div className="col-lg-6 col-xl-5 mb-6">

        {(!subscription) && (
        <h4>
          <span className="font-weight-normal">Subscribe to</span><br />
          <span>{stripePrice?.product.name}</span>
        </h4>
        )}
        {subscription && (
        <h4>
          <span className="font-weight-normal">Update Payment Method for</span><br />
          <span>{stripePrice?.product.name}</span>
        </h4>
        )}

        <div className="d-flex align-items-center">
          <span className="display-1 mb-0 mt-0 mr-1 font-weight-bold font2">
            <SubscribePrice unitAmount={amount || null}/>
          </span>
          <SubscribeInterval stacked={true}
            interval={recurring_interval || null}
            intervalCount={recurring_interval_count || null}
            />
        </div>

        {stripePrice?.product.metadata?.terms_url && (
          <div className="mt-2" style={{ fontSize: "0.825em" }}>
            <a href={stripePrice?.product.metadata?.terms_url} target="_blank">Click here for Terms and Conditions</a>
          </div>
        )}

        {testMode && (
          <div className="alert alert-danger my-5">
            <h5 className="mt-0 mb-1">Test Mode</h5>
            <p style={{ fontSize: "0.825em" }}>This payment form is running in test mode, no actual charges will occurr.</p>
          </div>
        )}

      </div>

      <div className="col-lg-6 col-xl-7 mb-6">
        <Elements stripe={stripe} options={{
          clientSecret: stripeClientSecret,
          loader: "always",
          fonts: [{ cssSrc: cssFontSrc }],
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#00f',
              colorBackground: '#ffffff',
              colorText: '#000',
              colorDanger: '#ff0000',
              fontFamily: '"DM Sans", sans-serif',
              fontSizeBase: '16px',
              spacingUnit: '4px',
              borderRadius: '0.75em',
            }
          }
          }}>
          <PaymentUpdateForm 
            clientSecret={stripeClientSecret}
            terms_url={stripePrice?.product.metadata?.terms_url} />
        </Elements>

      </div>

    </div>
  )

}



export function PaymentUpdateInitError({ subscribeInitError }: { subscribeInitError: SubscribeState["initError"] }) {

  if (subscribeInitError === "price_not_found") {
    return (
      <div className="container-fluid-md TRS-widget TRS-subscribeFlow" style={{position: 'relative'}}>
        <h2>Invalid Subscription Product</h2>
        <p>
          The link you followed to get here may be incorrect or out-of-date.
          Please go back to the subscription options page to select a current subscription plan.
        </p>
        <p><a href="javascript:void(0)" onClick={() => window.history.back()}>return to product selection</a></p>
      </div>
    );  
  }

  return (
    <div className="container-fluid-md TRS-widget TRS-subscribeFlow" style={{position: 'relative'}}>
      <h2>Payment Setup Error</h2>
      <p>
        We encountered an unknown error initializing the payment system. 
        Please reload the page and try again in a few minutes.
      </p>
      <p><a href="javascript:void(0)" onClick={() => window.history.back()}>return to product selection</a></p>
    </div>
  );

}



export default PaymentUpdate;
