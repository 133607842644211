import React, { useCallback, useEffect, useState } from "react";
import SpecialPage from "../SpecialPage";


export interface MembersPageProps {}


export function MembersPage() {

  return (
    <SpecialPage page="_members_page" />
  );

}

export default MembersPage;
