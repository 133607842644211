import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api';
import { setFormLoading, showsClear, showsFetched } from './reducer';


type StartSeatchArgs = {
  category?: string
  tag?: string
  sort: string
  eptype: string
  asc: boolean
  offset: number
}

export const startSearch = createAsyncThunk(
  'showlist/startsearch',
  async function({ category, tag, sort, eptype, asc, offset }: StartSeatchArgs, { dispatch }) {
    try {

      dispatch(setFormLoading(true));

      if (offset === 0) {
        dispatch(showsClear());
      }

      const sp = new URLSearchParams();
      if (category) sp.set('category', category);
      if (tag) sp.set('tag', tag);
      if (sort) sp.set('sort', sort);
      if (eptype) sp.set('eptype', eptype);
      if (asc) sp.set('asc', '1');
      if (offset > 0) sp.set('offset', offset.toString());

      const res = await api.get('/showlist/?'+sp.toString());

      dispatch(showsFetched(res));
      
    } catch(e) {
      dispatch(setFormLoading(false));
      throw new Error(e as any);
    }
  }
)
