import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from 'react-redux';
import { Loader } from "../loader";
import { useAppSlice } from "../../store/app/reducer";
import { useRatingsSlice } from "../../store/ratings/reducer";
import classNames from 'classnames';
import { RatingCommentFormStar } from "./RatingCommentFormStar";
import { selectUser } from "store/user/select";
import * as api from '../../api'
import { RatingCommentPopupMode } from "./RatingCommentPopup";


export interface RatingCommentFormProps {
  postId: number | null;
  postTitle?: string;
  postRating?: number;
  commentType?: "comment" | "question"
  postThumbnail?: string
  isPopup?: boolean
  popupActionMode?: RatingCommentPopupMode | null
  setPopupMode?: (mode: RatingCommentPopupMode) => void
  noRating?: boolean
}


export function RatingCommentForm({ postId, isPopup, setPopupMode, commentType: initCommentType, postThumbnail, postTitle, postRating, popupActionMode, noRating }: RatingCommentFormProps) {

  const formRef = useRef<HTMLFormElement>(null);
  const { register } = useAppSlice();
  const { ratingSet, setRatingSuccess, setCommentSuccess } = useRatingsSlice();
  // this is the current post rating average
  const rating = postRating ? parseFloat(postRating as any) : 0;

  const user = useSelector(selectUser);

  const [ ratingMode, setRatingMode ] = useState<null | "select" | "loading" | "result" >(null);
  const [ ratingSelectIdx, setratingSelectIdx ] = useState<number>(0);
  const [ myRating, setMyRating ] = useState<number | null>(null);
  const [ ratingResultMessage, setRatingResultMessage ] = useState<string>("");

  const [ commentType, setCommentType ] = useState<"comment" | "question">(initCommentType || "comment");
  const [ commentMode, setCommentMode ] = useState<null | "loading" | "result" >(null);


  const startSigninFlow = useCallback(() => {
    if (isPopup) {
      if (setPopupMode) {
        setPopupMode("signin")
      }
    } else {
      window.dispatchEvent(new Event('TRS_RatingCommentLoginPopup_open'))
    }
  }, [ isPopup, setPopupMode ])


  const apiSetRating = useCallback(async(rating?: number | null) => {
    rating = rating || myRating;
    if (!rating) return;

    const res = await api.post(`/rating/${postId ? postId : ''}`, {
      score: rating
    });

    // show success or error message
    if (rating < 4) {
      setRatingResultMessage(`Please send us a comment and tell us how we can do better!`)
    } else {
      setRatingResultMessage(`Thank You!`)
    }

    // cancel loading ops and show the result
    setRatingMode("result");

  }, [ postId, myRating, setRatingResultMessage, setRatingMode ]);


  const apiSetComment = useCallback(async () => {
    const f = formRef.current;
    if (!f) return;

    const ctextarea = f.elements.namedItem('comment');
    const cradios = f.elements.namedItem('ctype');
    if (!(ctextarea && cradios)) return;

    const comment = ((ctextarea as HTMLTextAreaElement).value || "").trim();
    const ctype = (cradios as RadioNodeList).value;

    const res = await api.post(`/rating/${postId ? postId : ''}`, {
      comment: comment,
      comment_type: ctype
    });

    // reset the form
    f.reset();

    // cancel loading ops and show the result
    setCommentMode("result");

  }, [ formRef.current, postId, setCommentMode ]);


  useEffect(() => {

    function cancel() {
      setMyRating(null)
      setRatingMode(null)
      setCommentMode(null)
    }

    function close() {
      setMyRating(null)
      setRatingMode(null)
      setCommentMode(null)
    }

    function success() {

      // if we were waiting to send an API call, then send it now
      if (myRating) {
        apiSetRating()
      }
      if (commentMode === "loading") {
        apiSetComment()
      }
      if (isPopup && setPopupMode) {
        setPopupMode(popupActionMode || "rate");
      }
    }
    
    window.addEventListener('TRS.user.login', success)
    window.addEventListener('TRS_RatingCommentPopup_cancel', cancel)
    window.addEventListener('TRS_RatingCommentPopup_close', close)

    return () => {
      window.removeEventListener('TRS.user.login', success)
      window.removeEventListener('TRS_RatingCommentPopup_cancel', cancel)
      window.removeEventListener('TRS_RatingCommentPopup_close', close)
    }
  }, [ myRating, setMyRating, setRatingMode, apiSetRating, commentMode, setCommentMode, apiSetComment, isPopup, setPopupMode, popupActionMode ])


  useEffect(() => {
    if (popupActionMode === "comment" || popupActionMode === "question") {
      setCommentType(popupActionMode)
    } else if (popupActionMode === "rate") {
      setRatingMode("select");
    }
  }, [ popupActionMode, setCommentType, setRatingMode ]);


  function sendRatingOrLogin(rating: number) {

    // set the rating state to loading
    setRatingMode("loading");

    // save my rating selection in state
    setMyRating(rating);

    // are we logged in?
    if (user && user.id) {
      // send the rating immediately without a popup
      apiSetRating(rating)
    } else {
      // open the popup so we can login orcreate an account
      startSigninFlow();
    }
  }

  function closePopup() {
    window.dispatchEvent(new Event('TRS_RatingCommentPopup_close'));
  }

  function sendCommentOrLogin() {
    // set the rating state to loading
    setCommentMode("loading");

    // are we logged in?
    if (user && user.id) {
      // send the rating immediately without a popup
      apiSetComment()
    } else {
      // open the popup so we can login orcreate an account
      startSigninFlow();
    }
  }

  function submitComment(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    sendCommentOrLogin();
  }


  return (
  <>
  <div className="TRS-widget" style={{ position: 'relative' }}>
    <div className="RatingsComments2">
      <div 
        className={classNames("rating form-group", { select: ratingMode === 'select' || ratingMode === "loading", 'd-none': !!noRating  })} 
        style={{ position: 'relative' }}>
        
        <div className={classNames("starsarea")} onMouseLeave={(e) => { 
          (ratingMode === "select") && setratingSelectIdx(0) 
          }}>
          <span className="stars">
            {[1,2,3,4,5].map(i => (
              <RatingCommentFormStar idx={i} 
                rating={rating} 
                selectMode={ratingMode === "select" || ratingMode === "loading"} 
                selectingIdx={ratingSelectIdx}
                onSelect={(e, rating) => sendRatingOrLogin(rating)} 
                onSelectHover={() => setratingSelectIdx(i)} />
            ))}
          </span>

          <span className="capt">
            {ratingMode === "select" ? <>
              Select your rating above...
            </> : <>
              {rating > 0 ? <>
                {rating.toFixed(1)} star rating
              </> : <>
                No ratings yet
              </>}
            </>} 
          </span>
        </div>

        <div className="rateit">
          <button type="button" className="btn btn-primary btn-sm" onClick={() => setRatingMode("select")}>
            Rate It! {ratingMode}
          </button>
        </div>

        <Loader loading={ratingMode === "loading"} exclass="dark" />
      </div>

      {ratingMode === "result" && 
      <div className={classNames('form-group rating-result alert alert-info')}>
        <h5 className="text-center">
          You rated {myRating} star{(myRating && myRating > 1) && <>s</> }{(myRating && myRating > 3) ? <>!</> : <>.</>}
        </h5>
        <div className="text-center">
          {ratingResultMessage}

          {isPopup && 
          <div className="text-center">
            <button type="button" className="cancel-button btn btn-outline-secondary btn-sm mb-2" onClick={closePopup}>
              Close
            </button>
          </div>
          }            
        </div>
      </div>
      }

      <div className={classNames("comment-result alert alert-info", {'d-none': commentMode !== "result"})}>
        <div className={classNames("comment-result-comment", {'d-none': commentType !== "comment"})}>
          <h5 className="text-center">Thank you for your comment</h5>
          <p>
            Note: Comments are moderated and may take some time before they appear on the website.
          </p>
          {isPopup && 
          <div className="text-center">
            <button type="button" className="cancel-button btn btn-outline-secondary btn-sm mb-2" onClick={closePopup}>
              Close
            </button>
          </div>
          } 
        </div>
        <div className={classNames("comment-result-question", {'d-none': commentType !== "question"})}>
          <h5 className="text-center">Thank you for your question</h5>
          <p>
            Note: Submitted questions have been forwarded to The Ruddle Show team for future follow up either directly or within an upcoming show. 
            Please be on the lookout! 
            In the meantime, you may enjoy our Just-In-Time portal at endoruddle.com/jit for additional content, 
              including FREE downloadable PDFs and educational videos.
          </p>
          {isPopup && 
          <div className="text-center">
            <button type="button" className="cancel-button btn btn-outline-secondary btn-sm mb-2" onClick={closePopup}>
              Close
            </button>
          </div>
          }            
        </div>                
      </div>

      <form className="commentform" ref={formRef} onSubmit={submitComment}>
        <div className="form-group questiontype">
          <label>
            <input type="radio" name="ctype" value="comment" 
              onClick={() => setCommentType("comment")}
              checked={commentType === "comment"} />{" "}
            Comment
          </label>{" "}
          <label>
            <input type="radio" name="ctype" value="question" 
              onClick={() => setCommentType("question")}
              checked={commentType === "question"} />{" "}
            Ask a Question
          </label>
        </div>

        <div className="form-group">
          <textarea className="form-control" rows={5} name="comment" required
            placeholder={commentType === "question" ? "Ask a Question..." : "Add a Comment..."}></textarea>
        </div>

        <div className="form-group submitrow" style={{ display: 'flex', justifyContent: isPopup ? "space-between" : "flex-end" }}>
          {isPopup && 
          <button type="button" className="cancel-button btn btn-outline-secondary btn-sm mb-2" onClick={closePopup}>
            Cancel
          </button>
          }
          <button type="submit" className="add-comment-button btn btn-primary btn-sm mb-2">
            Submit {commentType === "question" ? "Question" : "Comment"}
          </button>
        </div>
      </form>


    </div>

    <Loader loading={commentMode === "loading"} exclass="dark" />

  </div>
  </>
  );
}


export default RatingCommentForm;

