import { combineReducers } from 'redux'
import appReducer from './app/reducer'
import userReducer from './user/reducer'
import accountReducer from './account/reducer'
import ratingsReducer from './ratings/reducer'
import pollsReducer from './polls/reducer'
import searchReducer from './search/reducer'
import specialPageReducer from './specialpage/reducer'
import postListReducer from './postList/reducer'
import showListReducer from './showList/reducer'
import entityListReducer from './entityList/reducer'
import subscribeReducer from './subscribe/reducer'
import subscriptionsReducer from './subscriptions/reducer'
import podcastlistReducer from './podcastList/reducer'
import portallistReducer from './portalList/reducer'


const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  account: accountReducer,
  ratings: ratingsReducer,
  polls: pollsReducer,
  search: searchReducer,
  specialpage: specialPageReducer,
  postlist: postListReducer,
  showlist: showListReducer,
  entitylist: entityListReducer,
  podcastlist: podcastlistReducer,
  portallist: portallistReducer,
  subscribe: subscribeReducer,
  subscriptions: subscriptionsReducer,
})


export default rootReducer
