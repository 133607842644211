import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { UserForm, LoginError, SigninFlowChildProps, SigninFlowStep } from "../store/user/types";
import classNames from 'classnames';
import LoginButtonFacebook from './loginButton-facebook';
import { useUserSlice } from "store/user/reducer";
import { selectHasLoginError, selectLoginErrors, selectSignupStep } from "store/user/select";


interface SignupFormProps extends SigninFlowChildProps {}

export function SignupForm({ changeStep }: SignupFormProps) {


  const { userSignup } = useUserSlice();

  const signup_step = useSelector(selectSignupStep);
  const hasLoginError = useSelector(selectHasLoginError);
  const login_errors = useSelector(selectLoginErrors);


  const submitFormBase = function(userForm: UserForm) {
    userSignup(userForm);
    return true;
  }

  return (
    <div className="signupForm">

      <div className={classNames('signupFormStep', { 'active': signup_step === 1 })}>
        <SignupFormBase onSubmit={submitFormBase} error={hasLoginError} errors={login_errors} changeFlowStep={changeStep} />
      </div>

    </div>
  );
}


interface SignupFormBaseProps {
  onSubmit: (u: UserForm) => boolean;
  error?: boolean;
  errors: LoginError[];
  changeFlowStep: (step: SigninFlowStep) => void;
}

export function SignupFormBase(props: SignupFormBaseProps) {

  const [myuid] = useState( (window as any).TRS.wuid() );
  const [submitted, setSubmitted ] = useState(false);
  const [formError, setFormError ] = useState('');
  const [email, setEmail] = useState('');
  const [first_name, setFName] = useState('');
  const [last_name, setLName] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirm, setPasswordConfirm] = useState('');
  const [subscribe1, setSubscribe1] = useState(true);

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const f = (e.target as HTMLFormElement);
    
    setSubmitted(true);

    if (!f.checkValidity()) {
      setFormError('Please check the form for errors');
      return;
    }

    // check min length of password
    if (password.length < 8) {
      setFormError("Password must be at least 8 characters long (longer is recommended)");
    }

    // check password matching
    if (password !== password_confirm) {
      setFormError("Password and confirmation don't match");
      return;
    }

    setFormError('');

    // submit the form
    props.onSubmit({
      email,
      first_name,
      last_name,
      password,
      subscribe1,
    });
  }


  return (
    <div className="signupForm">
      
      <div className="h1">Sign Up</div>

      <div className="form-group text-center">
        Already have an account? <a href="/" onClick={(e) => { e.preventDefault(); return props.changeFlowStep('login') }}>Sign in</a>
      </div>

      <div className={classNames('alert alert-danger', {'d-none': !formError})}>
        <h4>Incomplete Signup</h4>
        <p>{formError}</p>
      </div>
      <div className={classNames('alert alert-danger', {'d-none': !props.error})}>
        <h4>Signup Error</h4>
        {props.errors.map(e => {

          if (e.type === 'email_exists_endoruddle') {
            return (
            <div>
              <p>
                You have an existing Endoruddle account that uses your email address <b>"{email}"</b>. For your convenience, you can login to The Ruddle Show using your Endoruddle sign-on credentials.
              </p>
              <p>
                If you forgot your Endoruddle password, please <a href="https://www.endoruddle.com/account/password_reset" rel="noopener noreferrer" target="_blank">click HERE</a> to reset it.
                <br />
                Or <a href="/" onClick={(e) => { e.preventDefault(); return props.changeFlowStep('forgotpass') }}>click HERE</a> to have an automatic login link sent to your email.
              </p>
              <p><a href="/" onClick={(e) => { e.preventDefault(); return props.changeFlowStep('login') }}>Login instead?</a></p>
            </div>
            )
          } else if (e.type === 'email_exists') {
            return (
            <div>
              <p>
                You have an existing account that uses your email address <b>"{email}"</b>.
              </p>
              <p>
                If you forgot your password, please <a href="/" onClick={(e) => { e.preventDefault(); return props.changeFlowStep('forgotpass') }}>click HERE</a>.
              </p>
              <p><a href="/" onClick={(e) => { e.preventDefault(); return props.changeFlowStep('login') }}>Login instead?</a></p>
            </div>
            )
          }

          
          return (
            <p>{e.msg}</p>
          )
        })}
      </div>

      <form className="signup-form" action="" method="POST" onSubmit={submitForm} noValidate>
        
        <div className={classNames('form-group', {'was-validated': email.length >= 4 || submitted})}>
          <label>Email Address</label>
          <input type="email" className="form-control" name="email" placeholder="Email" required autoComplete="username" 
            minLength={4}
            maxLength={255}
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
          <div className="invalid-feedback">
            Please enter a valid email address
          </div>                  
        </div>

        <div className={classNames('form-group', {'was-validated': first_name.length >= 1 || submitted})}>
          <label>First Name</label>
          <input type="text" className="form-control" name="first_name" placeholder="First name" required 
            autoComplete="given-name"
            minLength={1}
            maxLength={255}
            value={first_name}
            onChange={(e) => setFName(e.target.value)} />
          <div className="invalid-feedback">
            First Name is required
          </div>      
        </div>

        <div className={classNames('form-group', {'was-validated': last_name.length >= 1 || submitted})}>
          <label>Last Name</label>
          <input type="text" className="form-control" name="last_name" placeholder="Last name" required 
            autoComplete="family-name" 
            minLength={1}
            maxLength={255}
            value={last_name}
            onChange={(e) => setLName(e.target.value)} />
          <div className="invalid-feedback">
            Last Name is required
          </div>      
        </div>

        <div className={classNames('form-group', {'was-validated': password.length >= 8 || submitted})}>
          <label>Password</label>
          <input type="password" className="form-control" name="Password" placeholder="password" required 
            autoComplete="new-password"  
            minLength={8}
            maxLength={255}
            value={password}
            onChange={(e) => setPassword(e.target.value)} />
          <div className="invalid-feedback">
            {password.length < 8 ? 'Password must be at least 8 characters long (longer is recommended)' : 
              'Password is required'}
          </div>      
        </div>
        
        <div className={classNames('form-group', {'was-validated': password.length >= 1 || submitted})}>
          <label>Confirm Password</label>
          <input type="password" className={classNames('form-control', { 'is-invalid': submitted && password !== password_confirm })} 
            name="password_confirm" placeholder="Confirm password" required 
            autoComplete="confirm-password"  
            minLength={8}
            maxLength={255}
            value={password_confirm}
            onChange={(e) => setPasswordConfirm(e.target.value)} />
          <div className="invalid-feedback">
            {password.length >= 8 && password !== password_confirm ? "Password and confirmation don't match" : ''}
          </div>      
        </div>

        <div className="form-group">
          <div className={classNames('custom-control custom-checkbox')}>
            <input type="checkbox" className="custom-control-input" id={"SignupEmailOpt" + myuid} defaultChecked 
              value={1} 
              onChange={(e) => setSubscribe1(e.target.checked) } />
            <label className="custom-control-label" htmlFor={"SignupEmailOpt" + myuid}>Subscribe to mailing list for Announcements and New Show notices</label>
          </div>
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Sign up
          </button>
        </div>
      </form>

    </div>
  );
}


export default SignupForm;