import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
//import monitorReducersEnhancer from './monitor'
import loggerMiddleware from './logger'
import rootReducer from './reducer'


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
      loggerMiddleware, 
      ...getDefaultMiddleware({
        //serializableCheck: {
        //  // Ignore these field paths in all actions
        //  ignoredActionPaths: [/rejected$/], // ignore rejected saga actions, which are usually Error objects
        //},
      })
    ]
  ,
  enhancers: (defaultEnhancers) => [
    ...defaultEnhancers
  ],
  devTools: process.env.NODE_ENV !== 'production',
  //preloadedState,
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStateCore = ReturnType<typeof store.getState>
export type RootState = RootStateCore;

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const UseAppThunkDispatch: () => AppThunkDispatch = useDispatch;


export default store;
