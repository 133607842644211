import React, { useEffect, useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { Loader } from "../loader";
import { ShowListShow } from "./ShowListShow";


export interface ShowListResultsProps {
  staticURL: string
  category?: string
  tag?: string
  eptype: string
  sort: string
  asc: boolean
  loading: boolean
  shows: any[]
  onTriggerNext: () => void
}


export function ShowListResults({ staticURL, loading, shows, onTriggerNext }: ShowListResultsProps) {

  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible && !loading) {
      onTriggerNext();
    }
  }, [ isVisible, loading, onTriggerNext ]);


  return (
    <div className="TRS-widget TRS-showListResults" style={{position: 'relative'}}>

      <div className="container-fluid-xl mt-6">
        <div className="d-flex justify-content-center mx-auto">
        
          <div className="preview_slider1">
            
            <div className="sliderboard">
        
              {shows.map((show, i) => 
              <div className="video-box-outer">
                <ShowListShow staticURL={staticURL} show={show} fadein={true} index={i} />
              </div>        
              )}
          
            </div>
          </div>
          
        
        </div>
      </div>

      <div ref={ref} style={{ position: 'relative', minHeight: '5rem' }}>
        <Loader loading={loading} exclass="dark" />
      </div>

    </div>
  );
}

