import * as api from '../../api'
import { User, UserForm } from '../user/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError, setFormMessageType, setLoading, setPaymentUpdateErrors, setPaymentUpdateSuccess, setSubscription, setUser } from './reducer';
import { setClientSecret, setInitError, setPrice, setPublicKey, setSubscriptionId, setUserData } from 'store/subscribe/reducer';


export const accountUpdate = createAsyncThunk(
  'account/update',
  async (userForm: UserForm, thunkApi) => {
    let user: User | undefined;

    const dispatch = thunkApi.dispatch;

    try {

      dispatch( setFormMessageType(undefined) )
      dispatch( setError([]) )
      dispatch( setLoading(false) )

      const res = await api.post('/account/me', {
        ...userForm
      });
      user = res && res.user && res.user.id ? (res.user as User) : undefined;

      if (res.error && res.errors) {
        dispatch(setError(res.errors))
      } else if (user) {
        dispatch( setUser(user) )
        dispatch( setFormMessageType("success") )
      }

    } catch (e: any) {
      // this should be a failed http response, probably a 401
      dispatch( setError(e) )
    } finally {
      dispatch( setLoading(false) )
    }
    
  }
)



export const subscriptionCancel = createAsyncThunk(
  'account/subscription/cancel',
  async (subscriptionId: string, { dispatch }) => {
    try {

      dispatch(setLoading(true))

      const res = await api.post('/subscribe/scripts/cancel', {
        subscriptionId,
      });

      dispatch(setSubscription(res))

      dispatch(setLoading(false))
      
    } catch(e: any) {
      
      dispatch(setLoading(false))

      throw new Error(e as any);
    }    
  }
)



export const subscribeUpdateInit = createAsyncThunk(
  'account/subscription/method/init',
  async function({ subscriptionId }: { subscriptionId: string }, { dispatch }) {
    try {

      dispatch(setLoading(true))

      const s = new URLSearchParams({ subscriptionId });
      const res = await api.post('/subscribe/scripts/method/init', { subscriptionId });

      if (res.error) {
        const msg1 = res.errors?.[0]?.msg || "";
        dispatch(setInitError("unknown"))
        return;
      }

      dispatch(setPublicKey(res.public_key))
      dispatch(setSubscription(res.subscription))
      dispatch(setSubscriptionId(res.subscription?.id))
      dispatch(setClientSecret(res.clientSecret))
      dispatch(setPrice(res.price))
      dispatch(setUserData(res.user))
      dispatch(setLoading(false))
      
    } catch(e: any) {
      
      dispatch(setLoading(false))

      throw new Error(e as any);
    }
  }
)


export const subscribeUpdateSetup = createAsyncThunk(
  'account/subscription/method/setup',
  async function({ subscriptionId, setupIntentId }: { subscriptionId: string, setupIntentId: string }, { dispatch }) {
    try {

      dispatch(setLoading(true))

      const s = new URLSearchParams({ subscriptionId });
      const res = await api.post('/subscribe/scripts/method/setup', { subscriptionId, setupIntentId });

      if (res.error) {
        const msg1 = res.errors?.[0]?.msg || "";
        setPaymentUpdateErrors(res.errors)
        return;
      } else {
        dispatch(setPaymentUpdateSuccess(true));
      }

      dispatch(setLoading(false))

    } catch(e: any) {
      
      dispatch(setLoading(false))

      throw new Error(e as any);
    }
  }
)
