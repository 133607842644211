import SigninFlow from "components/signInFlow";
import { useCallback, useEffect, useRef, useState } from "react"
import RatingCommentForm from "./RatingCommentForm";
import classNames from "classnames";

export interface RatingCommentFormPopupProps {}

export interface CustomPopupEventDetail {
  postId: number
  rating: number
  action: RatingCommentPopupMode
  title_pre: string
  title: string
  title_post: string
  norating?: boolean
}
export type CustomPopupEvent = CustomEvent<CustomPopupEventDetail>;

interface PostTitle {
  pre?: string
  main: string
  post?: string
}

export type RatingCommentPopupMode = "rate" | "comment" | "question" | "signin";


export function RatingCommentFormPopup(props: RatingCommentFormPopupProps) {

  const mainRef = useRef<HTMLDivElement>(null);
  const [ mode, setMode ] = useState<RatingCommentPopupMode>("signin");
  const [ postId, setPostId ] = useState<null | number>(null);
  const [ title, setTitle ] = useState<null | PostTitle>(null);
  const [ rating, setRating ] = useState<number>(0);
  const [ popupShown, setPopupShown ] = useState<boolean>(false)
  const [ noRating, setNoRating ] = useState<boolean>(false)


  useEffect(() => {
    // register bootstrap popup
    const $ = (window as any).jQuery;
    $(mainRef.current).modal({
      show: false
    })
  }, [ mainRef.current ]);


  useEffect(() => {
    // register bootstrap popup
    const $ = (window as any).jQuery;

    function open(ev: Event) {
      const e: CustomPopupEvent = (ev as CustomPopupEvent);
      if (!e.detail) return;

      setMode(e.detail.action)
      setPostId(parseInt(e.detail?.postId as unknown as string));
      setRating(e.detail.rating ?? 0)
      setTitle({
        pre: e.detail.title_pre,
        main: e.detail.title,
        post: e.detail.title_post,
      })
      setNoRating(!!e.detail.norating)

      setPopupShown(true)
    }

    function close() {
      setPopupShown(false)
    }

    function setClosedFromExternal() {
      if (popupShown) {
        setPopupShown(false)
      }
    }

    // listen for custom events to open/close the modal
    window.addEventListener('TRS_RatingCommentPopup_open', open)
    window.addEventListener('TRS_RatingCommentPopup_close', close)
    $(mainRef.current).on('hide.bs.modal', setClosedFromExternal)
    $(mainRef.current).on('hidden.bs.modal', setClosedFromExternal)

    return () => {
      window.removeEventListener('TRS_RatingCommentPopup_open', open)
      window.removeEventListener('TRS_RatingCommentPopup_close', close)
      $(mainRef.current).off('hide.bs.modal', setClosedFromExternal)
      $(mainRef.current).off('hidden.bs.modal', setClosedFromExternal)
    }
  }, [ mainRef.current, setPopupShown, setPostId, setTitle, setNoRating, setRating ])


  useEffect(() => {
    const $ = (window as any).jQuery;
    if (popupShown) {
      $(mainRef.current).modal('show');
    } else {
      $(mainRef.current).modal('hide');
      setMode("signin");
      setPostId(null);
      setNoRating(false);
      setRating(0);
      setTitle(null);
    }
  }, [ mainRef.current, popupShown ]);


  const closeModal = useCallback(() => {
    window.dispatchEvent(new Event('TRS_RatingCommentPopup_cancel'))
    setPopupShown(false)
  }, [ mainRef.current ])


  return (
  <div ref={mainRef} className="modal fade" id="{{id}}" tabIndex={-1} 
    role="dialog" aria-labelledby="RatingFormLoginPopup" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-body">

          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>

          <div className={classNames({ 'd-none': (mode === "signin") })}>
            {title && 
            <h2>
              {title.pre && <small className="d-block">{title.pre}</small>}
              <span className="d-block">{title.main}</span>
              {title.post && <small className="d-block">{title.post}</small>}
            </h2>
            }

            <div className="TRS-widget mt-5">
              <RatingCommentForm 
                postId={postId} 
                isPopup={true} 
                popupActionMode={mode !== 'signin' ? mode : null}
                setPopupMode={setMode} 
                postRating={rating} 
                noRating={noRating}
                />
            </div>
          </div>

          <div className={classNames("TRS-widget mt-5", { 'd-none': (mode !== "signin") })}>
            <SigninFlow />
          </div>

        </div>
      </div>
    </div>
  </div>  
  )
}
