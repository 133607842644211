import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/select";
import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeAddressElementOptions, StripeError, StripePaymentElementOptions } from "@stripe/stripe-js";
import { selectUserData } from "../../store/subscribe/select";
import { Loader } from "../loader";
import classNames from "classnames";


export interface PaymentUpdateProps {
  clientSecret: string
  terms_url?: string
}


const initializerInitState: InitializedState = {
  parts: new Set(), 
  init: false,
}

export function PaymentUpdateForm({ clientSecret, terms_url }: PaymentUpdateProps) {

  const stripe = useStripe();
  const elements = useElements();

  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ errorMessage, setErrorMessage ] = useState<string>();
  const [ initializedState, initializedDispatch ] = useReducer(initializedReducer, initializerInitState);

  useEffect(() => initializedDispatch({ type: "stripe", payload: !!stripe }), [ stripe ]);
  useEffect(() => initializedDispatch({ type: "elements", payload: !!elements }), [ elements ]);
  useEffect(() => initializedDispatch({ type: "user", payload: !!user }), [ user ]);
  useEffect(() => initializedDispatch({ type: "userData", payload: !!userData }), [ userData ]);


  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {

    setErrorMessage(undefined)

    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const formData = new FormData(e.target as HTMLFormElement);
    const terms_agree = formData.get('terms_agree')?.toString();

    if (!terms_agree) {
      setErrorMessage(`You must agree to the Terms and Conditions`)
      return;
    }

    setLoading(true)

    const elemResult = await elements.submit();
    if (elemResult && elemResult.error) {
      setErrorMessage(elemResult.error.message)
      setLoading(false)
      return;
    }
    
    const result = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            email: userData?.email || "",
          }
        }
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setErrorMessage(result.error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setLoading(false)
  }, [])


  const defaultAddress = useMemo(() => {
    const defaultAddress = userData?.addresses[0];
    let address = defaultAddress ? {
      line1: defaultAddress.line1,
      line2: [ defaultAddress.line2, defaultAddress.line3 ].join(" ").trim(),
      city: defaultAddress.city,
      state: defaultAddress.province,
      postal_code: [ defaultAddress.postal_code, defaultAddress.postal_code_plus ].filter(d => (d || "").trim().length > 0).join("-"),
      country: defaultAddress.country,
    } : null;
    return address;
  }, [ userData ]);


  const defaultPhone = useMemo(() => {
    const phoneBook = userData?.phone_numbers || [];
    const dPhone = phoneBook.find(p => p.label === "Phone") || phoneBook.find(p => p.label === "Mobile") || phoneBook.find(p => p.label === "Home Phone") || phoneBook[0];
    return dPhone;
  }, [ userData ])


  const paymentOptions: StripePaymentElementOptions = useMemo(() => {
    return {
      defaultValues: {
        billingDetails: {
          name: [ user?.first_name, user?.middle_name, user?.last_name ].filter((n) => n && n.length > 0).join(" "),
          email: user?.email || "",
          //phone: defaultPhone?.number || "",
          address: defaultAddress || undefined,
        },
      }
    }
  }, [ user, defaultAddress, defaultPhone ]);


  const addressOptions: StripeAddressElementOptions = useMemo(() => {

    const contacts = (userData?.addresses || []).map(a => ({
      name: a.name,
      address: {
        line1: a.line1,
        line2: [ a.line2, a.line3 ].join(" ").trim(),
        city: a.city,
        state: a.province,
        postal_code: [ a.postal_code, a.postal_code_plus ].filter(d => d && d.trim().length > 0).join("-"),
        country: a.country,
      }
    }));

    return {
      mode: "billing",
      defaultValues: {
        name: [ user?.first_name, user?.middle_name, user?.last_name ].filter((n) => n && n.trim().length > 0).join(" "),
        //phone: defaultPhone?.number || "",
        address: defaultAddress || undefined,
      },
      contacts: contacts,
      //fields: {
      //  phone: "always",
      //},
      //validation: {
      //  phone: { 
      //    required: "always" 
      //  },
      //},
    }
  }, [ user, defaultAddress, defaultPhone ]);



  const standardStyle = {
    base: {
      iconColor: '#000',
      color: '#000',
      fontFamily: "DM Sans",
      fontWeight: '400',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#777',
      },
      '::placeholder': {
        color: '#777',
      },
    },
    invalid: {
      iconColor: '#FF0000',
      color: '#FF0000',
    },
  };


  return (
    <div className="relative">

      <Loader loading={!initializedState.init || loading} exclass="middle dark" >
        {!initializedState.init ? (
          <div className="text-center">Loading, please wait...</div>
        ) : (
          <div className="text-center">
            Updating payment method...
          </div>
        )}
      </Loader>

      <form onSubmit={handleSubmit}>

        <div className="mb-3">
          <PaymentElement options={paymentOptions} onReady={() => initializedDispatch({ type: "elPayment", payload: true })} />
        </div>

        <div className="FormField mb-3">
          <label>Billing Address</label>
          <AddressElement options={addressOptions} onReady={() => initializedDispatch({ type: "elAddress", payload: true })} />
        </div>

        {terms_url && (
          <div className="mt-4 mb-3">
            <label className="d-flex align-items-middle">
              <span className="d-block" style={{ display: "block", position: "relative", transform: "scale(1.4)", transformOrigin: "0 50%" }}>
                <input type="checkbox" name="terms_agree" value="1" />
              </span>
              <span className="d-block" style={{ marginLeft: "0.75em" }}>
                I have read and agree to the {" "}
                <a href={terms_url} target="_blank">Terms &amp; Conditions</a>
              </span>
            </label>
          </div>
        )}


        <div className={classNames("my-4", !errorMessage && "d-none" )}>
          <div className="alert alert-danger">
            <h5>Payment Not Completed</h5>
            <p>{errorMessage}</p>
          </div>
        </div>

        <div>
          Submitting this form will update your subscription payment method.
          Charges will not occur immediately unless your regular payment is past due.
          Future charges will be incurred to this payment method in accordance with your regular payment schedule.
        </div>

        <div className="my-4">
          <button type="submit" disabled={!initializedState.init || loading} className="btn btn-primary">
            Update
          </button>
        </div>

      </form>

    </div>
  )
}




type Initilizer = "stripe" | "elements" | "user" | "userData" | "elPayment" | "elAddress";
interface InitializedState {
  parts: Set<Initilizer>
  init: boolean
}

type InitializedAction = {
  type: Initilizer
  payload: boolean
}

function initializedReducer(state: InitializedState, action: InitializedAction): InitializedState {
  const parts = new Set(state.parts);

  if (action.payload) {
    parts.add(action.type)
  } else {
    parts.delete(action.type)
  }

  const init = parts.size >= 6;

  return {
    parts,
    init,
  }
}