import { useEffect } from 'react';


export function usePopState(callback: (loc: Location) => void): void {

  useEffect(() => {

    const handler = (e: PopStateEvent) => { 
      callback(window.location)
    }

    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('popstate', handler)
    }
  });

}
