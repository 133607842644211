import React from "react";
import classNames from 'classnames';


export interface RatingStarProps {
  selectScore: (e: React.MouseEvent, score: number) => void;
  score: number;
  selectedScore: number;
}


export function RatingStar(props: RatingStarProps) {

  function selectScore(e: React.MouseEvent, score: number) {
    e.preventDefault();
    return props.selectScore(e, score);
  }

  return (
    <button type="button" className={classNames('rating-star', {active: props.score <= props.selectedScore})} 
      onClick={e => selectScore(e, props.score)} title={props.score+' star'}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path d="M9 11.3l3.71 2.7-1.42-4.36L15 7h-4.55L9 2.5 7.55 7H3l3.71 2.64L5.29 14z"/>
      </svg>
    </button>
  );
}


export default RatingStar;
