import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { startSearch } from "./actions";
import { PodcastListState } from "./types";
import { createSliceWrapperHook } from "../../hooks/createSliceWrapperHook";
import { useCallback } from "react";


const initialState: PodcastListState = {
  loading: false,
  podcasts: [],
  done: false
};


export const podcastListSlice = createSlice({
  name: "podcastlist",
  initialState: initialState,
  reducers: {

    setFormLoading: (state: PodcastListState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    podcastsFetched: (state: PodcastListState, { payload }: PayloadAction<any>) => {
      let done = state.done;
      let shows = state.podcasts;
      if (payload.offset > 0) {
        done = !payload.results.length;
        shows = shows.concat(payload.results)
      } else {
        done = false;
        shows = payload.results;
      }
      state.loading = false;
      state.done = done;
      state.podcasts = shows;
    },

    podcastsClear: (state: PodcastListState, action: PayloadAction<void>) => {
      state.done = false;
      state.podcasts = []
    },

  },
  extraReducers: (builder) => {

  }
});


export const { setFormLoading, podcastsFetched, podcastsClear } = podcastListSlice.actions;

export const usePodcastListSlice = () => createSliceWrapperHook(podcastListSlice, "podcastlist", {
  startSearch,
});


export default podcastListSlice.reducer;