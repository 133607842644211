import React, { useState, useEffect } from "react";
import { SearchState } from "../store/search/types";
import SearchResultList from "./SearchResultList";
import { useSearchSlice } from "store/search/reducer";
import { useSelector } from "react-redux";
import { selectSearchLoading, selectSearchQuery, selectSearchResult } from "store/search/select";


export interface SearchPageProps extends SearchState {}


export function SearchPage({}: SearchPageProps) {

  const { searchFetch } = useSearchSlice();
  const query = useSelector(selectSearchQuery)
  const result = useSelector(selectSearchResult)
  const loading = useSelector(selectSearchLoading)

  const [myuid] = useState( (window as any).TRS.wuid() );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchFetch(searchParams.get('search') ?? "")
  }, [ searchFetch ])

  useEffect(() => {
    function onPop() {
      const searchParams = new URLSearchParams(window.location.search);
      searchFetch(searchParams.get('search') ?? "")
    }

    window.addEventListener('popstate', onPop);

    return () => {
      window.removeEventListener('popstate', onPop);
    }
  }, [ searchFetch ])

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const f = (e.target as HTMLFormElement);
    const input = (f.querySelector('[name="search"]') as HTMLInputElement);
    let s = input?.value;

    if (typeof s === 'string') {
      s = s.trim();
      window.history.pushState({}, '', window.location.pathname+'?search='+encodeURIComponent(s));
      if (s.length) {
        searchFetch(s);
      }
    }

  }

  return (
    <div className="TRS-widget TRS-searchPage" style={{position: 'relative'}} >

      <div className="h1">Search</div>

      <form className="search-form" action="" method="POST" onSubmit={submitForm} noValidate>
        
        <div className="input-group mb-3">
          <input type="text" name="search" className="form-control" 
            placeholder="search..." aria-label="search" aria-describedby={'search-button-' + myuid} 
            defaultValue={query} />
          <div className="input-group-append">
            <button className="btn btn-primary" type="submit" id={'search-button-' + myuid}>Search</button>
          </div>
        </div>

      </form>

      {result ? (
        <SearchResultList result={result} query={query} loading={loading} />
      ) : ''}

    </div>
  );
}


export default SearchPage;