import React, { useCallback, useEffect, useRef, useState } from "react";
import { EntityListForm } from "./EntityListForm";
import { usePopState } from "../../hooks/usePopState";
import { useEntityListSlice } from "../../store/entityList/reducer";
import { selectEntityListInitLoaded, selectEntityListLoading, selectEntityListPodcasts, selectEntityListPortals, selectEntityListPortalsDone, selectEntityListPodcastsDone, selectEntityListShowsFullDone, selectEntityListShowsClipDone, selectEntityListShowsFull, selectEntityListShowsClip } from "../../store/entityList/select";
import { useSelector } from "react-redux";
import { EntityListResults } from "./EntityListResults";
import { EntityListHeader } from "./EntityListHeader";
import { Loader } from "components/loader";


export type QType = "all" | "portal" | "podcast" | "showfull" | "showclip";


export interface EntityListProps {
  categorySlug?: string
  categoryName?: string
  tagSlug?: string
  tagName?: string
  staticurl: string
}


export function EntityList({ staticurl, categorySlug, categoryName, tagSlug, tagName }: EntityListProps) {

  const ref = useRef<HTMLDivElement | null>(null)

  const { startSearch, startSearchOnType } = useEntityListSlice();

  const portals = useSelector(selectEntityListPortals);
  const podcasts = useSelector(selectEntityListPodcasts);
  const showsfull = useSelector(selectEntityListShowsFull);
  const showsclip = useSelector(selectEntityListShowsClip);

  const portals_done = useSelector(selectEntityListPortalsDone);
  const podcasts_done = useSelector(selectEntityListPodcastsDone);
  const showsfull_done = useSelector(selectEntityListShowsFullDone);
  const showsclip_done = useSelector(selectEntityListShowsClipDone);
  
  const loading = useSelector(selectEntityListLoading);
  const initLoaded = useSelector(selectEntityListInitLoaded);
  
  const [ initialized, setInitialized ] = useState<boolean>(false);
  const [ qType, setQType ] = useState<QType>('all');
  const [ sort, setSort ] = useState<string>('aired');
  const [ asc, setAsc ] = useState<boolean>(false);


  const setStateFromURL = () => {
    // gather state from URL
    const sp = new URLSearchParams(window.location.search);
    setQType(sp.get('qtype') as QType || "all")
    setSort(sp.get('sort') || "aired")
    setAsc(!!sp.get('asc'))
  }


  const refreshSearch = useCallback(() => {
    // start a new search
    startSearch({
      category: categorySlug, 
      tag: tagSlug, 
      sort, 
      qType, 
      asc, 
      offset: 0,
      limit: 6,
    });
  }, [ startSearch, categorySlug, tagSlug, sort, qType, asc ]);

  
  const noop = () => {
    // do nothing
  }


  const loadNextPage = (qtype: QType) => {
    // start a new search
    let offset = 0;
    if (qtype === 'showfull') {
      if (showsfull_done) return;
      offset = showsfull.length;
    } else if (qtype === 'showclip') {
      if (showsclip_done) return;
      offset = showsclip.length;
    } else if (qtype === 'podcast') {
      if (podcasts_done) return;
      offset = podcasts.length;
    } else if (qtype === 'portal') {
      if (portals_done) return;
      offset = portals.length;
    } else {
      return;
    }

    startSearchOnType({
      category: categorySlug, 
      tag: tagSlug, 
      sort, 
      qType: qtype,
      asc, 
      offset,
      limit: 12,
    });
  }


  useEffect(() => {
    // on load, setup our state from the initial URL
    setStateFromURL();
    setInitialized(true);
  }, []) 

  useEffect(() => {
    if (!initialized) return;

    // re-start our search when our search params change
    updateURLQuery(sort, qType, asc);
    refreshSearch();
  }, [ initialized, sort, qType, asc, refreshSearch ]) 


  usePopState((loc: Location) => {
    // if we pop state to an older url, set our state from the current url
    setStateFromURL();
  });


  // handle form field changes
  const changeSort = (val: string) => setSort(val)
  const changeQType = (val: QType) => setQType(val)
  const changeAsc = (val: boolean) => setAsc(val)


  return (
    <div className="TRS-widget TRS-EntityList" style={{position: 'relative'}}>

      <div>
        <EntityListHeader qType={qType} category={categoryName} tag={tagName} />
      </div>

      <EntityListForm 
        qType={qType} sort={sort} asc={asc}
        onChangeQType={changeQType}
        onChangeSort={changeSort}
        onChangeAsc={changeAsc}
        ></EntityListForm>



      {initLoaded && (
      <>
        <EntityNoResult type="portal" name="Portals" collection={portals}
          qtype={qType} 
          categorySlug={categorySlug} categoryName={categoryName}
          tagSlug={tagSlug} tagName={tagName}
          />
        <EntityNoResult type="podcast" name="Podcasts" collection={podcasts}
          qtype={qType} 
          categorySlug={categorySlug} categoryName={categoryName}
          tagSlug={tagSlug} tagName={tagName}
          />
        <EntityNoResult type="showsfull" name="Full Episodes" collection={showsfull}
          qtype={qType} 
          categorySlug={categorySlug} categoryName={categoryName}
          tagSlug={tagSlug} tagName={tagName}
          />
        <EntityNoResult type="showsclip" name="Ruddle Show Clips" collection={showsclip}
          qtype={qType} 
          categorySlug={categorySlug} categoryName={categoryName}
          tagSlug={tagSlug} tagName={tagName}
          />
      </>
      )}



      {(portals && portals.length > 0) && (
        <>
          <div className="d-flex align-items-end mt-4 mb-2">
            <h2 className="my-0">Portals</h2>
          </div>
          <EntityListResults 
            staticURL={staticurl}
            category={categorySlug} tag={tagSlug} 
            sort={sort} asc={asc}
            entityType="portal"
            entities={portals}
            loading={loading}
            ></EntityListResults>

          {(!portals_done && !loading) &&
            <div className="text-center my-2">
              <button type="button" className="btn loadmore-button" onClick={() =>loadNextPage('portal')}>
                <span className="mmore">Load More Portals</span>
              </button>
            </div>
          }

        </>
      )}


      {(podcasts && podcasts.length > 0) && (
        <>
          <div className="d-flex align-items-end mt-4 mb-2">
            <h2 className="my-0">Podcasts</h2>
          </div>
          <EntityListResults 
            staticURL={staticurl}
            category={categorySlug} tag={tagSlug} 
            qType={qType} sort={sort} asc={asc}
            entityType="podcast"
            entities={podcasts}
            loading={loading}
            onTriggerNext={noop}
            ></EntityListResults>

          {(!podcasts_done && !loading) &&
            <div className="text-center">
              <button type="button" className="btn loadmore-button"  onClick={() =>loadNextPage('podcast')}>
                <span className="mmore">Load More Podcasts</span>
              </button>
            </div>
          }
        </>
      )}


      {(showsfull && showsfull.length > 0) && (
        <>
          <div className="d-flex align-items-end mt-4 mb-2">
            <h2 className="my-0">Full Episodes</h2>
          </div>
          <EntityListResults 
            staticURL={staticurl}
            category={categorySlug} tag={tagSlug} 
            qType={qType} sort={sort} asc={asc}
            entityType="show"
            entities={showsfull}
            loading={loading}
            onTriggerNext={noop}
            ></EntityListResults>

          {(!showsfull_done && !loading) &&
            <div className="text-center">
              <button type="button" className="btn loadmore-button"  onClick={() =>loadNextPage('showfull')}>
                <span className="mmore">Load More Shows</span>
              </button>
            </div>
          }

        </>
      )}


      {(showsclip && showsclip.length > 0) && (
        <>
          <div className="d-flex align-items-end mt-4 mb-2">
            <h2 className="my-0">Ruddle Show Clips</h2>
          </div>
          <EntityListResults 
            staticURL={staticurl}
            category={categorySlug} tag={tagSlug} 
            qType={qType} sort={sort} asc={asc}
            entityType="show"
            entities={showsclip}
            loading={loading}
            onTriggerNext={noop}
            ></EntityListResults>

          {(!showsclip_done && !loading) &&
            <div className="text-center">
              <button type="button" className="btn loadmore-button"  onClick={() =>loadNextPage('showclip')}>
                <span className="mmore">Load More Clips</span>
              </button>
            </div>
          }

        </>
      )}


      {(loading && !initLoaded) && 
        <div ref={ref} style={{ position: 'relative', minHeight: '5rem' }}>
          <Loader loading={loading} exclass="dark" />
        </div>
      }

    </div>
  );
}


function updateURLQuery(sort: string, qtype: string, asc: boolean) {
  const sp = new URLSearchParams(window.location.search);
  sp.set('sort', sort)
  sp.set('qtype', qtype)
  if (asc) {
    sp.set('asc', '1')
  } else if (sp.get('asc')?.length) {
    sp.delete('asc')
  }

  let newurl = new URL(window.location.href)
  newurl.search = sp.toString();
  window.history.pushState({}, "", newurl.toString());
}


function InType({category, tag}: {category?: string, tag?: string}) {
  if (category) {
    return (
      <>
        {" "}
        <span>
          in {category}
        </span>
      </>
    )
  } else if (tag) {
    return (
      <>
        {" "}
        <span>
          in {category}
        </span>
      </>
    )
  } 

  return <></>

}



function AllEntTypeLink({children, type, category, tag}: {type: string, category?: string, tag?: string} & React.PropsWithChildren) {
  let link = '/all';

  if (type === 'show') {
    link = '/shows';
  } else if (type === 'podcast') {
    link = '/podcasts';
  } else if (type === 'portal') {
    link = '/portals';
  }

  if (category) {
    link += '/' + category;
  } else if (tag) {
    link += '/tagged/' + tag;
  }

  return (
    <a href={link}>{children}</a>
  )
}


interface EntityNoResultProps {
  name: string
  collection: any[]
  qtype: QType
  type: string
  categorySlug?: string
  categoryName?: string
  tagSlug?: string
  tagName?: string
}


function EntityNoResult({ name, collection, qtype, type, categorySlug, categoryName, tagSlug, tagName }: EntityNoResultProps) {

  if (qtype === type && !(collection && collection.length)) {
    return (
      <div className="my-4 h2 text-center">
        <small>Sorry, no {name} found {" "}</small>

        {categorySlug && (
        <>
          <small>in {" "}</small>
          <b>{categoryName}</b>
        </>
        )}
        {tagSlug && (
        <>
          <small>tagged {" "}</small>
          <b>{tagName}</b>
        </>
        )}
      </div>    
    )
  }

  return <></>
}


export default EntityList