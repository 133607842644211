import React from "react";
import { useSelector } from 'react-redux';
import SigninFlow from './signInFlow';
import PollForm from './pollForm';
import { selectPoll } from "store/polls/select";
import { selectUser } from "store/user/select";


export interface PollFlowProps {}


export function PollFlow({}: PollFlowProps) {

  const poll = useSelector(selectPoll);
  const user = useSelector(selectUser);


  if (!poll) return (
    <div></div>
  );

  return (
    <div className="TRS-widget TRS-pollFlow" style={{position: 'relative'}}>

      {user && user.id ? (
        <div>
          <PollForm withCloser={true} />
        </div>
      ) : (
        <div>
          <h1>Sign In to add your Vote</h1>
          <SigninFlow />
        </div>
      )}

    </div>
  );
}


export default PollFlow;
