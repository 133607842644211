import React, { useEffect, useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { Loader } from "../loader";
import { PodcastListPodcast } from "./PodcastListPodcast";


export interface PodcastListResultsProps {
  staticURL: string
  category?: string
  tag?: string
  eptype: string
  sort: string
  asc: boolean
  loading: boolean
  podcasts: any[]
  onTriggerNext: () => void
}


export function PodcastListResults({ staticURL, loading, podcasts, onTriggerNext }: PodcastListResultsProps) {

  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible && !loading) {
      onTriggerNext();
    }
  }, [ isVisible, loading, onTriggerNext ]);


  return (
    <div className="TRS-widget TRS-showListResults" style={{position: 'relative'}}>
            
      <div>
        {podcasts.map((podcast, i) => 
        <div className={`video-box-outer fadeInUp animated`} style={{ animationDelay: i % 5 > 0 ? `${(i % 5) * 200}ms` : '0'}}>
          <PodcastListPodcast staticURL={staticURL} podcast={podcast} fadein={true} index={i} />
        </div>        
        )}
      </div>

      <div ref={ref} style={{ position: 'relative', minHeight: '5rem' }}>
        <Loader loading={loading} exclass="dark" />
      </div>

    </div>
  );
}

