import React, { useMemo } from "react"

export interface PacificDateProps {
  timestamp: number
  output: "date" | "time" | "datetime"
}

export function PacificDate({ timestamp, output }: PacificDateProps) {

  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Los_Angeles',
    dateStyle: "medium",
  };

  const formatted: string = useMemo(() => {
    const d = new Date(timestamp * 1000);
    switch(output) {
      case "date": {
        return d.toLocaleDateString('en-US', options);
        break;
      }
      case "time": {
        return d.toLocaleTimeString('en-US', options);
        break;
      }
      default: {
        return d.toLocaleString('en-US', options);
      }
    }
  }, [ timestamp ])

  return (
    <>{formatted}</>
  )
}