import React from "react";
import { useSelector } from 'react-redux';
import { Loader } from "./loader";
import { SigninFlowChildProps } from "../store/user/types";
import { useUserSlice } from "store/user/reducer";
import { selectAppRegistrations } from "store/app/select";
import { selectFormLoading } from "store/user/select";
import UserInfo from "./userInfo";


interface LogoutFormProps extends SigninFlowChildProps {}

export function LogoutForm({}: LogoutFormProps) {

  const { userLogout } = useUserSlice();
  const appRegisters = useSelector(selectAppRegistrations);
  const formLoading = useSelector(selectFormLoading);

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    userLogout(appRegisters);
  }

  function closeModal() {
    window.dispatchEvent(new Event('TRS.logged-in'));
  }

  return (
    <div className="TRS-widget logoutForm" style={{position: 'relative'}}>
      
      <form className={'logout-form'} 
        action="" method="POST" noValidate onSubmit={submitForm}>

        <div>
          <UserInfo />
        </div>

        <div className="form-group text-center mt-3 d-flex" style={{ justifyContent: "space-between" }}>

          <button type="submit" className="btn btn-outline-secondary">
            Sign Out
          </button>

          <button type="button" className="btn btn-primary" onClick={() => closeModal()}>
            Close
          </button>

        </div>

      </form>

      <Loader loading={formLoading} exclass="dark" />

    </div>
  );
}


export default LogoutForm;
