import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { EntityListState } from "./types";

const slice = (state: RootState) => state.entitylist;

export const selectEntityListPortalsDone = createSelector(slice, (state: EntityListState) => state.portals_total <= state.portals.length);
export const selectEntityListPodcastsDone = createSelector(slice, (state: EntityListState) => state.podcasts_total <= state.podcasts.length );
export const selectEntityListShowsFullDone = createSelector(slice, (state: EntityListState) => state.showsfull_total <= state.showsfull.length);
export const selectEntityListShowsClipDone = createSelector(slice, (state: EntityListState) => state.showsclip_total <= state.showsclip.length);

export const selectEntityListLoading = createSelector(slice, (state: EntityListState) => state.loading);
export const selectEntityListInitLoaded = createSelector(slice, (state: EntityListState) => state.listLoaded);

export const selectEntityListPortals = createSelector(slice, (state: EntityListState) => state.portals);
export const selectEntityListPodcasts = createSelector(slice, (state: EntityListState) => state.podcasts);
export const selectEntityListShowsFull = createSelector(slice, (state: EntityListState) => state.showsfull);
export const selectEntityListShowsClip = createSelector(slice, (state: EntityListState) => state.showsclip);

