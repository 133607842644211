import React from "react";
import classNames from 'classnames';


interface LoaderProps extends React.PropsWithChildren {
  loading: boolean;
  exclass?: string;
}

export function Loader({ loading, exclass, children }: LoaderProps) {

  let className = classNames('TRS-loader', exclass, { on: loading });

  return (
    <div className={className}>
      <div className="TRS-loading-spinner-wrapper">
        
        <div className="TRS-loading-spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>

        <div>
          {children}
        </div>

      </div>
    </div>
  );
}
