

export function wuid() {
  if (!(window as any).WUIDCounter) {
    (window as any).WUIDCounter = 0;
  }
  (window as any).WUIDCounter += 1;
  return "WUID_"+(window as any).WUIDCounter;
}


// Debounce function dereved from https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func: Function, wait: number, immediate: boolean = false) {
	let timeout: NodeJS.Timeout | null;

	return function(this: any) {
		const context = this;
    const args = arguments;

		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		const callNow = immediate && !timeout;
		
    if (timeout) {
      clearTimeout(timeout);
    }
    		
    timeout = setTimeout(later, wait);
		
    if (callNow) func.apply(context, args);
	};
};


export function lpad(str: string, minlen: number, padchr?: string) {
	str = str + "";
	padchr = (padchr+"").length === 1 ? padchr+"" : ' ';

	if (str.length < minlen) {
		let i = 0;
		while (str.length < minlen && i < 1000) {
			str = padchr + str;
			i++;
		}
	}

	return str;
}


export function secondsToHMS(seconds: number): { h: number, m: number, s: number } {

  let h: number;
  let m: number;
  let s: number;

  h = Math.floor(seconds / 3600);
  seconds = seconds - (h * 3600);
  m = Math.floor(seconds / 60);
  s = seconds - (m * 60);

  return {
    h, m, s
  }
}


const season_num_regex = /^[0-9]+$/;

export function seFormat(season: number | string | undefined, episode: number | string | undefined) {
  let ret = '';
  if (season && episode) { 
    if (season_num_regex.test(`${season}`)) {
      ret += 'S' + lpad(season+"", 2, '0');
    } else if (`${season}`.length) {
      ret += season;
    }

    if (episode) {
      ret += ' E' + lpad(episode+"", 2, '0');
    }
  }
  return ret;
}
