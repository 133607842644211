import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { SubscribeState } from "./types";

const slice = (state: RootState) => state.subscribe;

export const selectSubscribFlowLoading = createSelector(slice, (state: SubscribeState) => state.subscribeFlowLoading);
export const selectPublicKey = createSelector(slice, (state: SubscribeState) => state.public_key);
export const selectClientSecret = createSelector(slice, (state: SubscribeState) => state.clientSecret);
export const selectPrice = createSelector(slice, (state: SubscribeState) => state.price);
export const selectUserData = createSelector(slice, (state: SubscribeState) => state.userData);
export const selectSubscribeInitError = createSelector(slice, (state: SubscribeState) => state.initError);
export const selectGranted = createSelector(slice, (state: SubscribeState) => state.granted);
