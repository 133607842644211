import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { SpecialPageState } from "./types";

const slice = (state: RootState) => state.specialpage;

export const selectLoading = createSelector(slice, (state: SpecialPageState) => state.loading);
export const selectRenderedPage = createSelector(slice, (state: SpecialPageState) => state.renderedPage);
export const selectMetaData = createSelector(selectRenderedPage, (state) => state?.metadata);

