import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { PollsState } from "./types";

const slice = (state: RootState) => state.polls;

export const selectChartLibLoaded = createSelector(slice, (state: PollsState) => state.chart_lib_loaded);
export const selectPollFormLoading = createSelector(slice, (state: PollsState) => state.form_loading);
export const selectPoll = createSelector(slice, (state: PollsState) => state.poll);
export const selectVoteSuccess = createSelector(slice, (state: PollsState) => state.vote_success);
