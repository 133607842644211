import * as api from '../../api'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading, setQuery, setResult } from './reducer';


export const searchFetch = createAsyncThunk(
  'search/fetch',
  async function(searchQuery: string, { dispatch }) {

    dispatch(setQuery(searchQuery))
    dispatch(setLoading(true))

    try {
      const res = await api.get('/search?search='+encodeURIComponent(searchQuery));
      dispatch(setResult(res || {}))
    } catch (e) {
      // this should be a failed http response, probably a 401
      // ignore error here - TODO
    } finally {
      dispatch(setLoading(false))
    }
  }
)

