import React, { useMemo } from "react";
import { md5 } from "../../md5";
import { imageResizeURL } from "../../resizeURL";
import { lpad, seFormat } from "../../util";


export interface PortalListEntityProps {
  staticURL: string
  fadein: boolean
  index: number
  portal: any
}


export function PortalListEntity({ staticURL, fadein, index, portal }: PortalListEntityProps) {

  const previewTimeStamp: number = portal.data.preview_timestamp || (Math.floor(parseInt(portal.data.duration) / 2)) || 25;
  const posterImage = portal.data.thumbnail 
    ? staticURL + `/portal-uploads/${portal.updated}/${portal.data.thumbnail}`
    : "";
  
  const duration = formatDuration(portal.data.duration);

  const seFormatted = seFormat(portal.data.season_number, portal.data.episode_number);

  const delay = 0.125 * (index % 3);

  return (
    <div className={`video-box1${fadein ? ' wow fadeIn' : ''}`} 
      data-wow-delay={delay + "s"}>
      
      <div className="thumb">
        <a href={`/portal/${portal.urlpath}`} className="img">
          <span className="still" style={{ backgroundImage: `url('${posterImage}')` }}></span>
        </a>
    
        <span className="cover"></span>
    
        <a href={`/portal/${portal.urlpath}?play=true`} className="b_play">
          PORTAL
        </a>
        
        <a href={`/portal/${portal.urlpath}`} className="b_info">
          <svg className="icon icon-info" style={{ width: '1.25em', height: '1.25em'}}>
            <use xlinkHref="#icon-info"></use>
          </svg>
        </a>
        
        {/*
        <span className="d_time"> 
          <small>{duration}</small>
        </span>
        */}
    
      </div>
      
      <div className="info">

        <p className="epnum" style={{ display: (portal.data.season_number && portal.data.episode_number ? "" : "none") }}>
          {seFormatted}
        </p>
    
        <p className="pre" style={{ display: (portal.data.title_pre ? "" : "none") }}>{portal.data.title_pre}</p>
        
        <h3 className="title">{portal.data.title}</h3>

        <p className="post" style={{ display: (portal.data.title_post ? "" : "none") }}>{portal.data.title_post}</p>
        
      </div>
    
    </div>
  );
}


function formatDuration(input: string) {
  let n = parseInt(input);

  let h,m;

  h = Math.floor(n / 3600);
  n = n - (h * 3600);
  
  m = Math.floor(n / 60);
  n = n - (m * 60);

  if (h) {
    return `${h}:${lpad(m+"", 2, '0')}:${lpad(n+"", 2, '0')}`
  } else {
    return `${lpad(m+"", 2, '0')}:${lpad(n+"", 2, '0')}`
  }

}
