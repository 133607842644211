import React from "react";
import { HTMLAttributes, useRef, useEffect } from "react"

type Props = HTMLAttributes<HTMLDivElement> & {
  html: string
  updateCallback?: (el: HTMLDivElement) => void
}

export function DangerouslySetHtmlContent({
  html,
  dangerouslySetInnerHTML,
  updateCallback,
  ...rest
}: Props) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef<HTMLDivElement>(null)
  html = html || "";

  useEffect(() => {
    if (!divRef.current) throw new Error("divRef can't be null")

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml);// Append the new content

  }, [html, divRef])


  useEffect(() => {
    if (updateCallback && divRef.current) {
      updateCallback(divRef.current)
    }
  }, [html, divRef, updateCallback])


  return (
    <div {...rest} ref={divRef}></div>
  )
}

export default DangerouslySetHtmlContent