import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Loader } from "./loader";
import classNames from 'classnames';
import { selectForgotPassResult, selectFormLoading, selectUser } from "store/user/select";
import { useUserSlice } from "store/user/reducer";
import { SigninFlowStep } from "store/user/types";


export interface ForgotPasFormProps {
  fbMessage: boolean
  changeStep: (step: SigninFlowStep) => void
}

export function ForgotPassForm({ changeStep, fbMessage }: ForgotPasFormProps) {

  const user = useSelector(selectUser);

  const { userSendLink } = useUserSlice();

  const [username, setUsername] = useState('');

  const forgotpass_result = useSelector(selectForgotPassResult);
  const form_loading = useSelector(selectFormLoading)


  function updateUsername(e: React.ChangeEvent<HTMLInputElement>) {
    setUsername(e.target.value || '');
  }

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // check for a completed form
    //const f = (e.target as HTMLFormElement);
    if (username.length > 3) {
      userSendLink({ username: username, url: window.location.href });
    }
  }


  return (
    <div className="TRS-widget forgotPassForm" style={{position: 'relative'}}>

      {fbMessage && <FBMessage />}

      <div className="h1">Forgot Password</div>

      <div className="form-group text-center">
        Don't have an account? <a href="/" onClick={e => { e.preventDefault(); changeStep('signup')}}>Sign up</a>
      </div>

      <div className="form-group text-center">
        Enter your email address and we'll send a link to log you in automatically.
      </div>

      <div className={classNames('alert alert-danger', {'d-none': forgotpass_result !== 'error'})}>
        <h4>Account Not Found</h4>
        <p>
          Sorry, we couldn't find an account for the email <b>{username}</b>. 
          Please double check the address and try again.
        </p>
      </div>

      <div className={classNames('alert alert-info', {'d-none': forgotpass_result !== 'success'})}>
        <h4>Email Sent</h4>
        <p>
          We've sent an email with a login link to you. Please check your email and click on the link to log in.
        </p>
      </div>

      <form className={classNames('forgotpass-form')} 
        action="" method="POST" noValidate onSubmit={submitForm}>
        
        <div className={classNames('form-group', {'was-validated': username.length >= 4})}>
          <label>Email Address</label>
          <input type="email" className="form-control" name="username" placeholder="email" required autoComplete="username" 
            minLength={5}
            maxLength={255}
            value={username}
            onChange={updateUsername} />
          <div className="invalid-feedback">
            Please enter a valid email address
          </div>                    
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Send Login Link
          </button>
        </div>

      </form>


      <div className="form-group text-center">
        Try signing in again? <a href="/" onClick={e => { e.preventDefault(); changeStep('login')}}>Sign In</a>
      </div>

      <Loader loading={form_loading} exclass="dark" />

    </div>
  );

}


function FBMessage() {
  return (
  <div className="bghl1-100 p-4 mx-auto mb-4" style={{ maxWidth: "900px" }}>
    
    <h1 className="text-center">
      Notice for Facebook Login Users
    </h1>

    <div className="">
      <p>
        Due to recent technical and policy changes at Meta/Facebook, we have decided to discontinue support for Facebook Logins.
      </p>

      <p>
        Please use the "Forgot Password" tool below to log into your account using your email address.
        Simply enter the same email address that is connected with your Facebook account and check your email to login to your account.
        After logging in, you can add a password to your account using the "My Account" tools.
      </p>
      
      <p>
        We apologize for any inconvenience.
        If you need assistance in adding a password to your account, please {" "}
        <a href="/contact">contact us.</a>
      </p>
    </div>

  </div>
  )
}



export default ForgotPassForm;

