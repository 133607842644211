import { useSelector } from "react-redux"
import { selectCompleteStripeSubscriptions, selectLoadingSubscriptions } from "store/subscriptions/select"
import { Loader } from "../loader";
import { useEffect, useState } from "react";
import { useSubscriptionsSlice } from "store/subscriptions/reducer";
import { SubscriptionBlock } from './SubscriptionBlock'


export interface SubscriptionManagerProps {
}

export function SubscriptionManager({}: SubscriptionManagerProps) {

  const { fetchSubscriptions } = useSubscriptionsSlice();

  const loadingScripts = useSelector(selectLoadingSubscriptions);
  const subscriptions = useSelector(selectCompleteStripeSubscriptions);


  useEffect(() => {
    fetchSubscriptions();
  }, [])


  return (
    <div className="container-fluid-xl" style={{ minHeight: "200px" }}>
      <Loader loading={loadingScripts} exclass="dark middle">
        Loading, please wait...
      </Loader>

      {subscriptions.map(s => (
        <SubscriptionBlock 
          subscription={s} 
          />
      ))}

      {!subscriptions.length && (
      <div className="my-7">
        <h4 className="text-muted text-center">You have no current subscriptions</h4>
      </div>
      )}

    </div>
  )
}

