import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import SpecialPage from "../SpecialPage";
import { selectLoaded, selectUser } from "../../store/user/select";
import PodcastPage from "./PodcastPage";
import { get as apiGet } from '../../api'
import InnerHTML from '../DangerouslySetHtmlContent';
import { Loader } from "components/loader";

export interface PodcastFlowProps {}


export function PodcastFlow({}: PodcastFlowProps) {

  const [ pageData, setPageData ] = useState<any>();


  const getPage = () => {
    // get the podcast id from the url
    const parts = window.location.pathname.slice(1).split(/\//g);
    const podcast_id = parseInt(parts[1]);

    // check for a draft key
    const draft_key = new URLSearchParams(window.location.search).get('draft_key');

    // fetch the podcast from the website api
    const fetch = async () => {
      try {
        const res = await apiGet(`/podcast/watch/${podcast_id}` + (draft_key ? `?draft_key=${draft_key}` : '')) 
        if (res && res.metadata) {
          setPageData(res);
        } else {
          setPageData(undefined);
        }
      } catch (e) { 
        console.error(e) 
      }
    }

    fetch();
  }

  useEffect(() => {
    getPage();
  }, []);


  // re-fetch the the page content if the user logs in or out
  useEffect(() => {

    const handleLogin = () => {
      getPage();
    }
    const handleLogout = () => {
      getPage();
    }

    window.addEventListener('TRS.user.login', handleLogin)
    window.addEventListener('TRS.user.logout', handleLogout)

    return () => {
      window.removeEventListener('TRS.user.login', handleLogin)
      window.removeEventListener('TRS.user.logout', handleLogout)
    }
  }, [])


  useEffect(() => {
    // re-init TRS components to pick up any components that may be in the content
    if (typeof (window as any).TRSRender === 'function') {
      (window as any).TRSRender();
    }
  }, [ pageData ]);


  return (
    <>
      {pageData && pageData.__html ? (
        <InnerHTML html={pageData.__html}></InnerHTML>
      ) : (
        <div className="my-7" style={{ minHeight: "60vh" }}>
          <Loader loading={true} exclass="dark middle">
            Loading Podcast...
          </Loader>
        </div>
      )}
    </>
  )

}


export default PodcastFlow;
