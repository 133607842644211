import React from "react";


export interface PortalListFormProps {
  eptype: string
  sort: string
  asc: boolean
  onChangeSort: (val: string) => void
  onChangeAsc: (val: boolean) => void
}


export function PortalListForm({ eptype, sort, asc, onChangeSort, onChangeAsc }: PortalListFormProps) {


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    alert(`Submitting Form`);

  } 

  const changeSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeSort(e.target.value);
  }
  //const changeEptype = (val: string) => onChangeEptype(val);
  const changeAsc = (val: boolean) => onChangeAsc(val);


  return (
    <form className="mt-4" id="SortForm_" onSubmit={handleSubmit}>
      <div className="row justify-content-start align-items-center">
        <div className="col-auto">
          <div className="form-group form-inline">
            <label className="mr-2">Sort</label>
            <select name="sort" className="form-control" 
              value={sort} onChange={changeSort}>
              <option value="featured">Featured</option>
              <option value="published">Publish Date</option>
              <option value="segments">Video Segment Count</option>
              <option value="rating">Rating</option>
            </select>
          </div>
        </div>

        <div className="col-auto">
          <div className="form-group btn-group btn-group-sm radio-btn-group">
            
            <label className={`btn ${!asc ? 'btn-secondary' : 'btn-outline-secondary'}`} htmlFor="SortForm_dir_desc_">
              <input type="radio" name="dir" value="desc" 
                id="SortForm_dir_desc_"
                checked={!asc} onChange={() => changeAsc(false)} 
                />
              <svg className="icon icon-sort-amount-desc" style={{ width: '1.25em', height: '1.25em'}}>
                <use xlinkHref="#icon-sort-amount-desc"></use>
              </svg>
              <span className="sr-only">Descending</span>
            </label>
            
            <label className={`btn ${asc ? 'btn-secondary' : 'btn-outline-secondary'}`} htmlFor="SortForm_dir_asc_">
              <input type="radio" name="dir" value="asc" 
                id="SortForm_dir_asc_" 
                checked={asc} onChange={() => changeAsc(true)} 
                />
              <svg className="icon icon-sort-amount-desc" style={{ width: '1.25em', height: '1.25em'}}>
                <use xlinkHref="#icon-sort-amount-asc"></use>
              </svg>
              <span className="sr-only">Ascending</span>
            </label>

          </div>
        </div>

      </div>
    </form>
  )
}