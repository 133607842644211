import { Config, gmaps_key } from "."


const config: Config = {

  host: 'https://api.theruddleshow.com',
  static_host: 'https://static.theruddleshow.com',
  prefix: '/api/v1',
  gmaps_key,
}


export default config;
